// ====================== COMMON ======================
import React, { useState } from 'react';
import dayjs from 'dayjs';

// ====================== Layouts ======================
import DefaultLayout from '../../layouts/defaultLayout'

// ====================== MUI ======================
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Pagination, Checkbox, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography, FormControlLabel, Radio, RadioGroup, Alert, ButtonGroup } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize  } from '@mui/base/TextareaAutosize';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles'

// ====================== Components ======================
import CustomDialog from '../../components/dialog';
import TuneIcon from '@mui/icons-material/Tune';
import Stack from '@mui/material/Stack';

// rows 테스트 데이터
const rows = [];
for (let i = 0; i < 100; i++) {
  rows.push({
    id: (i + 1),
    regDate: '2024-05-20',
    gds: 'TOPAS',
    pnr: '6LINEFS',
    group: '개인',
    headcount: (i + 1),
    departure: 'ZE511',
    startDate: '10OCT',
    startRegion: 'ICN',
    endRegion: 'BKK',
    class: 'Q|K|T|Y',
    status: '캔슬|확정|대기',
    client: '거래처명',
    ticketTL: '24-05-23T16:00:00',
    pnr_view: '실시간',
  });
}

// client 테스트 데이터
const clients = [];
for (let i = 0; i < 100; i++) {
  clients.push({
    id: (i + 1),
    name: '(주) 삼화여행사',
    code: 'SH0002_T',
    manager: 'HO/SIKJOONG',
    tel: '070-9898-0101',
    memo: '인센티브 통합 요청',
  });
}

function PNR() {
  const [pnrOpen, setPnrOpen] = useState(false);
  const [pnrPopOpen, setPnrPopOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [clientsPage, setClientsPage] = useState(1);
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [searchClients, setSearchClients] = useState('');
  const [dateValue, setDateValue] = useState(dayjs(new Date()));
  
  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
  const paginatedRows = filteredRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const changePage = (event, newPage) => {
    setPage(newPage);
  };
  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const changeSearch = (event) => {
    setSearchText(event.target.value);
    setPage(1);
  };
  
  const filteredClients = clients.filter((client) =>
    Object.values(client).some((value) =>
      value.toString().toLowerCase().includes(searchClients.toLowerCase())
    )
  );
  const paginatedClients = filteredClients.slice((clientsPage - 1) * clientsPerPage, clientsPage * clientsPerPage);
  const changeClientsPage = (event, newPage) => {
    setClientsPage(newPage);
  };
  const changeClientsPerPage = (event) => {
    setClientsPerPage(parseInt(event.target.value, 10));
    setClientsPage(1);
  };
  const changeSearchClients = (event) => {
    setSearchClients(event.target.value);
    setClientsPage(1);
  };
  
  const openPnr = () => setPnrOpen(true);
  const closePnr = () => setPnrOpen(false);
  const openPnrPop = () => setPnrPopOpen(true);
  const closePnrPop = () => setPnrPopOpen(false);
  const openFilter = () => setFilterOpen(true);
  const closeFilter = () => setFilterOpen(false);
  const openClient = () => setClientOpen(true);
  const closeClient = () => setClientOpen(false);
  
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 6px 6px 0 6px;
    color: ${theme.palette.grey[900]};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
    resize: none;

    &:hover {
      border-color: ${theme.palette.black};
    }

    &:focus {
      outline: 0;
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  
  return (
    <DefaultLayout>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <FormControl variant="outlined" size="small" sx={{ textAlign: 'center', flex: '0 0 120px' }}>
          <InputLabel>페이지당 노출 개수</InputLabel>
          <Select
            value={rowsPerPage}
            onChange={changeRowsPerPage}
            label="페이지당 노출 개수"
          >
            {[10, 25, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>{size}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Box display="flex" gap={2}>
          <TextField
            label="검색어 입력"
            variant="outlined"
            value={searchText}
            onChange={changeSearch}
            size="small"
          />
          <Button variant="contained" color="secondary" sx={{ fontWeight: 700 }} startIcon={<TuneIcon />} onClick={openFilter}>필터</Button>
          <Button variant="contained" sx={{ fontWeight: 700 }} onClick={openPnrPop} sx={{ color: 'white' }}>PNR 등록</Button>
        </Box>
      </Box>
      
      <TableContainer component={Paper} className="custom-paper custom-paper-sm table-paper">
        <Table
          className="custom-table custom-table-sm"
          size="small"
          stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox defaultChecked />
              </TableCell>
              <TableCell align="center">등록일</TableCell>
              <TableCell align="center">GDS</TableCell>
              <TableCell align="center">PNR</TableCell>
              <TableCell align="center">구분</TableCell>
              <TableCell align="center">인원</TableCell>
              <TableCell align="center">출발편</TableCell>
              <TableCell align="center">출발일</TableCell>
              <TableCell align="center">출발지</TableCell>
              <TableCell align="center">도착지</TableCell>
              <TableCell align="center">클래스</TableCell>
              <TableCell align="center">상태</TableCell>
              <TableCell align="center">거래처</TableCell>
              <TableCell align="center">발권 TL</TableCell>
              <TableCell align="center">PNR VIEW</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row" align="center">
                  <Checkbox />
                </TableCell>
                <TableCell align="center">{row.regDate}</TableCell>
                <TableCell align="center">{row.gds}</TableCell>
                <TableCell align="center">{row.pnr}</TableCell>
                <TableCell align="center">{row.group}</TableCell>
                <TableCell align="center">{row.headcount}</TableCell>
                <TableCell align="center">{row.departure}</TableCell>
                <TableCell align="center">{row.startDate}</TableCell>
                <TableCell align="center">{row.startRegion}</TableCell>
                <TableCell align="center">{row.endRegion}</TableCell>
                <TableCell align="center">{row.class}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.client}</TableCell>
                <TableCell align="center">{row.ticketTL}</TableCell>
                <TableCell align="center" onClick={() => openPnr()}>
                  {row.pnr_view}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
        <Pagination
          count={Math.ceil(rows.length / rowsPerPage)}
          page={page}
          onChange={changePage}
          shape="rounded"
          color='primary'
        />
      </Box>
      
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Button variant="contained" size="large" color="error" onClick={() => goToList()} sx={{ px: 8, color: 'white' }}>삭제</Button>
        
        <Box display="flex" gap={2}>
          <Button variant="outlined" size="large" color="secondary" onClick={() => {}} sx={{ px: 8 }}>Excel 다운</Button>
          <Button variant="contained" size="large" color="secondary" onClick={() => openClient()} sx={{ px: 8 }}>거래처 등록하기</Button>
          <Button variant="contained" size="large" onClick={() => {}} sx={{ px: 8, color: 'white' }}>업데이트</Button>
        </Box>
      </Stack>
      
      <CustomDialog
        open={filterOpen}
        onClose={closeFilter}
        title={{text: '필터 설정', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '초기화', handler: closeFilter, color: 'secondary' },
            { label: '설정', color: 'primary' }
          ]
        }}
      >
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="등록일 FROM"
              value={dateValue}
              slotProps={{
                textField: { size: 'small', variant: 'filled' }
              }}
            />
            <DatePicker
              label="등록일 TO"
              value={dateValue}
              slotProps={{
                textField: { size: 'small', variant: 'filled' }
              }}
            />
          </LocalizationProvider>
          <TextField label="GDS" size="small" variant="filled" placeholder="TOPAS" />
          <TextField label="PNR" size="small" variant="filled" placeholder="5ZP57W" />
          <TextField label="인원" size="small" variant="filled" placeholder="30" />
          <TextField label="출발편명" size="small" variant="filled" placeholder="KE017" />
          <TextField label="출발일자" size="small" variant="filled" placeholder="01AUG" />
          <TextField label="출발지" size="small" variant="filled" placeholder="ICN" />
          <TextField label="도착지" size="small" variant="filled" placeholder="LAX" />
          <TextField label="거래처" size="small" variant="filled" placeholder="LAX" />
        </Box>
      </CustomDialog>
      
      <CustomDialog
        open={pnrPopOpen}
        onClose={closePnrPop}
        maxWidth="sm"
        title={{text: '예약실적등록', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '초기화', handler: closePnrPop, color: 'secondary' },
            { label: '등록', handler: closePnrPop, color: 'primary' }
          ]
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>PNR Address 직접입력</Typography>
          <RadioGroup row name="name">
            <FormControlLabel value="토파스" control={<Radio />} label="토파스" />
            <FormControlLabel value="세이버" control={<Radio />} label="세이버" />
          </RadioGroup>
          
          <TextField size="small" placeholder="PNR ADDRESS" sx={{ mt: 2 }} />
        </Box>
        
        <Box mt={5}>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>PNR 조회화면 붙여넣기</Typography>
          
          <Alert severity="warning">TL관리가 필요한 경우 전체화면 내용을 붙여넣으세요.</Alert>
          
          <Box mt={4}>
            <Textarea minRows={12} sx={{ width: '100%' }} placeholder="--- RLR ---&#13;&#10; RP/SELK136BS/SELK136BS            AA/SU  30APR24/0545Z   67ADQ8 &#13;&#10; 1.YOU/TRIPMS &#13;&#10; 2  KE 645 K 01SEP 7 ICNSIN HK1  1840 2340  01SEP  E  KE/67ADQ8 &#13;&#10; 3 AP SEL 070-4144-8742 - TRIPUS - A &#13;&#10; 4 APM 01094160000 &#13;&#10; 5 TK OK30APR/SELK136BS &#13;&#10; 6 SSR CTCM KE HK1 821094160000 &#13;&#10; 7 OPW SELK136BS-02MAY:1600/1C7/KE REQUIRES TICKET ON OR BEFORE &#13;&#10; &nbsp;&nbsp;&nbsp;05MAY:1600 ICN TIME ZONE/TKT/S2 &#13;&#10; 8 OPC SELK136BS-05MAY:1600/1C8/KE CANCELLATION DUE TO NO &#13;&#10; TICKET ICN TIME ZONE/TKT/S2" />
          </Box>
        </Box>
      </CustomDialog>
      
      <CustomDialog
        open={pnrOpen}
        onClose={closePnr}
        maxWidth="sm"
        title={{text: 'PNR 보기', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closePnr, color: 'secondary' },
            { label: '업데이트', handler: closePnr, color: 'primary' }
          ]
        }}
      >
        KCAOCL<br/>
        1.1HONG/KILDONG MR<br/>
        NO ITIN<br/>
        TKT/TIME LIMIT<br/>
        1.TAW/<br/>
        PHONES<br/>
        1.SELT*00-000-0000 TRU HONG/KILDONG<br/>
        2.SELM*010-1123-3334HONG/KILDONG<br/>
        3.SELE*TEST/AT/TEST.COM<br/>
        PASSENGER DETAIL FIELD EXISTS - USE PD TO DISPLAY<br/>
        GENERAL FACTS<br/>
        1.OSI OZ CTCM 010 1123 3334<br/>
        2.SSR OTHS 1B MISSING SSR CTCM MOBILE OR SSR CTCE EMAIL OR SS<br/>
        R CTCR NON-CONSENT FOR OZ<br/>
        3.SSR OTHS 1B OZ RSVN IS 7705-0990<br/>
        4.SSR ADTK 1B TO OZ BY 27MAY 1900 ICN TIME ZONE OTHERWISE WIL<br/>
        L BE XLD<br/>
        RECEIVED FROM - AGT<br/>
        1NRK.1NRK*A83 1912/21MAY24 KCAOCL H
      </CustomDialog>
      
      <CustomDialog
        open={clientOpen}
        onClose={closeClient}
        maxWidth="md"
        actions={{
          align: 'center',
          buttons: [
            { label: '업데이트', handler: closeClient, color: 'primary' }
          ]
        }}
      >
        <Box display="flex" gap={2} mb={3}>
          <FormControl variant="outlined" size="small" sx={{ textAlign: 'center', flex: '0 0 120px' }}>
            <InputLabel>페이지당 노출 개수</InputLabel>
            <Select
              value={clientsPerPage}
              onChange={changeClientsPerPage}
              label="페이지당 노출 개수"
            >
              {[10, 25, 50, 100].map((size) => (
                <MenuItem key={size} value={size}>{size}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Box display="flex" gap={2}>
            <TextField
              label="검색어 입력"
              variant="outlined"
              value={searchClients}
              onChange={changeSearchClients}
              size="small"
            />
          </Box>
        </Box>
        
        <TableContainer sx={{ maxHeight: 450 }}>
          <Table
            className="custom-table"
            size="small"
            stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox defaultChecked />
                </TableCell>
                <TableCell align="center">거래처</TableCell>
                <TableCell align="center">거래처코드</TableCell>
                <TableCell align="center">담당자</TableCell>
                <TableCell align="center">전화번호</TableCell>
                <TableCell align="center">메모</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedClients.map((client) => (
                <TableRow key={client.id}>
                  <TableCell scope="row" align="center">
                    <Checkbox />
                  </TableCell>
                  <TableCell align="center">{client.name}</TableCell>
                  <TableCell align="center">{client.code}</TableCell>
                  <TableCell align="center">{client.manager}</TableCell>
                  <TableCell align="center">{client.tel}</TableCell>
                  <TableCell align="center">{client.memo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Pagination
            count={Math.ceil(clients.length / clientsPerPage)}
            page={clientsPage}
            onChange={changeClientsPage}
            shape="rounded"
            color='primary'
          />
        </Box>
      </CustomDialog>
    </DefaultLayout>
  )
}

export default PNR;
/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useUtripContextApi, setOpenLoading } from '../context';
import { useEffect } from 'react';

function GlobalLoading() {
  const [controller, dispatch] = useUtripContextApi();
  const { openLoading } = controller;

  useEffect(() => {
    const loadingElement = document.getElementById('loading');

    if (openLoading.showYn) {
      loadingElement?.classList.add('visible');
    } else {
      loadingElement?.classList.remove('visible');
    }
  }, [openLoading]);

  return (
    <div className="loading" id="loading">
      {/* loader-flash, loader-spin1, loader-spin2 사용 가능 */}
      <span className="loader loader-flash"></span>
    </div>
  );
}

export default GlobalLoading;

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function AlertDialog({ open, save, handleClose, title, content, saveAction }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { minWidth: 400, textAlign: 'center' } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          color="black"
          sx={{ whiteSpace: 'pre-line' }} // This ensures \n is respected
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {save == true ? (
          <Button onClick={saveAction} color="primary">
            저장
          </Button>
        ) : (
          ''
        )}
        <Button onClick={handleClose} color="error">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;

// ====================== React Library ===========================

import React, { useState, useEffect } from 'react';
import utripApi from '../../utils/api/utripApi';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

// ====================== MUI ======================
import {
    Paper,
    Grid,
    Alert,
    Button,
    Stack,
    ListItemText,
    TextField,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadIcon from '@mui/icons-material/Upload';
import { setOpenLoading, useUtripContextApi, setOpenDialogPopup } from '../../context';

// ====================== Service Components ======================

import DefaultLayout from '../../layouts/defaultLayout';
import CustomDialog from '../../components/dialog';


function KeMeal() {
    const [checkedId, setCheckedId] = useState([]);
    const [pnrInfo, setPnrInfo] = useState();
    const [controller, dispatch] = useUtripContextApi();
    const changePnr = (event) => {
        setPnrInfo(event.target.value);
    };
    const [mealResult, setMealResult] = [];
    const [mealReq, setMealReq] = useState(mealResult ? mealResult : []);
    const mealCheck = mealReq.map((ps) => ps.id);
    const isAllChecked = mealCheck.every((id) => checkedId.includes(id));
    const checkMeal = (mealId) => {
        setCheckedId((prevCheckedId) => {
          if (prevCheckedId.includes(mealId)) {
            // 기존에 존재하는 mealId 제거
            return prevCheckedId.filter((id) => id !== mealId);
          } else {
            // 신규로 mealId 추가
            return [...prevCheckedId, mealId];
          }
        });
      };
    const checkAllMeal = () => {
        const mealCheck = mealReq.map((ps) => ps.id);

        setCheckedId((prevCheckedId) => {
            const allChecked = mealCheck.every((id) => prevCheckedId.includes(id));

            if (allChecked) {
                return prevCheckedId.filter((id) => !mealCheck.includes(id));
            } else {
                // Add all companies on the current page to checkedId
                const newCheckedIds = new Set(prevCheckedId);
                mealCheck.forEach((id) => newCheckedIds.add(id));
                return Array.from(newCheckedIds);
            }
        });
    };
    const setMealResultData = (data) => {
        console.log('here')
        console.log(data)
        const dataWithId = data.map((item, index) => ({
            ...item,
            id: index
        }));
        console.log(dataWithId)
        setMealReq(dataWithId);
    };
    const getMealList = async () => {
        setOpenLoading(dispatch, { showYn: true });
        const pnr = pnrInfo;
        if (!pnr) {
            setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
            return
        }
        console.log('here000')
        console.log(pnr);
        try {
            const response = await utripApi.get(`/apis/func_wire/v2/kemeal?pnr=${pnr}`);
            console.log(response)
            if (response.status == 200 && response?.data?.errorcode == null) {
                setMealResultData(response.data?.dataframe);
                setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true });
            }
        } catch (error) {
            console.log('Error get meal-List', error);
            setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
        }
        setOpenLoading(dispatch, { showYn: false });
    }
    return (
        <DefaultLayout>
            <Grid container spacing={8} flexDirection="column">
                <Grid item sx={{ '.MuiPaper-root.custom-paper': { height: 'auto', maxHeight: 'none' } }}>
                    <Paper className="custom-paper">
                        <Box gap={2} display="flex">
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="column" gap={4}>
                                    <TextField
                                        size="small"
                                        placeholder="PNR 입력"
                                        value={pnrInfo}
                                        onChange={changePnr}
                                        sx={{ width: '100%' }}
                                        autoComplete="off"
                                    />
                                </Box>
                            </Grid>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ px: 8, color: 'white' }}
                                onClick={() => getMealList()}
                            >
                                기내식 조회
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item sx={{ '.MuiPaper-root.custom-paper': { height: '60vh', maxHeight: '60vh' } }}>
                <TableContainer component={Paper} className="custom-paper table-paper">
                    <Table className="custom-table custom-table-sm" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox checked={isAllChecked} onClick={() => checkAllMeal()} />
                                </TableCell>
                                <TableCell>카테고리</TableCell>
                                <TableCell>식사순서</TableCell>
                                <TableCell>기내식</TableCell>
                                <TableCell>이미지</TableCell>
                                <TableCell>설명</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mealReq?.map((row, index) => (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={checkedId.includes(row.id)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                checkMeal(row.id);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name={`카테고리-${index}`}
                                            variant="outlined"
                                            value={row?.카테고리 || ''}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name={`순서-${index}`}
                                            variant="outlined"
                                            value={row?.순서 || ''}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name={`기내식-${index}`}
                                            variant="outlined"
                                            value={row?.기내식 || ''}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button name={`이미지-${index}`}>
                                            <img width="150px" src={row?.이미지 || ''} alt="" />
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name={`설명-${index}`}
                                            variant="outlined"
                                            value={row?.설명 || ''}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    variant="contained"
                    size="large"
                    sx={{ px: 8, color: 'white' }}
                    onClick={() => getMealList()}
                >
                    신청
                </Button>
            </Grid>
            
        </DefaultLayout>
    )
}
export default KeMeal;
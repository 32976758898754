// ====================== Common ======================
import React, { useState } from 'react';

// ====================== MUI ======================
import {
  Paper, Grid, Box, TextField, Button, Link, FormControlLabel, Radio, RadioGroup, Typography, Stack, Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// ====================== Components ======================
import DefaultLayout from '../../layouts/defaultLayout';

function ListUpload() {
  const [fileName, setFileName] = useState('');
  const [radioValue, setRadioValue] = useState('토파스');
  
  const inputFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName('');
    }
  };
  
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  }
  
  const DownloadLink = styled(Link)(({ theme }) => `
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  `,);
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  return (
    <DefaultLayout>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>엑셀 양식 다운로드</Typography>
              
              <Box display="flex" gap={3}>
                <Button variant="outlined" size="large" color="secondary" startIcon={<FileDownloadIcon />}><DownloadLink href="/assets/docs/NAME_LIST_INT.xlsx" download>엑셀 양식 다운로드 (국제)</DownloadLink></Button>
                <Button variant="outlined" size="large" color="secondary" startIcon={<FileDownloadIcon />}><DownloadLink href="/assets/docs/NAME_LIST_DOM.xlsx" download>엑셀 양식 다운로드 (국내)</DownloadLink></Button>
              </Box>
            </Box>
            
            <Box mt={8}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>명단 업로드</Typography>
              
              <RadioGroup row name="type" value={radioValue} onChange={radioChange} sx={{ mb: 3 }}>
                <FormControlLabel value="토파스" control={<Radio id="type1" />} label="토파스" />
                <FormControlLabel value="세이버" control={<Radio id="type2" />} label="세이버" />
              </RadioGroup>
              
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" gap={4}>
                    <TextField size="small" placeholder="PNR 입력" sx={{ width: '100%' }} />
                    <TextField size="small" placeholder="PNR 입력" sx={{ width: '100%' }} />
                    <TextField size="small" placeholder="PNR 입력" sx={{ width: '100%' }} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box gap={2} display="flex">
                    <TextField value={fileName} size="small" sx={{ flex: '1 1 1%' }} InputProps={{ readOnly: true }} placeholder="선택된 파일 없음" />
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      color="secondary"
                      onChange={inputFileChange}
                    >
                      파일 업로드
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>명단 업로드 결과</Typography>
            
            <Alert severity="info">
              <Typography sx={{ mt: -0.25 }}>저장된 명단 확인 후 저장 또는 취소 버튼을 클릭하세요.</Typography>
            </Alert>
            
            <Alert severity="warning" sx={{ mt: 3 }}>
              <Typography sx={{ mt: -0.25 }}>세이버 APIS는 명단과 동시작업이 불가합니다.</Typography>
              <Typography sx={{ mt: -0.25 }}>APIS 업로드가 필요한 경우 APIS 업로드 메뉴를 이용해 주시기 바랍니다.</Typography>
            </Alert>
            
            <Stack direction="row" spacing={4} mt={6} justifyContent="center">
              <Button variant="outlined" size="large" color="error" sx={{ px: 8 }}>업로드 취소</Button>
              <Button variant="outlined" size="large" sx={{ px: 8 }}>PNR 저장</Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      
      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button variant="contained" size="large" color="secondary" sx={{ px: 8 }}>초기화</Button>
        <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }}>실행</Button>
      </Stack>
    </DefaultLayout>
  )
}

export default ListUpload;
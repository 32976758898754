// ====================== React Library ===========================

import React, { useState, useEffect } from 'react';
import utripApi from '../../utils/api/utripApi';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

// ====================== MUI ======================
import {
    Paper,
    Grid,
    Alert,
    Button,
    Stack,
    ListItemText,
    TextField,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadIcon from '@mui/icons-material/Upload';
import { setOpenLoading, useUtripContextApi, setOpenDialogPopup } from '../../context';

// ====================== Service Components ======================

import DefaultLayout from '../../layouts/defaultLayout';
import CustomDialog from '../../components/dialog';


function KeSeat() {
    const [checkedId, setCheckedId] = useState([]);
    return (
        <DefaultLayout>
            서비스 준비중입니다.
        </DefaultLayout>
    )
}
export default KeSeat;
import { TextField, Button } from '@mui/material';
import { useState } from 'react';

function Footer({ callApi, onChangeValue }) {
  const [value, setValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeValue = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChangeValue(newValue); // Update the parent state
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !isSubmitting) {
      setIsSubmitting(true);
      await callApi(value);
      setIsSubmitting(false);
      setValue('');
    }
  };

  const handleClick = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      await callApi(value);
      setIsSubmitting(false);
      setValue('');
    }
  };

  return (
    <div className="chatbot-footer">
      <TextField
        size="small"
        placeholder="예시) APIS 입력 ENTRY 알려줘"
        value={value || ''}
        onChange={changeValue}
        disabled={isSubmitting}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={handleClick} disabled={isSubmitting}>
        <img src="/assets/images/btn-send.svg" alt="" />
      </Button>
    </div>
  );
}

export default Footer;

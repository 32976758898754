/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState } from 'react';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { useUtripContextApi, setOpenLoading, setOpenDialogPopup } from '../../context';

// ====================== MUI Components  =========================
import { Paper } from '@mui/material';
import DefaultLayout from '../../layouts/defaultLayout';
import Header from './header';
import Content from './content';
import Footer from './footer';

// ====================== Service Components ======================

// ====================== Images ==================================

function AiBot() {
  const [value, setValue] = useState('');
  const [controller, dispatch] = useUtripContextApi();
  const [gds, setGds] = useState('');
  const [pcLoading, setPcLoading] = useState(false);

  const [requests, setRequests] = useState([]);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const savedRequests = JSON.parse(sessionStorage.getItem('aiBotReq')) || [];
    const savedResponses = JSON.parse(sessionStorage.getItem('aiBotRes')) || [];
    setRequests(savedRequests);
    setResponses(savedResponses);
  }, []);

  useEffect(() => {
    if (requests.length > 0 || responses.length > 0) {
      sessionStorage.setItem('aiBotReq', JSON.stringify(requests));
      sessionStorage.setItem('aiBotRes', JSON.stringify(responses));
    }
  }, [requests, responses]);

  const callApi = async () => {
    if (value == '') {
      return;
    }

    setPcLoading(true);

    const request = {
      message: value,
      time: new Date().toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
    };
    setRequests((prevRequests) => [...prevRequests, request]);

    const query = value;
    const determineGdsValue = (value) => {
      if (value.includes("세이버")||value.includes("새이버")||value.includes("sabre")||value.includes("Sabre")) {
        return 'sabre';
      } else {
        return 'topas';
      }
    };

    setValue('');

    try {
      const response = await utripApi.get(
        `/apis/func_wire/v2/search?gds=${determineGdsValue(query)}&query=${query}`
      );

      if (response.status === 200) {
        const responseMessage = {
          message: response.data?.gpt_answer,
          time: new Date().toLocaleTimeString('ko-KR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        };

        setResponses((prevResponses) => [...prevResponses, responseMessage]);
      }
    } catch (error) {
      const errorMessage = {
        message: 'REQUESTED DISPLAY NOT AVAILABLE',
        time: new Date().toLocaleTimeString('ko-KR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
      };
      setResponses((prevResponses) => [...prevResponses, errorMessage]);
    }

    setPcLoading(false);
  };

  const handleChangeValue = (newValue) => {
    setValue(newValue);
  };
  return (
    <DefaultLayout>
      <Paper>
        <div className="chatbot">
          <Header />
          <Content
            callApi={callApi}
            responses={responses}
            requests={requests}
            pcLoading={pcLoading}
          />
          <Footer callApi={callApi} onChangeValue={handleChangeValue} />
        </div>
      </Paper>
    </DefaultLayout>
  );
}

export default AiBot;

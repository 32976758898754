/*
	SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

import Cookies from 'js-cookie';

export function logoutRemoveAuthInfo() {
  Cookies.remove('AccessToken');
  Cookies.remove('RefreshToken');
  window.localStorage.removeItem('UtripRPA');
  window.sessionStorage.removeItem('UtripRPA');
  window.location.href = '/';
}

const isLogIn =
  JSON.parse(localStorage.getItem('UtripRPA')) || JSON.parse(sessionStorage.getItem('UtripRPA'));

export { isLogIn };

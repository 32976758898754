/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import React, { useEffect, useState } from 'react';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenDialogPopup, useUtripContextApi } from '../../context';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import TuneIcon from '@mui/icons-material/Tune';

// ====================== Service Components ======================

// ====================== Images ==================================

function CustomCompanyProfiles() {
  const [controller, dispatch] = useUtripContextApi();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({
    company_name: '',
    company_code: '',
    business_registration_number: '',
    fax: '',
    company_representative: '',
    manager: '',
    manager_email: '',
    manager_mobile: '',
    mobile: '',
    company_type: '',
    accounts_payable: '',
    accounts_receivable: '',
    content: '',
  });
  const [gCompanyId, setGCompanyId] = useState(0);
  const [checkedId, setCheckedId] = useState([]);
  const [pageTitle, setPageTitle] = useState('거래처관리');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const start = (page - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const companiesOnPage = companies.slice(start, end).map((company) => company.id);
  const isAllChecked = companiesOnPage.every((id) => checkedId.includes(id));

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      company_name: company?.company_name,
      company_code: company?.company_code,
      business_registration_number: company?.business_registration_number,
      fax: company?.fax,
      company_representative: company?.company_representative,
      manager: company?.manager,
      manager_email: company?.manager_email,
      manager_mobile: company?.manager_mobile,
      mobile: company?.mobile,
      company_type: company?.company_type,
      accounts_payable: company?.accounts_payable,
      accounts_receivable: company?.accounts_receivable,
      content: company?.content,
    },
  });

  const handleCompanyInfo = (event) => {
    const { name, value } = event.target;
    setCompany({
      ...company,
      [name]: value,
    });
  };

  const clearCompany = () => {
    location.reload();
  };

  const checkAllCompany = () => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const companiesOnPage = companies.slice(start, end).map((company) => company.id);

    setCheckedId((prevCheckedId) => {
      const allChecked = companiesOnPage.every((id) => prevCheckedId.includes(id));

      if (allChecked) {
        return prevCheckedId.filter((id) => !companiesOnPage.includes(id));
      } else {
        // Add all companies on the current page to checkedId
        const newCheckedIds = new Set(prevCheckedId);
        companiesOnPage.forEach((id) => newCheckedIds.add(id));
        return Array.from(newCheckedIds);
      }
    });
  };
  // 회사정보 조회, 복수 건
  const getAllCompanyPrfiles = async (empty) => {
    try {
      const response = await utripApi.post('/apis/func_wire/v2/customer-company', empty);
      if (response.data.message === 'success' && response.data.data) {
        setCompanies(response.data.data);
      }
    } catch (error) {
      console.error('Error getAllCompanyPrfiles : ', error);
    }
  };

  useEffect(() => {
    getAllCompanyPrfiles();
  }, []);

  const checkCompany = (companyId) => {
    setCheckedId((prevCheckedId) => {
      if (prevCheckedId.includes(companyId)) {
        // 기존에 존재하는 companyId 제거
        return prevCheckedId.filter((id) => id !== companyId);
      } else {
        // 신규로 companyId 추가
        return [...prevCheckedId, companyId];
      }
    });
  };

  // 회사정보 조회, 단건
  const getOneCompany = async (companyId) => {
    setGCompanyId(companyId);

    if (!checkedId.includes(companyId)) {
      try {
        const response = await utripApi.get(
          `/apis/func_wire/v2/customer-company?companyid=${companyId}`
        );
        if (response.data.message === 'success') {
          setCompany(response.data.data[0]);
          setValue('company_name', response.data.data[0]?.company_name);
          setValue('company_code', response.data.data[0]?.company_code);
          setValue(
            'business_registration_number',
            response.data.data[0]?.business_registration_number
          );
          setValue('fax', response.data.data[0]?.fax);
          setValue('company_representative', response.data.data[0]?.company_representative);
          setValue('manager', response.data.data[0]?.manager);
          setValue('manager_email', response.data.data[0]?.manager_email);
          setValue('manager_mobile', response.data.data[0]?.manager_mobile);
          setValue('mobile', response.data.data[0]?.mobile);
          setValue('company_type', response.data.data[0]?.company_type);
          setValue('accounts_payable', response.data.data[0]?.accounts_payable);
          setValue('accounts_receivable', response.data.data[0]?.accounts_receivable);
          setValue('content', response.data.data[0]?.content);
        }
      } catch (error) {
        console.error('Error getOneCompany : ', error);
      }
    }
  };

  // 회사정보 등록/수정
  const updateCompanyInfo = async (companyInfo) => {
    const updateCompany = {
      accounts_payable: companyInfo.accounts_payable,
      accounts_receivable: companyInfo.accounts_receivable,
      business_registration_number: companyInfo.business_registration_number,
      companyId: gCompanyId || 0,
      company_code: companyInfo.company_code,
      company_name: companyInfo.company_name,
      company_representative: companyInfo.company_representative,
      content: companyInfo.content,
      fax: companyInfo.fax,
      manager: companyInfo.manager,
      manager_email: companyInfo.manager_email,
      manager_mobile: companyInfo.manager_mobile,
      mobile: companyInfo.mobile,
    };

    try {
      const response = await utripApi.post(
        '/apis/func_wire/v2/save-customer-company',
        updateCompany
      );
      if (response.data.message === 'success') {
        getAllCompanyPrfiles();
        setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true, reload: true });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }
  };

  const deleteCompany = async () => {
    let stringCheckedId = checkedId.join(',');
    try {
      const response = await utripApi.delete(
        `/apis/func_wire/v2/customercompany?delete_list=${stringCheckedId}`
      );
      if (response.data.message === 'success') {
        getAllCompanyPrfiles();
        setOpenDialogPopup(dispatch, { modalType: 'success', showYn: true, reload: true });
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error('Error deleteCompany : ', error);
    }
  };

  const filteredRows = companies.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const paginatedRows = filteredRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const changePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const changeSearch = (event) => {
    setSearchText(event.target.value);
    setPage(1);
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 6px 6px 0 6px;
    color: ${theme.palette.grey[900]};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
    resize: none;

    &:hover {
      border-color: ${theme.palette.black};
    }

    &:focus {
      outline: 0;
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid
        container
        spacing={5}
        component="form"
        role="form"
        onSubmit={handleSubmit((data) => {
          updateCompanyInfo(data);
        })}
      >
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                거래처정보
              </Typography>

              <Grid container spacing={3} mt={1}>
                <Grid item xs={6}>
                  <TextField
                    label="회사명"
                    variant="outlined"
                    size="small"
                    placeholder="유트립"
                    {...register('company_name')}
                    onChange={handleCompanyInfo}
                    value={company.company_name || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="회사코드(영문기호)"
                    variant="outlined"
                    size="small"
                    placeholder="U100"
                    {...register('company_code')}
                    onChange={handleCompanyInfo}
                    value={company.company_code || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="회사타입"
                    variant="outlined"
                    size="small"
                    placeholder="상용"
                    {...register('company_type')}
                    onChange={handleCompanyInfo}
                    value={company.company_type || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="사업자등록번호"
                    variant="outlined"
                    size="small"
                    placeholder="유트립"
                    {...register('business_registration_number')}
                    onChange={handleCompanyInfo}
                    value={company.business_registration_number || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="대표전화"
                    variant="outlined"
                    size="small"
                    placeholder="+8200-1111-2222"
                    {...register('mobile')}
                    onChange={handleCompanyInfo}
                    value={company.mobile || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="FAX"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    {...register('fax')}
                    onChange={handleCompanyInfo}
                    value={company.fax || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="회사담당자"
                    variant="outlined"
                    size="small"
                    placeholder="JAMES DEAN"
                    {...register('manager')}
                    onChange={handleCompanyInfo}
                    value={company.manager || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="담당자 이메일"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    {...register('manager_email')}
                    onChange={handleCompanyInfo}
                    value={company.manager_email || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="담당자 전화번호"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    {...register('manager_mobile')}
                    onChange={handleCompanyInfo}
                    value={company.manager_mobile || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="미지급금"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    {...register('accounts_payable')}
                    onChange={handleCompanyInfo}
                    value={company.accounts_payable || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="미수금"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    {...register('accounts_receivable')}
                    onChange={handleCompanyInfo}
                    value={company.accounts_receivable || ''}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>

              <TextField
                multiline
                minRows={8}
                placeholder=""
                {...register('content')}
                onChange={handleCompanyInfo}
                value={company.content || ''}
                sx={{ width: '100%', mt: 3 }}
              />
            </Box>
          </Paper>

          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => clearCompany()}
            >
              초기화
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              type="submit"
              disabled={isSubmitting}
            >
              수정/저장
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box display="flex" justifyContent="space-between" mb={3}>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ textAlign: 'center', flex: '0 0 120px' }}
              >
                <InputLabel>페이지당 노출 개수</InputLabel>
                <Select value={rowsPerPage} onChange={changeRowsPerPage} label="페이지당 노출 개수">
                  {[10, 25, 50, 100].map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box display="flex" gap={2}>
                <TextField
                  label="검색어 입력"
                  variant="outlined"
                  value={searchText}
                  onChange={changeSearch}
                  size="small"
                />
              </Box>
            </Box>

            <TableContainer sx={{ maxHeight: 450 }}>
              <Table className="custom-table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox checked={isAllChecked} onClick={() => checkAllCompany()} />
                    </TableCell>
                    <TableCell align="center">거래처</TableCell>
                    <TableCell align="center">거래처코드</TableCell>
                    <TableCell align="center">담당자</TableCell>
                    <TableCell align="center">전화번호</TableCell>
                    <TableCell align="center">메모</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows.map((row) => (
                    <TableRow key={row.id} onClick={() => getOneCompany(row.id)}>
                      <TableCell scope="row" align="center">
                        <Checkbox
                          checked={checkedId.includes(row.id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            checkCompany(row.id);
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{row?.company_name}</TableCell>
                      <TableCell align="center">{row?.company_code}</TableCell>
                      <TableCell align="center">{row?.manager}</TableCell>
                      <TableCell align="center">{row?.mobile}</TableCell>
                      <TableCell align="center">{row?.content}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
              <Pagination
                count={Math.ceil(companies.length / rowsPerPage)}
                page={page}
                onChange={changePage}
                shape="rounded"
                color="primary"
              />
            </Box>
          </Paper>

          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="error"
              sx={{ px: 8 }}
              onClick={() => deleteCompany()}
            >
              삭제
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}

export default CustomCompanyProfiles;

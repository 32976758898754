import {
  Paper
} from '@mui/material';
import DefaultLayout from '../../layouts/defaultLayout';
import Header from './header';
import Content from './content';
import Footer from './footer';

function AiBot() {
  return (
    <DefaultLayout>
      <Paper>
        <div className="chatbot">
          <Header />
          <Content />
          <Footer />
        </div>
      </Paper>
    </DefaultLayout>
  )
}

export default AiBot;
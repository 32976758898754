// ====================== COMMON ======================
import routes from '../../routes';
import { useLocation } from 'react-router-dom';

// ====================== MUI ======================
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

// ====================== Components ======================
import Header from '../../components/header';
import Toolbar from '../../components/toolbar';
import FloatingChat from '../../components/floatingChat';

function DefaultLayout({ children, pageTitle }) {
  const location = useLocation();
  const theme = useTheme();
  const currentRoute = routes?.find((route) => location.pathname.includes(route.route));

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />

        <Container
          component="main"
          maxWidth="xxl"
          disableGutters={true}
          sx={{
            bgcolor: '#f0f0f0',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            p: 5,
          }}
        >
          <Toolbar />

          <Breadcrumbs component="div" aria-label="breadcrumb" sx={{ mb: 5 }}>
            {pageTitle ? (
              <Link underline="hover" color="inherit" href={currentRoute?.route}>
                {currentRoute?.name}
              </Link>
            ) : (
              <Typography
                sx={{
                  color: theme.palette.black,
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                {/* {currentRoute?.name} */}
              </Typography>
            )}
            {pageTitle && (
              <Typography
                sx={{
                  color: theme.palette.black,
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                {pageTitle}
              </Typography>
            )}
          </Breadcrumbs>

          {children}
        </Container>
      </Box>

      <FloatingChat />
    </>
  );
}

export default DefaultLayout;

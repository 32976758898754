/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import React, { useState, useEffect } from 'react';
import utripApi from '../../utils/api/utripApi';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

// ====================== MUI ======================
import {
  Paper,
  Grid,
  Alert,
  Button,
  Stack,
  TextField,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadIcon from '@mui/icons-material/Upload';
import { setOpenLoading, useUtripContextApi, setOpenDialogPopup } from '../../context';

// ====================== Service Components ======================

import DefaultLayout from '../../layouts/defaultLayout';
import CustomDialog from '../../components/dialog';
import DragDrop from '../../components/dragDrop'; // dragDrop
// 임시 데이터

function Passport() {
  const [checkedId, setCheckedId] = useState([]);
  const navigate = useNavigate();

  const [radioValue, setRadioValue] = useState('');
  const [pnrValue, setPnrValue] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [transApisPop, setTransApisPop] = useState('');
  const [uploadApisPop, setUploadApisPop] = useState('');
  const [uploadListApisPop, setUploadListApisPop] = useState('');
  const [reservePopOpen, setReservePopOpen] = useState(false);
  const [controller, dispatch] = useUtripContextApi();
  const passportResultString = sessionStorage.getItem('passportResult');
  const passportResult = passportResultString ? JSON.parse(passportResultString) : [];
  const [passportReq, setPassportReq] = useState(passportResult ? passportResult : []);
  const passPortCheck = passportReq.map((ps) => ps.id);
  const isAllChecked = passPortCheck.every((id) => checkedId.includes(id));
  const [ocrSsrUploadTopas, setOcrSsrUploadTopas] = useState('');
  const [ocrSsrUploadSabre, setOcrSsrUploadSabre] = useState('');
  const [nameListTopas, setNameListTopas] = useState('');
  const [nameListSabre, setNameListSabre] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  // dragDrop
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]); // 이미지 미리보기 URL 배열 상태

  const changePnrValue = (event) => {
    setPnrValue(event.target.value);
  };
  // 토파스, 세이버 라디오 변경
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const validationCheck = () => {
    return new Promise((resolve, reject) => {
      if (passportReq.length === 0) {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `여권 이미지 추출 후 이용 하세요.`,
        });
      } else {
        resolve();
      }
    });
  };

  const radioChangeApisUpload = (e) => {
    setRadioValue(e.target.value);
  };
  const setPassportData = (data) => {
    const dataWithId = data.map((item, index) => ({
      ...item,
      id: index + 1, // Adding an id property
    }));
    setPassportReq(dataWithId);

    sessionStorage.setItem('passportResult', JSON.stringify(dataWithId));
  };

  const extractPassport = () => {
    if (uploadFiles.length == 0) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `파일을 업로드 해주세요.`,
      });
      return;
    }

    const formData = new FormData();
    uploadFiles?.forEach((file) => {
      formData.append(file.name, file);
    });
    executeExtractPassport(formData);
  };

  const executeExtractPassport = async (body) => {
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await utripApi.post('/apis/func_wire/ocr_extract', body, {
        timeout: 600000 //600s
      });

      if (response.status == 200 && response?.data?.errorcode == null) {
        setPassportData(response.data?.dataframe);
        let successCount = 0
        response.data?.dataframe.forEach((item) => {
          // 데이터 값 중 하나라도 "READ_FAIL"이 포함되어 있는지 확인
          const hasReadFail = Object.values(item).some((value) => value === "READ_FAIL");

          if (!hasReadFail) {
            // "READ_FAIL"이 없으면 successCount 증가
            successCount += 1;
          }
        });
        setOpenDialogPopup(dispatch, { modalType: 'passport_success', showYn: true, content: `총 ${successCount}건 완료<br>한국발행 여권의 경우에는 외교부_여권진위여부 확인 버튼을 눌러 다시 한번 점검하시고<br>외국발행 여권의 경우에는 여권사본 이미지와 대조하여 점검하시기 바랍니다.` });
      }
    } catch (error) {
      console.log('Error executeExtractPassport', error);
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const verifyPassport = async () => {
    await validationCheck();
    setOpenLoading(dispatch, { showYn: true });

    try {
      const response = await utripApi.post(
        '/apis/func_wire/ocr_extract_check_passport',
        passportReq
      );

      if (response.data.message == 'success') {
        sessionStorage.setItem('passportResult', JSON.stringify(response.data?.dataframe));
        setPassportReq(response.data?.dataframe);

        setOpenDialogPopup(dispatch, {
          modalType: 'sucCustom',
          showYn: true,
          content: '외교부 데이터 확인이 완료되었습니다.<br>외교부등록 데이터와 일치하지 않는 데이터는 노란색으로 표시되어 있습니다.',
        });
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const [apisType, setApisType] = useState('amadeus');
  const [textFields, setTextFields] = useState([]);

  const handleRadioChange = (event) => {
    const selectedType = event.target.value;
    setApisType(selectedType);
    transformApis(selectedType);
  };

  const handleCopy = (index) => {
    navigator.clipboard.writeText(textFields[index]);
  };

  const handleTextFieldChange = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index] = event.target.value;
    setTextFields(newTextFields);
  };

  const transformApis = async (type = apisType) => {
    const formData = new FormData();

    const formBody = passportResult
      .filter((row) => checkedId.includes(row.id))
      .map(({ id, ...rest }) => rest);

    formData.append('data', JSON.stringify(formBody));
    const body = formData;

    try {
      const response = await utripApi.post('/apis/func_wire/ocr_apis_transform', body, {
        timeout: 600000 //600s
      });

      if (response.status == 200 && response?.data?.errorcode == null) {
        const apiData = response.data;

        // Initialize newTextFields based on formBody length
        let newTextFields = new Array(formBody.length).fill('');

        // Update newTextFields based on API data
        if (type == 'amadeus') {
          newTextFields = apiData.topas_apis.slice(0, formBody.length);
        } else if (type == 'sabre') {
          newTextFields = apiData.sabre_apis.slice(0, formBody.length);
        }
        setTextFields(newTextFields); // Updating state
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }
  };

  const ocrReservation = async () => {
    setOpenLoading(dispatch, { showYn: true });

    const passportResult = passportReq.filter((row) => checkedId.includes(row.id));

    const formData = new FormData();
    formData.append('data', JSON.stringify(passportResult));
    formData.append('gds', radioValue);
    const body = formData;
    try {
      const response = await utripApi.post('/apis/func_wire/ocr_reservation', body, {
        timeout: 600000 //600s
      });
      if (response.status == 200 && response.data?.errorcode) {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: response.data.message,
        });
      } else if (response.status == 200) {
        //sessionStorage 담고 GDSPage로 이동하기
        const resData = response?.data?.inputpage_pre_used_result;
        sessionStorage.setItem('gdsReq', JSON.stringify(['']));
        sessionStorage.setItem('gdsRes', JSON.stringify([resData]));
        sessionStorage.setItem('gdsRadioValue', radioValue);

        navigate('/inputpage');
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const ocrNameListUpload = async () => {
    setOpenLoading(dispatch, { showYn: true });

    const passportResult = passportReq.filter((row) => checkedId.includes(row.id));

    const formData = new FormData();
    formData.append('data', JSON.stringify(passportResult));
    formData.append('gds', radioValue);
    formData.append('pnr', pnrValue);
    const body = formData;
    try {
      const response = await utripApi.post('/apis/func_wire/ocr_namelist_upload', body, {
        timeout: 600000 //600s
      });

      if (response.status == 200 && response?.data?.errorcode == null) {
        if (radioValue == 'topas') {
          setNameListTopas(response.data.ssr_output);
        }
        if (radioValue == 'sabre') {
          setNameListSabre(response.data.ssr_output);
        }
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const ocrSsrUpload = async () => {
    setOpenLoading(dispatch, { showYn: true });

    const passportResult = passportReq.filter((row) => checkedId.includes(row.id));

    const formData = new FormData();
    formData.append('data', JSON.stringify(passportResult));
    formData.append('gds', radioValue);
    formData.append('pnr', pnrValue);
    const body = formData;
    try {
      const response = await utripApi.post('/apis/func_wire/ocr_ssr_upload', body, {
        timeout: 600000 //600s
      });

      if (response.status == 200 && response?.data?.errorcode == null) {
        if (radioValue == 'topas') {
          setOcrSsrUploadTopas(response.data.ssr_output);
        }
        if (radioValue == 'sabre') {
          setOcrSsrUploadSabre(response.data.ssr_output);
        }
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  const openTransApisPop = async () => {
    await validationCheck();
    transformApis();
    setTransApisPop(true);
  };

  const closeTransApisPop = () => {
    setTransApisPop(false);
  };

  const openUploadListApisPop = async () => {
    await validationCheck();
    setUploadListApisPop(true);
  };

  const closeUploadListApisPop = () => {
    setNameListTopas('');
    setNameListSabre('');
    setPnrValue('');

    setUploadListApisPop(false);
  };

  const sendUploadListApisPop = () => {
    ocrNameListUpload();
    // setUploadListApisPop(false);
  };

  const openUploadApisPop = async () => {
    await validationCheck();
    setUploadApisPop(true);
  };

  const closeUploadApisPop = () => {
    setOcrSsrUploadTopas('');
    setOcrSsrUploadSabre('');
    setPnrValue('');
    setUploadApisPop(false);
  };

  const sendUploadApisPop = () => {
    ocrSsrUpload();
  };

  const openReservePop = async () => {
    await validationCheck();
    setReservePopOpen(true);
  };

  const closeReservePop = () => {
    setReservePopOpen(false);
  };

  const sendResevePop = () => {
    ocrReservation();
    setReservePopOpen(false);
  };
  const inputFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setFileNames(files.map((file) => file.name));
      setUploadFiles(files);
      const imagePreviews = files.map((file) => {
        if (file.type.startsWith('image/')) {
          return URL.createObjectURL(file); // 이미지 파일일 경우 미리보기 URL 생성
        }
        return null; // 이미지가 아닌 파일은 null로 처리
      });
      setFiles(files)
      setPreviews(imagePreviews);
    } else {
      setFileNames([]);
      setUploadFiles([]);
    }
  };

  const handlePassportInfo = (event, index) => {
    const { name, value } = event.target;
    const fieldName = name.split('-')[0];
    const updatedPassportReq = [...passportReq];
    updatedPassportReq[index] = {
      ...updatedPassportReq[index],
      [fieldName]: value,
    };
    setPassportReq(updatedPassportReq);
    // sessionStorage.setItem('passportResult', JSON.parse(updatedPassportReq));
  };

  const checkPassport = (passportId) => {
    setCheckedId((prevCheckedId) => {
      if (prevCheckedId.includes(passportId)) {
        // 기존에 존재하는 passportId 제거
        return prevCheckedId.filter((id) => id !== passportId);
      } else {
        // 신규로 passportId 추가
        return [...prevCheckedId, passportId];
      }
    });
  };
  const checkAllPassport = () => {
    const passPortCheck = passportReq.map((ps) => ps.id);

    setCheckedId((prevCheckedId) => {
      const allChecked = passPortCheck.every((id) => prevCheckedId.includes(id));

      if (allChecked) {
        return prevCheckedId.filter((id) => !passPortCheck.includes(id));
      } else {
        // Add all companies on the current page to checkedId
        const newCheckedIds = new Set(prevCheckedId);
        passPortCheck.forEach((id) => newCheckedIds.add(id));
        return Array.from(newCheckedIds);
      }
    });
  };

  const clearInfo = () => {
    sessionStorage.removeItem('passportResult');
    location.reload();
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //엑셀 다운로드
  const excelDownload = async () => {
    await validationCheck();
    if (!checkedId.length) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '다운로드할 대상 선택 후, 다운로드 버튼을 클릭하세요.',
      });
      return
    }
    // /ocr_apis_transform
    const formData = new FormData();

    const formBody = passportResult
      .filter((row) => checkedId.includes(row.id))
      .map(({ id, ...rest }) => rest);

    formData.append('data', JSON.stringify(formBody));
    const body = formData;
    let apisData
    try {
      const response = await utripApi.post('/apis/func_wire/ocr_apis_transform', body, {
        timeout: 600000 //600s
      });

      if (response.status == 200 && response?.data?.errorcode == null) {
        apisData = response.data;
      }
    } catch (error) {
      console.log('Error verifyPassport', error);
    }

    const excelData = passportReq
      .filter((row) => checkedId.includes(row.id))
      .map((row, index) => ({
        성: row?.성,
        이름: row?.이름,
        성별: row?.성별,
        생년월일: row?.생년월일,
        국적: row?.국적,
        여권번호: row?.여권번호,
        여권만료일: row?.여권만료일,
        발행국가: row?.발행국가,
        여권타입: row?.여권타입,
        한글이름: row?.한글이름,
        파일명: row?.파일명,
        'Topas APIS': apisData?.topas_apis[index],
        'Sabre APIS': apisData?.sabre_apis[index],
      }));

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, '여권 정보');
    XLSX.writeFile(workBook, 'ocr_result.xlsx');
  };

  const updatePassportReqDates = (type) => {
    const updatedPassportReq = passportReq.map((item) => {
      const updatedItem = { ...item };

      if (type === '생년월일') {
        updatedItem.생년월일 = changeDateFormat(item.생년월일);
      }
      if (type === '여권만료일') {
        updatedItem.여권만료일 = changeDateFormat(item.여권만료일);
      }

      return updatedItem;
    });

    setPassportReq(updatedPassportReq);
  };

  const changeDateFormat = (dateString) => {
    if (!dateString) return dateString;

    if (dateString.length === 6) {
      // Forward conversion: 870201 -> 01FEB87
      const year = dateString.substring(0, 2);
      const month = dateString.substring(2, 4);
      const day = dateString.substring(4, 6);

      const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ];
      const formattedMonth = months[parseInt(month, 10) - 1];

      return `${day}${formattedMonth}${year}`;
    } else if (dateString.length === 7) {
      // Backward conversion: 01FEB87 -> 870201
      const day = dateString.substring(0, 2);
      const month = dateString.substring(2, 5).toUpperCase();
      const year = dateString.substring(5, 7);

      const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ];
      const monthIndex = months.indexOf(month) + 1;

      const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;

      return `${year}${formattedMonth}${day}`;
    } else {
      return dateString; // Return as is if it's not in an expected format
    }
  };

  const isPassportExpired = (expiryDate) => {
    if (!expiryDate) return false;

    // 여권 만료일을 `yymmdd`에서 `Date` 객체로 변환
    const currentYear = new Date().getFullYear().toString().slice(0, 2); // 현재 연도의 앞 두 자리 (e.g., "20")
    const formattedDate = `${currentYear}${expiryDate}`; // yyyyMMdd 형태로 변환
    const expiry = new Date(
      formattedDate.slice(0, 4),
      formattedDate.slice(4, 6) - 1, // Month는 0-based
      formattedDate.slice(6, 8)
    );
    const sixMonthsLater = new Date();
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
    // 만료일이 6개월 후보다 이전인지 확인
    return expiry < sixMonthsLater;
  };
  const deleteRow = async () => {
    await validationCheck();
    if (!checkedId.length) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '대상 선택 후, tkrwp 버튼을 클릭하세요.',
      });
      return
    }
    const filteredData = passportReq.filter(item => !checkedId.includes(item.id));
    setPassportReq(filteredData);
    sessionStorage.setItem('passportResult', JSON.stringify(filteredData));
  }
  // dragDrop
  // 파일 변경 핸들러 (드롭되거나 선택된 파일)
  const handleFileChange = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    const newImagePreviews = newFiles.map((file) => {
      if (file.type.startsWith('image/')) {
        return URL.createObjectURL(file); // 이미지 파일일 경우 미리보기 URL 생성
      }
      return null; // 이미지가 아닌 파일은 null로 처리
    });
    setFileNames((prevFileNames) => [
      ...prevFileNames,
      ...newFiles.map((file) => file.name),
    ]); // 기존 파일 이름에 새 파일 이름 추가

    setUploadFiles((prevUploadFiles) => [...prevUploadFiles, ...newFiles]); // 기존 업로드 파일 배열에 새 파일 추가

    setPreviews((prevPreviews) => [...prevPreviews, ...newImagePreviews]); // 기존 미리보기 배열에 새 미리보기 추가
  };
  return (
    <DefaultLayout pageTitle="Passport">
      <Grid container spacing={5} flexDirection="column">
        <Grid item sx={{ '.MuiPaper-root.custom-paper': { height: 'auto', maxHeight: 'none' } }}>
          {/* <Alert severity="info" sx={{ py: 0, mb: 3, boxShadow: '0 0 5px 2px rgba(0,0,0,0.2)' }}>
            본 메뉴에서는 여권 정보를 인식하고 APIS로 변환한 후, 변환된 정보를 명단 포함하여 PNR에
            바로 연동할 수 있습니다.
          </Alert> */}

          <Paper className="custom-paper">
            <Box gap={2} display="flex">
              {/* <TextField
                value={fileNames.length ? fileNames.join(', ') : '선택된 파일 없음'}
                size="small"
                sx={{ flex: '1 1 1%' }}
                InputProps={{ readOnly: true }}
                placeholder="선택된 파일 없음"
              /> */}
              <DragDrop onChangeFiles={handleFileChange} description={fileNames.length ? fileNames.join(', ') : "이곳에 파일을 드래그앤드롭 하시면 여러 개의 파일을 한 번에 업로드할 수 있습니다"} />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                color="secondary"
                onChange={inputFileChange}
              >
                파일 업로드
                <VisuallyHiddenInput type="file" multiple />
              </Button>
            </Box>
            <div className="flex flex-col items-center">
              {/* DragDrop 컴포넌트 */}
              {/* <DragDrop onChangeFiles={handleFileChange} description="이곳에 파일을 드래그앤드롭 하시면 여러 개의 파일을 한 번에 업로드할 수 있습니다" /> */}

              {/* 업로드된 파일 목록 표시 */}
              {files.length > 0 && (
                <Grid container spacing={12}>
                  <Grid item xs={12}>
                    <Paper className="custom-paper">
                      업로드 파일 {files.length}개
                      <Box
                        p={4}
                        display="flex"
                        flexWrap="wrap"
                        gap={2} // 항목 간격 조정
                        justifyContent="center"
                      >
                        {files.map((file, index) => (
                          previews[index] && ( // 미리보기가 있는 파일만 표시
                            <Box
                              key={index}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              gap={1}
                              style={{ width: '22%', minWidth: '100px', maxWidth: '150px' }} // 한 줄에 4개 배치를 위한 너비 설정
                            >
                              {/* 이미지 미리보기 */}
                              <img
                                src={previews[index]} // 이미지 미리보기 URL
                                alt={file.name}
                                style={{ maxWidth: '100px', maxHeight: '100px' }} // 미리보기 이미지 크기 조정
                              />
                              {/* 파일 정보 */}
                              <div className="text-center">
                                <p>{file.name}</p>
                                <p>{(file.size / 1024).toFixed(2)} KB</p>
                              </div>
                            </Box>
                          )
                        ))}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </div>
            {/* <Alert severity="warning" sx={{ py: 0, mt: 2 }}>
              본 메뉴에서는 여권 정보를 인식하고 APIS로 변환한 후, 변환된 정보를 명단 포함하여 PNR에
              바로 연동할 수 있습니다.
            </Alert> */}
          </Paper>
          <Stack direction="row" spacing={4} mt={6} justifyContent="center">
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              onClick={() => extractPassport()}
            >
              추출하기
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => clearInfo()}
            >
              초기화
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              onClick={() => verifyPassport()}
            >
              외교부_여권진위여부확인
            </Button>
          </Stack>
        </Grid>
        {/* {passportReq.length > 0 ? ( */}
        <Grid item sx={{ '.MuiPaper-root.custom-paper': { height: 'auto', maxHeight: 'auto' } }}>
          <TableContainer component={Paper} className="custom-paper table-paper">
            <Table className="custom-table custom-table-sm" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox checked={isAllChecked} onClick={() => checkAllPassport()} />
                  </TableCell>
                  <TableCell>순서</TableCell>
                  <TableCell>성</TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell>성별</TableCell>
                  <Tooltip title="날짜 타입 변환" arrow placement="top">
                    <TableCell
                      onClick={() => updatePassportReqDates('생년월일')}
                      style={{ color: 'green' }}
                    >
                      생년월일
                    </TableCell>
                  </Tooltip>
                  <TableCell>국적</TableCell>
                  <TableCell>여권번호</TableCell>
                  <Tooltip title="날짜 타입 변환" arrow placement="top">
                    <TableCell
                      style={{ color: 'green' }}
                      onClick={() => updatePassportReqDates('여권만료일')}
                    >
                      여권만료일
                    </TableCell>
                  </Tooltip>
                  <TableCell>발행국가</TableCell>
                  <TableCell>여권타입</TableCell>
                  <TableCell>한글이름</TableCell>
                  <TableCell>파일명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {passportReq?.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      background: row.여권검증 === 'VERIFY_FAIL' ? 'yellow' : 'inherit',
                    }}
                  >

                    <TableCell>
                      <Checkbox
                        checked={checkedId.includes(row.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          checkPassport(row.id);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        name={index}
                        variant="outlined"
                        value={index + 1}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`성-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.성 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`이름-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.이름 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`성별-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.성별 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`생년월일-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.생년월일 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`국적-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.국적 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`여권번호-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.여권번호 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`여권만료일-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.여권만료일 || ''}
                        size="small"
                        InputProps={{
                          style: {
                            color: isPassportExpired(row?.여권만료일) ? "red" : "inherit", // 글자 색상 직접 설정
                          },
                        }}
                        FormHelperTextProps={{
                          style: {
                            color: isPassportExpired(row?.여권만료일) ? "red" : "inherit", // 헬퍼 텍스트 색상 설정
                          },
                        }}
                        helperText={isPassportExpired(row?.여권만료일) ? "여권 유효기간을 확인하십시오." : ""}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`발행국가-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.발행국가 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`여권타입-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.여권타입 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`한글이름-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.한글이름 || ''}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={`파일명-${index}`}
                        variant="outlined"
                        onChange={(e) => handlePassportInfo(e, index)}
                        value={row?.파일명 || ''}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={4} mt={6} justifyContent="center">
            <Button
              justifyContent="left"
              variant="outlined"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => {
                deleteRow();
              }}
            >
              삭제
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => {
                excelDownload();
              }}
            >
              Excel 다운로드
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => openTransApisPop()}
            >
              APIS 변환
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              onClick={openReservePop}
            >
              예약하기
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={openUploadApisPop}
            >
              APIS 업로드
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={openUploadListApisPop}
            >
              명단 &amp; APIS 업로드
            </Button>
          </Stack>
        </Grid>

        {/* <Grid
          item
          sx={{
            '.MuiPaper-root.custom-paper': { height: 'auto', maxHeight: 'none' },
            display: 'flex',
          }}
        >
          <Alert
            severity="info"
            sx={{
              py: 0,
              mb: 3,
              mr: 1,
              boxShadow: '0 0 5px 2px rgba(0,0,0,0.2)',
              width: '100%',
            }}
          >
            <Box>
              한국정부 발행 여권 : <br />
              <br />
              1.외교부 API를 통해 데이터 진위여부를 확인합니다. <br />
              2.여권파일 업로드 후 추출하기 기능을 사용한 다음, 여권진위여부 기능을 이용하십시오.
              <br />
              3.한글 이름, 영문 스펠링, 여권번호를 모두 체크하며, 잘못 추출된 정보는 노란색으로
              마킹됩니다.
              <br /> 4.노란색 마킹 데이터는 해당 칸을 두 번 클릭하여 직접 수정하십시오.
              <br /> 5.수정 후, 다시 진위여부 체크 버튼을 눌러 최종 점검하십시오.
              <br />
              6.노란색 마킹부분이 사라져야 DATA 수정이 완료된것입니다.
            </Box>
          </Alert>
          <Alert
            severity="info"
            sx={{
              py: 0,
              mb: 3,
              boxShadow: '0 0 5px 2px rgba(0,0,0,0.2)',
              width: '100%',
            }}
          >
            <Box>
              외국 발행 여권: <br />
              <br />
              1.외교부 API와 연동되지 않습니다.
              <br /> 2.영문 스펠링 정보와 추출된 스펠링 정보가 일치하는지 확인하십시오.
            </Box>
          </Alert>
        </Grid> */}
      </Grid>

      <CustomDialog
        open={transApisPop}
        onClose={closeTransApisPop}
        maxWidth="md"
        title={{ text: 'APIS 변환', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [{ label: '닫기', handler: closeTransApisPop, color: 'secondary' }],
        }}
      >
        <RadioGroup name="apisType" value={apisType} onChange={handleRadioChange} row>
          <FormControlLabel value="amadeus" label="아마데우스" control={<Radio />} />
          <FormControlLabel value="sabre" label="세이버" control={<Radio />} />
        </RadioGroup>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
          {textFields.map((text, index) => (
            <Box sx={{ display: 'flex', gap: 1 }} key={index}>
              <TextField
                size="small"
                sx={{ flex: '1 1 1%' }}
                value={text}
                onChange={(e) => handleTextFieldChange(index, e)}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<ContentCopyIcon />}
                sx={{ color: 'white' }}
                onClick={() => handleCopy(index)}
              >
                COPY
              </Button>
            </Box>
          ))}
        </Box>
      </CustomDialog>

      <CustomDialog
        open={uploadListApisPop}
        onClose={closeUploadListApisPop}
        maxWidth="sm"
        title={{ text: '명단 & APIS 업로드', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [{ label: '닫기', handler: closeUploadListApisPop, color: 'secondary' }],
        }}
      >
        <RadioGroup
          name="apisType"
          defaultValue="topas"
          value={radioValue}
          onChange={radioChange}
          row
        >
          <FormControlLabel value="topas" label="아마데우스" control={<Radio />}></FormControlLabel>
          <FormControlLabel value="sabre" label="세이버" control={<Radio />}></FormControlLabel>
        </RadioGroup>
        {radioValue == 'topas' ? (
          nameListTopas ? (
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <Box multiline size="medium" sx={{ flex: '1 1 1%' }}>
                {convertNewlinesToBreaks(nameListTopas)}
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <TextField
                size="small"
                placeholder="GDS PNR 입력"
                sx={{ flex: '1 1 1%' }}
                value={pnrValue}
                onChange={changePnrValue}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<UploadIcon />}
                sx={{ color: 'white' }}
                onClick={() => sendUploadListApisPop()}
              >
                업로드
              </Button>
            </Box>
          )
        ) : nameListSabre ? (
          <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
            <Box multiline size="medium" sx={{ flex: '1 1 1%' }}>
              {convertNewlinesToBreaks(nameListSabre)}
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
            <TextField
              size="small"
              placeholder="GDS PNR 입력"
              sx={{ flex: '1 1 1%' }}
              value={pnrValue}
              onChange={changePnrValue}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<UploadIcon />}
              sx={{ color: 'white' }}
              onClick={() => sendUploadListApisPop()}
            >
              업로드
            </Button>
          </Box>
        )}
      </CustomDialog>

      <CustomDialog
        open={uploadApisPop}
        onClose={closeUploadApisPop}
        maxWidth="sm"
        title={{ text: 'APIS 업로드', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [{ label: '닫기', handler: closeUploadApisPop, color: 'secondary' }],
        }}
      >
        <RadioGroup
          name="apisType"
          defaultValue="topas"
          value={radioValue}
          onChange={radioChangeApisUpload}
          row
        >
          <FormControlLabel value="topas" label="아마데우스" control={<Radio />}></FormControlLabel>
          <FormControlLabel value="sabre" label="세이버" control={<Radio />}></FormControlLabel>
        </RadioGroup>
        {radioValue == 'topas' ? (
          ocrSsrUploadTopas ? (
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <Box multiline size="medium" sx={{ flex: '1 1 1%' }}>
                {convertNewlinesToBreaks(ocrSsrUploadTopas)}
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <TextField
                size="small"
                placeholder="GDS PNR 입력"
                sx={{ flex: '1 1 1%' }}
                value={pnrValue}
                onChange={changePnrValue}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<UploadIcon />}
                sx={{ color: 'white' }}
                onClick={() => sendUploadApisPop()}
              >
                업로드
              </Button>
            </Box>
          )
        ) : ocrSsrUploadSabre ? (
          <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
            <Box multiline size="medium" sx={{ flex: '1 1 1%' }}>
              {convertNewlinesToBreaks(ocrSsrUploadSabre)}
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
            <TextField
              size="small"
              placeholder="GDS PNR 입력"
              sx={{ flex: '1 1 1%' }}
              value={pnrValue}
              onChange={changePnrValue}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<UploadIcon />}
              sx={{ color: 'white' }}
              onClick={() => sendUploadApisPop()}
            >
              업로드
            </Button>
          </Box>
        )}
      </CustomDialog>

      <CustomDialog
        open={reservePopOpen}
        onClose={closeReservePop}
        maxWidth="sm"
        title={{ text: '예약하기', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '취소', handler: closeReservePop, color: 'secondary' },
            { label: '보내기', handler: sendResevePop, color: 'primary' },
          ],
        }}
      >
        <RadioGroup name="reserveType" value={radioValue} onChange={radioChange}>
          <Button variant="outlined" endIcon={<IosShareIcon color="secondary" />}>
            <FormControlLabel
              value="topas"
              label="토파스 GDS로 보내기"
              control={<Radio />}
              sx={{ width: '100%', color: 'secondary.main' }}
            />
          </Button>
          <Button variant="outlined" endIcon={<IosShareIcon color="secondary" />} sx={{ mt: 3 }}>
            <FormControlLabel
              value="sabre"
              label="세이버 GDS로 보내기"
              control={<Radio />}
              sx={{ width: '100%', color: 'secondary.main' }}
            />
          </Button>
        </RadioGroup>
      </CustomDialog>
    </DefaultLayout>
  );
}

export default Passport;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import routes from './routes';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/theme/index.js';
import CssBaseline from '@mui/material/CssBaseline';

import PrivateRoute from './utils/routerCheck/PrivateRoute';
import GlobalAlertDialog from './components/globalAlert';
import GlobalLoading from './components/loading';

function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // route에 auth가 true일 경우 접근제한
      if (route.auth) {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      } else {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      {/* Alert popup 전역으로 등록*/}
      <GlobalAlertDialog />
      {/* Loading 전역으로 등록*/}
      <GlobalLoading />
    </ThemeProvider>
  );
}

export default App;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import React, { useState, useEffect } from 'react';
import { setOpenLoading, setOpenDialogPopup, useUtripContextApi } from '../../context';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// ====================== MUI Components  =========================

import DefaultLayout from '../../layouts/defaultLayout';
import { styled, css } from '@mui/system';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { Modal as BaseModal } from '@mui/base/Modal';
import Stack from '@mui/material/Stack';
import TableFooter from '@mui/material/TableFooter';

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'base-Backdrop-open': open }, className)} ref={ref} {...other} />;
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[200]};
    box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
    padding: 24px;
    color: ${theme.palette.grey[900]};

    & .modal-title {
      margin: 0 0 8px;
      line-height: 1.5rem;
    }

    & .modal-description {
      margin: 0 0 4px;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.grey[800]};
    }
  `
);

function LowestPriceReservation() {
  const [controller, dispatch] = useUtripContextApi();
  const [open, setOpen] = useState(false);
  const [gdsName, setGdsName] = useState('');
  const ageTypes = ['성인', '소아', '유아'];
  const genderTypes = ['남', '여'];
  const travelMember = JSON.parse(sessionStorage.getItem('travelMember'));

  const ageTypeValues = [];

  // Add `성인` (adult) values
  for (let i = 0; i < travelMember.adult; i++) {
    ageTypeValues.push(ageTypes[0]); // '성인'
  }

  // Add `소아` (child) values
  for (let i = 0; i < travelMember.child; i++) {
    ageTypeValues.push(ageTypes[1]); // '소아'
  }

  // Add `유아` (infant) values
  for (let i = 0; i < travelMember.infant; i++) {
    ageTypeValues.push(ageTypes[2]); // '유아'
  }

  const totalNum = Object.values(travelMember)?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const createDefaultMember = (index) => ({
    resListAdtcheck: ageTypeValues[index], // Set `구분` based on the index
    resListGender: '',
    resListName: '',
    resListFname: '',
    resListBirth: '',
    resListNation: 'KR',
    resListAPM: '',
    resListNationNum: '',
    resListMobNum: '',
    resListPaNnum: String(index + 1),
  });

  // Initialize `resJson` state
  const [resJson, setResJson] = useState(
    Array.from({ length: totalNum }, (_, index) => createDefaultMember(index))
  );

  useEffect(() => {
    setResJson(Array.from({ length: totalNum }, (_, index) => createDefaultMember(index)));
  }, [totalNum]);

  const handleInfo = (index, event) => {
    const { name, value } = event.target;
    const updatedResJson = [...resJson];
    updatedResJson[index][name] = value;
    setResJson(updatedResJson);
  };

  const handleAddRow = (index) => {
    const updatedResJson = [...resJson];
    updatedResJson.splice(index + 1, 0, {
      resListAPM: '',
      resListNationNum: '',
      resListMobNum: '',
      resListPaNnum: '',
    });
    setResJson(updatedResJson);
  };

  const handleRemoveRow = (index) => {
    const updatedResJson = [...resJson];
    updatedResJson.splice(index, 1);
    setResJson(updatedResJson);
  };

  const navigate = useNavigate();

  const [reserveInfo, setReserveInfo] = useState({
    resNum: 1,
  });

  useEffect(() => {
    console.log(resJson, 'resJson');
    console.log(reserveInfo, 'reserveInfo');
  }, [resJson, reserveInfo]);

  const handleReserveInfo = (event) => {
    const { name, value } = event.target;
    setReserveInfo({
      ...reserveInfo,
      [name]: value,
    });
  };

  const goToDetail = () => {
    const no = sessionStorage.getItem('lowestPricingDetail');
    navigate(`/pricingpage/${no}`);
  };
  const goToResult = () => {
    reserveTravelInfo();
  };

  const includePassenger = () => {
    const updatedResJson = [...resJson];
    let [lastName, firstName] = ['', ''];
    if (reserveInfo?.resName) {
      [lastName, firstName] = reserveInfo.resName?.split('/');
    }
    updatedResJson[0]['resListName'] = firstName;
    updatedResJson[0]['resListFname'] = lastName;
    setResJson(updatedResJson);
  };

  const selectedSegInfo = sessionStorage.getItem('selectedSegInfo');
  const pricingInfo = JSON.parse(sessionStorage.getItem('selectedTravelInfo'));

  const popOpen = () => {
    setOpen(true);
  };

  const popClose = () => {
    setOpen(false);
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const reserveTravelInfo = async () => {
    if (resJson.some((member) => !member.resListFname || !member.resListName || !member.resListGender)) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `탑승자 정보를 확인하세요.`,
      });
      return;
    }
    if (gdsName == '') {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `GDS선택 후 실행해주세요.`,
      });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });

    const adultPaNnumList = resJson
      .filter((member) => member.resListAdtcheck === '성인')
      .map((member) => member.resListPaNnum);

    const updatedResJson = resJson.map((member) => {
      if (member.resListAdtcheck === '유아') {
        return {
          ...member,
          resListParentnum: adultPaNnumList[0],
        };
      }
      return member;
    });

    sessionStorage.setItem('lowestPassMember', JSON.stringify(updatedResJson));

    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      resData: {
        gdsName: gdsName,
        resMail: reserveInfo?.resMail,
        resName: reserveInfo?.resName,
        resNum: reserveInfo?.resNum,
        resPhone: reserveInfo?.resPhone,
        segList: [selectedSegInfo],
      },
      resJson: updatedResJson,
    };
    console.log(body, '바디');

    try {
      const response = await utripApi.post(
        '/apis/func_wire/v2/get_lowestprice_air/reservation',
        body
      );
      if (
        response.status === 200 &&
        response.data.pnr !== 'fail' &&
        response.data.pnr !== 'timeout' &&
        response.data.message !== 'Proccess Error'
      ) {
        sessionStorage.setItem('lowestPriceResult', JSON.stringify(response.data.pnr));
        navigate('/pricingpage/result');
      } else if (response.data.pnr == 'timeout') {
        setOpenDialogPopup(dispatch, { modalType: 'failCustom', showYn: true, content: '작업 시간이 만료되어 다시 예약해주세요.' });
      } else if (response.data.pnr == 'namelist_error') {
        setOpenDialogPopup(dispatch, { modalType: 'failCustom', showYn: true, content: '입력한 명단을 확인해주세요.' });
      }
      else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  return (
    <DefaultLayout pageTitle={'예약하기 및 예상운임'}>
      <>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Paper className="custom-paper">
              <Box>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  예약자 정보
                </Typography>

                <TableContainer>
                  <Table className="custom-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">예약자명 (영문)</TableCell>
                        <TableCell align="center">연락처 (MOBILE)</TableCell>
                        <TableCell align="center">메일주소 (선택)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <TextField
                            label="예약자명"
                            variant="outlined"
                            placeholder="HONG/GILDONG"
                            size="small"
                            {...register('resName')}
                            onChange={handleReserveInfo}
                            value={reserveInfo?.resName || ''}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="연락처"
                            variant="outlined"
                            placeholder="010-1234-5678"
                            size="small"
                            {...register('resPhone')}
                            onChange={handleReserveInfo}
                            value={reserveInfo?.resPhone || ''}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="메일주소"
                            variant="outlined"
                            placeholder="tripus@tripus.co.kr"
                            size="small"
                            {...register('resMail')}
                            onChange={handleReserveInfo}
                            value={reserveInfo?.resMail || ''}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box mt={8}>
                <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                  탑승자 정보
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0 20px' }}>
                  <Alert severity="warning" sx={{ flex: '1 0 1%', py: 0, mb: 2 }}>
                    소아/유아 포함인 경우 생년월일 필수 입력
                  </Alert>
                  <FormControlLabel
                    control={<Checkbox onClick={() => includePassenger()} />}
                    label="탑승자에 여행자 포함"
                    sx={{ '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                  />
                </Box>

                <TableContainer>
                  <Table className="custom-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">구분</TableCell>
                        <TableCell align="center">성별</TableCell>
                        <TableCell align="center">성</TableCell>
                        <TableCell align="center">이름</TableCell>
                        <TableCell align="center">생년월일</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resJson?.map((member, currentRowIndex) => (
                        <TableRow key={currentRowIndex}>
                          <TableCell>{currentRowIndex + 1}</TableCell>
                          <TableCell>
                            <FormControl fullWidth size="small" sx={{ width: '100px' }}>
                              <InputLabel id="demo-simple-select-label">구분</InputLabel>
                              <Select
                                labelId="label1"
                                id={`select1-${currentRowIndex}`}
                                {...register(`resListAdtcheck-${currentRowIndex}`)}
                                value={member.resListAdtcheck}
                                onChange={(event) => handleInfo(currentRowIndex, event)}
                                name="resListAdtcheck"
                                label="구분"
                              >
                                {ageTypes.map((ageType) => (
                                  <MenuItem key={ageType} value={ageType}>
                                    {ageType}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth size="small" sx={{ width: '80px' }}>
                              <InputLabel id="demo-simple-select-label">성별</InputLabel>
                              <Select
                                labelId="label2"
                                id={`select2-${currentRowIndex}`}
                                {...register(`resListGender-${currentRowIndex}`)}
                                value={member?.resListGender}
                                onChange={(event) => handleInfo(currentRowIndex, event)}
                                name="resListGender"
                                label="성별"
                              >
                                {genderTypes.map((genderType) => (
                                  <MenuItem key={genderType} value={genderType}>
                                    {genderType}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="성"
                              variant="outlined"
                              placeholder="HONG"
                              size="small"
                              {...register(`resListFname-${currentRowIndex}`)}
                              value={member?.resListFname}
                              onChange={(event) => handleInfo(currentRowIndex, event)}
                              name="resListFname"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="이름"
                              variant="outlined"
                              placeholder="KILDING"
                              size="small"
                              {...register(`resListName-${currentRowIndex}`)}
                              value={member?.resListName}
                              onChange={(event) => handleInfo(currentRowIndex, event)}
                              name="resListName"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="생년월일"
                              variant="outlined"
                              placeholder="19870101"
                              size="small"
                              {...register(`resListBirth-${currentRowIndex}`)}
                              value={member?.resListBirth}
                              onChange={(event) => handleInfo(currentRowIndex, event)}
                              name="resListBirth"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box
                  mt={3}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '0 20px',
                  }}
                >
                  <RadioGroup row name="name">
                    <FormControlLabel
                      value="토파스"
                      control={<Radio />}
                      label="토파스"
                      {...register('gdsName')}
                      onChange={() => setGdsName('topas')}
                    />
                    <FormControlLabel
                      value="세이버"
                      control={<Radio />}
                      label="세이버"
                      {...register('gdsName')}
                      onChange={() => setGdsName('sabre')}
                    />
                  </RadioGroup>

                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => popOpen(true)}
                    sx={{ color: 'white' }}
                  >
                    연락처 입력
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="custom-paper">
              <Box>
                <Alert sx={{ whiteSpace: "pre-line" }} severity="success">{selectedSegInfo}</Alert>
                <TableContainer className="custom-scrollbar" sx={{ mt: 2 }}>
                  <Table className="custom-table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">PTC</TableCell>
                        <TableCell align="center">운임</TableCell>
                        <TableCell align="center">TAX</TableCell>
                        <TableCell align="center">총 합계</TableCell>
                        <TableCell align="center">FARE BASIS</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pricingInfo?.items.map((item) => (
                        <TableRow key={item?.no}>
                          <TableCell align="center">{item?.ptc}</TableCell>
                          <TableCell align="center">{item?.fee}</TableCell>
                          <TableCell align="center">{item?.tax}</TableCell>
                          <TableCell align="center">{item?.price}</TableCell>
                          <TableCell align="center">{item?.basis}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter sx={{ bgcolor: 'grey.800' }}>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={{ color: 'white', fontWeight: 700 }}
                        >
                          TOTAL
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.fee}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.tax}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.price}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>

            <Box py={3} px={5}>
              <Typography variant="body" color="error.main">
                {/* 운임 TL : 30MAY24 */}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => goToResult()}
            sx={{ px: 8, color: 'white' }}
          >
            예약하기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => goToDetail()}
            sx={{ px: 8, bgcolor: 'secondary.main', color: 'white' }}
          >
            이전
          </Button>

        </Stack>

        <Modal open={open} onClose={popClose} slots={{ backdrop: StyledBackdrop }}>
          <ModalContent sx={{ width: 800 }}>
            <Typography variant="h6" fontWeight={700} gutterBottom>
              승객 연락처 입력
            </Typography>

            <Table className="custom-table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">휴대폰 번호(APM)</TableCell>
                  <TableCell align="center">국가번호</TableCell>
                  <TableCell align="center">휴대폰 번호(CTCM)</TableCell>
                  <TableCell align="center">승객지정</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resJson?.map((member, currentRowIndex) => (
                  <TableRow key={currentRowIndex}>
                    {currentRowIndex === 0 ? (
                      <TableCell>
                        <TextField
                          label="APM"
                          variant="outlined"
                          placeholder="01012345678"
                          size="small"
                          value={member?.resListAPM || ''}
                          onChange={(event) => handleInfo(currentRowIndex, event)}
                          name="resListAPM"
                        />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {/* <TextField
                          label="APM"
                          variant="outlined"
                          placeholder="01012345678"
                          size="small"
                          value={member?.resListAPM || ''}
                          name="resListAPM"
                          disabled
                        /> */}
                      </TableCell>)}
                    <TableCell>
                      <TextField
                        label="국가번호"
                        variant="outlined"
                        placeholder="82"
                        size="small"
                        value={member?.resListNationNum}
                        onChange={(event) => handleInfo(currentRowIndex, event)}
                        name="resListNationNum"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="01012345678"
                        variant="outlined"
                        placeholder="01012345678"
                        size="small"
                        value={member?.resListMobNum}
                        onChange={(event) => handleInfo(currentRowIndex, event)}
                        name="resListMobNum"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="승객지정"
                        variant="outlined"
                        placeholder="1"
                        size="small"
                        value={member?.resListPaNnum}
                        onChange={(event) => handleInfo(currentRowIndex, event)}
                        name="resListPaNnum"
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1} justifyContent="center">
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          sx={{ p: 0, minWidth: 24 }}
                          onClick={() => handleAddRow(currentRowIndex)}
                        >
                          +
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          sx={{ p: 0, minWidth: 24 }}
                          onClick={() => handleRemoveRow(currentRowIndex)}
                        >
                          -
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Button variant="contained" sx={{ fontWeight: 700, color: 'white' }} onClick={popClose}>
                      확인
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </Table>
          </ModalContent>
        </Modal>
      </>
    </DefaultLayout>
  );
}

export default LowestPriceReservation;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState } from 'react';

// ====================== MUI Components  =========================
import { Grid } from '@mui/material';

// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
import NonamePnrCreatePage from './noname-pnr-create';
import ExcelWrite from './excel-write';
import ExcelResult from './excel-result';
import Result from './result';

function GrpReservation() {
  const [pageTitle, setPageTitle] = useState('Group');
  const [isResult, setIsResult] = useState(false);
  const [isExcelResult, setIsExcelResult] = useState(false);

  const goToList = () => {
    setIsResult(false);
    setPageTitle(null);
  };

  const goToResult = () => {
    setIsResult(true);
    setPageTitle('예약결과');
  };
  const goToExcelResult = () => {
    setIsExcelResult(true);
    setPageTitle('예약결과');
  };
  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          {isResult ? <Result /> : <NonamePnrCreatePage goToResult={goToResult} />}
        </Grid>
        <Grid item xs={6}>
          {isExcelResult ? <ExcelResult /> : <ExcelWrite goToExcelResult={goToExcelResult} />}
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}

export default GrpReservation;

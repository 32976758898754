// ====================== COMMON ======================
import React, { useState } from 'react';

// ====================== Layouts ======================
import DefaultLayout from '../../layouts/defaultLayout';

// ====================== MUI ======================
import {
  Grid,
  Paper,
  Button,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { TextareaAutosize as BaseTextareaAutosize  } from '@mui/base/TextareaAutosize';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// ====================== Components ======================
import Calendar from './calendar';
import AlertDialog from '../../components/alert';
import Box from '@mui/material/Box';
import theme from '../../assets/theme';
import CustomDialog from '../../components/dialog';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

function MainPage() {
  const [popOpen, setPopOpen] = useState(false);
  const [pnrDetailOpen, setPnrDetailOpen] = useState(false);
  const [ttlaDetailOpen, setTtlaDetailOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [field, setField] = useState('TL 메모');
  const [radioValue, setRadioValue] = useState('완료');
  const [dateValue, setDateValue] = useState(dayjs(new Date()));
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  
  const openPop = () => {
    setPopOpen(true);
  };
  
  const closePop = () => {
    setPopOpen(false);
  };
  
  const openPnrDetail = () => {
    setPnrDetailOpen(true);
  }
  
  const closePnrDetail = () => {
    setPnrDetailOpen(false);
  }
  
  const openTtlaDetail = () => {
    setTtlaDetailOpen(true);
  }
  
  const closeTtlaDetail = () => {
    setTtlaDetailOpen(false);
  }
  
  const AlertOpen = () => {
    setAlertOpen(true);
  };
  
  const AlertClose = () => {
    setAlertOpen(false);
  };
  
  const selectChange = (e) => {
    setField(e.target.value);
  }
  
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  }
  
  // 이벤트 목록
  const [events, setEvents] = useState([
    {
      title: '5T7IXO(2)',
      start: '2024-05-01',
      end: '2024-05-01'
    },
    {
      title: '5T7IXO(2)',
      start: '2024-05-01',
      end: '2024-05-01'
    },
    {
      title: '5T7IXO(2)',
      start: '2024-05-01',
      end: '2024-05-01'
    },
    {
      title: '5T7IXO(2)',
      start: '2024-05-01',
      end: '2024-05-01'
    },
  ]);
  
  // PNR, MEM, TTL, TLM 건 수
  const itemCounts = {
    '2024-05-01': {
      'PNR': 7,
      'TTL': 7,
      'MEM': 7,
      'TLM': 7,
      'COM': 0, // 완료
      'PEN': 0, // 미완료
    },
    '2024-05-08': {
      'PNR': 7,
      'TTL': 0,
      'MEM': 7,
      'TLM': 0,
      'COM': 4, // 완료
      'PEN': 0, // 미완료
    },
    '2024-05-15': {
      'PNR': 0,
      'TTL': 0,
      'MEM': 7,
      'TLM': 7,
      'COM': 0, // 완료
      'PEN': 0, // 미완료
    },
    '2024-05-22': {
      'PNR': 0,
      'TTL': 0,
      'MEM': 7,
      'TLM': 7,
      'COM': 0, // 완료
      'PEN': 0, // 미완료
    },
    '2024-05-29': {
      'PNR': 0,
      'TTL': 0,
      'MEM': 7,
      'TLM': 7,
      'COM': 0, // 완료
      'PEN': 0, // 미완료
    },
  }
  
  // 휴일목록
  const holidays = {
    '2024-01-01': '신정',
    '2024-05-05': '어린이날',
    '2024-05-15': '부처님오신날',
    '2024-06-06': '현충일',
    '2024-12-25': '크리스마스'
  };
  
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 6px 6px 0 6px;
    color: ${theme.palette.grey[900]};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
    resize: none;

    &:hover {
      border-color: ${theme.palette.black};
    }

    &:focus {
      outline: 0;
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  
  return (
    <DefaultLayout>
      <Grid container spacing={4}>
        <Grid item xs={10}>
          <Paper className="custom-paper custom-paper-full-height">
            <Calendar events={events} itemCounts={itemCounts} holidays={holidays} />
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="custom-paper custom-paper-full-height">
            <Button variant="contained" color="secondary" sx={{ width: '100%' }} onClick={openPop}>TTLM &amp; 업무메모 추가</Button>
            
            <Box mt={5}>
              <Box sx={{ p: 1, border: '1px solid', borderColor: theme.palette.secondary.main, borderRadius: 1 }}>
                <Typography variant="subtitle1" textAlign="center" fontWeight={700}>
                  2024년 5월 23일 목요일
                </Typography>
              </Box>
              
              <Box sx={{ mt: 4, border: '1px solid', borderColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Typography variant="subtitle2" textAlign="center" fontWeight={700} sx={{ py: 1, color: 'white', backgroundColor: theme.palette.info.main }}>
                  금일출발 PNR : 4건
                </Typography>
                
                <Box p={4} display="flex" flexDirection="column" gap={1}>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openPnrDetail}>
                    5K8DTX(5) <span className="status-badge secondary">개인</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openPnrDetail}>
                    5K8DTX(5) <span className="status-badge green">단체</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openPnrDetail}>
                    5K8DTX(5) <span className="status-badge secondary">개인</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openPnrDetail}>
                    5K8DTX(5) <span className="status-badge green">단체</span>
                  </Button>
                </Box>
              </Box>
              
              <Box sx={{ mt: 4, border: '1px solid', borderColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Typography variant="subtitle2" textAlign="center" fontWeight={700} sx={{ py: 1, color: 'white', backgroundColor: theme.palette.info.main }}>
                  MEM : 4건
                </Typography>
                
                <Box p={4}>
                  <Box>
                    <span className="status-badge success">완료</span>
                    
                    <List>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText primary="일광업체 확인" sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 }, }} />
                      </ListItem>
                    </List>
                  </Box>
                  
                  <Box mt={2}>
                    <span className="status-badge error">미완료</span>
                    
                    <List>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText primary="일광업체 확인" sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 }, }} />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Box>
              
              <Box sx={{ mt: 4, border: '1px solid', borderColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Typography variant="subtitle2" textAlign="center" fontWeight={700} sx={{ py: 1, color: 'white', backgroundColor: theme.palette.primary.main }}>
                  TTLA : 4건
                </Typography>
                
                <Box p={4} display="flex" flexDirection="column" gap={1}>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openTtlaDetail}>
                    5K8DTX(5) <span className="status-badge secondary">개인</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openTtlaDetail}>
                    5K8DTX(5) <span className="status-badge green">단체</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openTtlaDetail}>
                    5K8DTX(5) <span className="status-badge secondary">개인</span>
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ width: '100%', justifyContent: 'space-between', }} onClick={openTtlaDetail}>
                    5K8DTX(5) <span className="status-badge green">단체</span>
                  </Button>
                </Box>
              </Box>
              
              <Box sx={{ mt: 4, border: '1px solid', borderColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Typography variant="subtitle2" textAlign="center" fontWeight={700} sx={{ py: 1, color: 'white', backgroundColor: theme.palette.primary.main }}>
                  TTLM : 4건
                </Typography>
                
                <Box p={4}>
                  <Box>
                    <span className="status-badge success">완료</span>
                    
                    <List>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText primary="일광업체 확인" sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 }, }} />
                      </ListItem>
                    </List>
                  </Box>
                  
                  <Box mt={2}>
                    <span className="status-badge error">미완료</span>
                    
                    <List>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText primary="일광업체 확인" sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 }, }} />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      
      <CustomDialog
        open={popOpen}
        onClose={closePop}
        maxWidth="sm"
        title={{text: '메모추가', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closePop, color: 'secondary' },
            { label: '추가', handler: closePop, color: 'primary' }
          ]
        }}
      >
        <FormControl fullWidth size="small">
          <InputLabel id="label">구분</InputLabel>
          <Select
            labelId="label"
            id="select"
            value={field}
            name="field"
            label="Field"
            onChange={selectChange}
          >
            {['TL 메모', '일반메모'].map(num => <MenuItem key={num} value={num}>{num}</MenuItem>)}
          </Select>
        </FormControl>
        
        {field === 'TL 메모' ?
          <>
            <Box display="flex" gap={4} mt={4}>
              <Box display="flex" alignItems="center" gap={5} sx={{ flex: '1 1 1%' }}>
                <Typography variant="subtitle1" fontWeight={700}>상태</Typography>
                
                <RadioGroup row name="radio" value={radioValue} onChange={radioChange}>
                  <FormControlLabel value="완료" control={<Radio id="radio1" />} label="완료" />
                  <FormControlLabel value="미완료" control={<Radio id="radio2" />} label="미완료" />
                </RadioGroup>
              </Box>
              
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: '1 1 1%' }}>
                <DateTimePicker
                  label="TL 일시"
                  value={selectedDateTime}
                  onChange={(newValue) => {
                    setSelectedDateTime(newValue);
                  }}
                  slotProps={{
                    textField: { size: 'small', variant: 'outlined' }
                  }}
                />
              </LocalizationProvider>
            </Box>
            
            <TextField label="PNR" variant="outlined" placeholder="PNR" size='small' sx={{ width: '100%', flex: '1 1 1%', mt: 4 }} />
          </>
          :
          <>
            <Box display="flex" gap={4} mt={4}>
              <Box display="flex" alignItems="center" gap={5} sx={{ flex: '1 1 1%' }}>
                <Typography variant="subtitle1" fontWeight={700}>상태</Typography>
                
                <RadioGroup row name="radio" value={radioValue} onChange={radioChange}>
                  <FormControlLabel value="완료" control={<Radio id="radio1" />} label="완료" />
                  <FormControlLabel value="미완료" control={<Radio id="radio2" />} label="미완료" />
                </RadioGroup>
              </Box>
              
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: '1 1 1%' }}>
                <DateTimePicker
                  label="날짜"
                  value={selectedDateTime}
                  onChange={(newValue) => {
                    setSelectedDateTime(newValue);
                  }}
                  slotProps={{
                    textField: { size: 'small', variant: 'outlined' }
                  }}
                />
              </LocalizationProvider>
            </Box>
            
            <TextField label="제목" variant="outlined" placeholder="제목 (최대 24자)" size='small' sx={{ width: '100%', flex: '1 1 1%', mt: 4 }} />
          </>
        }
        
        <Textarea minRows={5} placeholder="" sx={{ width: '100%', mt: 5 }} />
      </CustomDialog>
      
      <CustomDialog
        open={pnrDetailOpen}
        onClose={closePnrDetail}
        maxWidth="sm"
        title={{text: 'PNR 상세보기', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closePnrDetail, color: 'secondary' },
            { label: '추가', handler: closePnrDetail, color: 'primary' }
          ]
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>등록일</TableCell>
              <TableCell>2024-05-21 14:27:42</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GDS</TableCell>
              <TableCell>TOPAS</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PNR</TableCell>
              <TableCell>5JPIKB(2)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>확정</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>구분</TableCell>
              <TableCell>개인</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>거래처</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발일</TableCell>
              <TableCell>2024-05-29</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발편명</TableCell>
              <TableCell>KE901</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발지</TableCell>
              <TableCell>ICN</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>도착지</TableCell>
              <TableCell>CDG</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>네임 TL</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>발권 TL</TableCell>
              <TableCell>2025-05-11 16:00:00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CustomDialog>
      
      <CustomDialog
        open={ttlaDetailOpen}
        onClose={closeTtlaDetail}
        maxWidth="sm"
        title={{text: 'TTLA 상세보기', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closeTtlaDetail, color: 'secondary' },
            { label: '추가', handler: closeTtlaDetail, color: 'primary' }
          ]
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>등록일</TableCell>
              <TableCell>2024-06-03 21:42:57</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GDS</TableCell>
              <TableCell>TOPAS</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PNR</TableCell>
              <TableCell>5Y6SYJ(4)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>확정</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>구분</TableCell>
              <TableCell>개인</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>거래처</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발일</TableCell>
              <TableCell>2024-07-20</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발편명</TableCell>
              <TableCell>OZ741</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발지</TableCell>
              <TableCell>ICN</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>도착지</TableCell>
              <TableCell>BKK</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>네임 TL</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>발권 TL</TableCell>
              <TableCell>2024-06-06 19:00:00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CustomDialog>
      
      <AlertDialog
        open={alertOpen}
        handleClose={AlertClose}
        title=""
        content="여정을 클릭해주세요."
      />
    </DefaultLayout>
  );
}

export default MainPage;

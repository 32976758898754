/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

import App from './App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { UtripControllerProvider } from './context';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import rootReducer from './modules/index';

import { createLogger } from 'redux-logger';
import './assets/scss/common.scss';

const logger = createLogger();
// composeWithDevTools(applyMiddleware(logger))
const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <UtripControllerProvider>
        <App />
      </UtripControllerProvider>
    </BrowserRouter>
  </Provider>
);

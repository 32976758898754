/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenLoading, setOpenDialogPopup, useUtripContextApi } from '../../context';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

function NonamePnrCreatePage({ goToResult }) {
  const [controller, dispatch] = useUtripContextApi();

  const [radioValue, setRadioValue] = useState('topas');

  const [travelInfo, setTravelInfo] = useState({
    airline: sessionStorage.getItem('noname_airline') || '',
    arr_air_port1: sessionStorage.getItem('noname_arr_air_port1') || '',
    arr_air_port2: sessionStorage.getItem('noname_arr_air_port2') || '',
    arr_air_port3: sessionStorage.getItem('noname_arr_air_port3') || '',
    arr_air_port4: sessionStorage.getItem('noname_arr_air_port4') || '',
    cls1: sessionStorage.getItem('noname_cls1') || '',
    cls2: sessionStorage.getItem('noname_cls2') || '',
    cls3: sessionStorage.getItem('noname_cls3') || '',
    cls4: sessionStorage.getItem('noname_cls4') || '',
    date1: sessionStorage.getItem('noname_date1') || '',
    date2: sessionStorage.getItem('noname_date2') || '',
    date3: sessionStorage.getItem('noname_date3') || '',
    date4: sessionStorage.getItem('noname_date4') || '',
    dep_air_port1: sessionStorage.getItem('noname_dep_air_port1') || '',
    dep_air_port2: sessionStorage.getItem('noname_dep_air_port2') || '',
    dep_air_port3: sessionStorage.getItem('noname_dep_air_port3') || '',
    dep_air_port4: sessionStorage.getItem('noname_dep_air_port4') || '',
    flight1: sessionStorage.getItem('noname_flight1') || '',
    flight2: sessionStorage.getItem('noname_flight2') || '',
    flight3: sessionStorage.getItem('noname_flight3') || '',
    flight4: sessionStorage.getItem('noname_flight4') || '',
    gds: radioValue,
    grpName: sessionStorage.getItem('noname_grpName') || '',
    grptype: sessionStorage.getItem('noname_grptype') || '',
    person_num: sessionStorage.getItem('noname_person_num') || '',
    remark: sessionStorage.getItem('noname_remark') || '',
  });

  const handleTravelInfo = (event) => {
    const { name, value } = event.target;
    setTravelInfo({
      ...travelInfo,
      [name]: value,
    });
    sessionStorage.setItem(`noname_${name}`, value);
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const clearInfo = () => {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.startsWith("noname_")) {
        sessionStorage.removeItem(key);
        i--;
      }
    }
    location.reload();
  };

  const reserveSchedule = async () => {
    setOpenLoading(dispatch, { showYn: true });

    const body = {
      airline: travelInfo?.airline,
      arr_air_port1: travelInfo?.arr_air_port1,
      arr_air_port2: travelInfo?.arr_air_port2,
      arr_air_port3: travelInfo?.arr_air_port3,
      arr_air_port4: travelInfo?.arr_air_port4,
      cls1: travelInfo?.cls1,
      cls2: travelInfo?.cls2,
      cls3: travelInfo?.cls3,
      cls4: travelInfo?.cls4,
      date1: travelInfo?.date1,
      date2: travelInfo?.date2,
      date3: travelInfo?.date3,
      date4: travelInfo?.date4,
      dep_air_port1: travelInfo?.dep_air_port1,
      dep_air_port2: travelInfo?.dep_air_port2,
      dep_air_port3: travelInfo?.dep_air_port3,
      dep_air_port4: travelInfo?.dep_air_port4,
      flight1: travelInfo?.flight1,
      flight2: travelInfo?.flight2,
      flight3: travelInfo?.flight3,
      flight4: travelInfo?.flight4,
      gds: radioValue,
      grpName: travelInfo?.grpName,
      grptype: travelInfo?.grptype,
      person_num: travelInfo?.person_num,
      remark: travelInfo?.remark,
    };

    try {
      const response = await utripApi.post('/apis/func_wire/v2/nonamequickres', body);

      if (response.status === 200 && response?.data.length >= 1) {
        response.data.forEach((item, index) => {
          item['No'] = index + 1;
        });
        sessionStorage.setItem('nonameResultIndv', JSON.stringify(response?.data));
        goToResult();
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      console.error(error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  return (
    <>
      <Paper className="custom-paper">
        <Typography variant="h5" fontWeight={700} mb={5} textAlign="center" gutterBottom>
          노네임단체 PNR생성(개별)
        </Typography>
        <Box
          mt={10}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RadioGroup row name="name" value={radioValue} onChange={radioChange}>
            <FormControlLabel value="topas" control={<Radio />} label="토파스" />
            <FormControlLabel value="sabre" control={<Radio />} label="세이버" />
          </RadioGroup>
        </Box>
        <Box>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            1. 단체정보입력
          </Typography>
          <Grid
            container
            px={4}
            py={3}
            flexWrap="nowrap"
            sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
          >
            <Grid item xs>
              <TextField
                label="항공사"
                variant="outlined"
                placeholder="KE"
                size="small"
                InputProps={{ required: true }}
                {...register('airline')}
                onChange={handleTravelInfo}
                value={travelInfo?.airline || ''}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="단체 영문명"
                variant="outlined"
                placeholder="TRU"
                size="small"
                InputProps={{ required: true }}
                {...register('grpName')}
                onChange={handleTravelInfo}
                value={travelInfo?.grpName || ''}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label="인원"
                variant="outlined"
                placeholder="150"
                size="small"
                InputProps={{ required: true }}
                {...register('person_num')}
                onChange={handleTravelInfo}
                value={travelInfo?.person_num || ''}
              />
            </Grid>
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <TextField
                label="단체성격"
                variant="outlined"
                placeholder="PKG"
                size="small"
                {...register('grptype')}
                onChange={handleTravelInfo}
                value={travelInfo?.grptype || ''}
              />
            </Grid>
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <TextField
                label="리막사항"
                variant="outlined"
                placeholder="CHINMOKHOI"
                size="small"
                {...register('remark')}
                onChange={handleTravelInfo}
                value={travelInfo?.remark || ''}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={5}>
          <Typography variant="subtitle1" fontWeight={700} gutterBottom>
            2. 항공스케쥴입력
          </Typography>

          <Grid
            container
            px={4}
            py={3}
            flexWrap="nowrap"
            sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
          >
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
              여정1
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="편명"
                variant="outlined"
                placeholder="KE1234"
                size="small"
                {...register('flight1')}
                onChange={handleTravelInfo}
                value={travelInfo?.flight1 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="CLASS"
                variant="outlined"
                placeholder="Y"
                size="small"
                {...register('cls1')}
                onChange={handleTravelInfo}
                value={travelInfo?.cls1 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="날짜"
                variant="outlined"
                placeholder="20JAN"
                size="small"
                {...register('date1')}
                onChange={handleTravelInfo}
                value={travelInfo?.date1 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="출발지"
                variant="outlined"
                placeholder="ICN"
                size="small"
                {...register('dep_air_port1')}
                onChange={handleTravelInfo}
                value={travelInfo?.dep_air_port1 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="목적지"
                variant="outlined"
                placeholder="DAD"
                size="small"
                {...register('arr_air_port1')}
                onChange={handleTravelInfo}
                value={travelInfo?.arr_air_port1 || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            px={4}
            py={3}
            flexWrap="nowrap"
            sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
          >
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
              여정2
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="편명"
                variant="outlined"
                placeholder="KE1234"
                size="small"
                {...register('flight2')}
                onChange={handleTravelInfo}
                value={travelInfo?.flight2 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="CLASS"
                variant="outlined"
                placeholder="Y"
                size="small"
                {...register('cls2')}
                onChange={handleTravelInfo}
                value={travelInfo?.cls2 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="날짜"
                variant="outlined"
                placeholder="20JAN"
                size="small"
                {...register('date2')}
                onChange={handleTravelInfo}
                value={travelInfo?.date2 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="출발지"
                variant="outlined"
                placeholder="ICN"
                size="small"
                {...register('dep_air_port2')}
                onChange={handleTravelInfo}
                value={travelInfo?.dep_air_port2 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="목적지"
                variant="outlined"
                placeholder="DAD"
                size="small"
                {...register('arr_air_port2')}
                onChange={handleTravelInfo}
                value={travelInfo?.arr_air_port2 || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            px={4}
            py={3}
            flexWrap="nowrap"
            sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
          >
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
              여정3
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="편명"
                variant="outlined"
                placeholder="KE1234"
                size="small"
                {...register('flight3')}
                onChange={handleTravelInfo}
                value={travelInfo?.flight3 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="CLASS"
                variant="outlined"
                placeholder="Y"
                size="small"
                {...register('cls3')}
                onChange={handleTravelInfo}
                value={travelInfo?.cls3 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="날짜"
                variant="outlined"
                placeholder="20JAN"
                size="small"
                {...register('date3')}
                onChange={handleTravelInfo}
                value={travelInfo?.date3 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="출발지"
                variant="outlined"
                placeholder="ICN"
                size="small"
                {...register('dep_air_port3')}
                onChange={handleTravelInfo}
                value={travelInfo?.dep_air_port3 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="목적지"
                variant="outlined"
                placeholder="DAD"
                size="small"
                {...register('arr_air_port3')}
                onChange={handleTravelInfo}
                value={travelInfo?.arr_air_port3 || ''}
              />
            </Grid>
          </Grid>
          <Grid
            container
            px={4}
            py={3}
            flexWrap="nowrap"
            sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
          >
            <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
              여정4
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="편명"
                variant="outlined"
                placeholder="KE1234"
                size="small"
                {...register('flight4')}
                onChange={handleTravelInfo}
                value={travelInfo?.flight4 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="CLASS"
                variant="outlined"
                placeholder="Y"
                size="small"
                {...register('cls4')}
                onChange={handleTravelInfo}
                value={travelInfo?.cls4 || ''}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="날짜"
                variant="outlined"
                placeholder="20JAN"
                size="small"
                {...register('date4')}
                onChange={handleTravelInfo}
                value={travelInfo?.date4 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="출발지"
                variant="outlined"
                placeholder="ICN"
                size="small"
                {...register('dep_air_port4')}
                onChange={handleTravelInfo}
                value={travelInfo?.dep_air_port4 || ''}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <TextField
                label="목적지"
                variant="outlined"
                placeholder="DAD"
                size="small"
                {...register('arr_air_port4')}
                onChange={handleTravelInfo}
                value={travelInfo?.arr_air_port4 || ''}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          onClick={() => reserveSchedule()}
        >
          예약하기
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ px: 8 }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>
      </Stack>
    </>
  );
}

export default NonamePnrCreatePage;

function Header({ isMobile }) {
  return (
    <>
      <div className="chatbot-header">
        {isMobile ? <div className="user-id">AI U-BOT</div>
          :
          <div className="user-id">GDS 명령어 검색 전문 AI 챗봇 “U-Bot(beta)"입니다. 생성형 AI 이므로 참고용으로 활용해주세요.</div>
        }
      </div>
    </>
  );
}

export default Header;
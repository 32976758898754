import { createSvgIcon } from '@mui/material/utils';

const WhiteIconCalendar = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 9H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'WhiteIconCalendar'
);

const IconCalendar = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 9H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconCalendar'
);

const WhiteIconAddress = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 15.5C13.2164 14.3589 11.981 13.5 10.5 13.5C9.019 13.5 7.78364 14.3589 7.5 15.5M21 5V7M21 11V13M21 17V19M6.2 21H14.8C15.9201 21 16.4802 21 16.908 20.782C17.2843 20.5903 17.5903 20.2843 17.782 19.908C18 19.4802 18 18.9201 18 17.8V6.2C18 5.0799 18 4.51984 17.782 4.09202C17.5903 3.71569 17.2843 3.40973 16.908 3.21799C16.4802 3 15.9201 3 14.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21ZM11.5 9.5C11.5 10.0523 11.0523 10.5 10.5 10.5C9.94772 10.5 9.5 10.0523 9.5 9.5C9.5 8.94772 9.94772 8.5 10.5 8.5C11.0523 8.5 11.5 8.94772 11.5 9.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'WhiteIconAddress'
);

const IconAddress = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 15.5C13.2164 14.3589 11.981 13.5 10.5 13.5C9.019 13.5 7.78364 14.3589 7.5 15.5M21 5V7M21 11V13M21 17V19M6.2 21H14.8C15.9201 21 16.4802 21 16.908 20.782C17.2843 20.5903 17.5903 20.2843 17.782 19.908C18 19.4802 18 18.9201 18 17.8V6.2C18 5.0799 18 4.51984 17.782 4.09202C17.5903 3.71569 17.2843 3.40973 16.908 3.21799C16.4802 3 15.9201 3 14.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21ZM11.5 9.5C11.5 10.0523 11.0523 10.5 10.5 10.5C9.94772 10.5 9.5 10.0523 9.5 9.5C9.5 8.94772 9.94772 8.5 10.5 8.5C11.0523 8.5 11.5 8.94772 11.5 9.5Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconAddress'
);

const WhiteIconFace = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2"
      y="4.5"
      width="20"
      height="15"
      rx="1.31949"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6294 10.2236L18.6861 10.2236"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6294 13.3428L18.6861 13.3428"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.62598 10.745C6.62598 12.1806 7.75615 13.3445 9.15028 13.3445C9.50272 13.3445 9.8383 13.2701 10.143 13.1357C11.0436 12.7386 11.6746 11.8177 11.6746 10.745C11.6746 9.30933 10.5444 8.14551 9.15028 8.14551C7.75615 8.14551 6.62598 9.30933 6.62598 10.745Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.31396 15.9175C5.71508 15.1318 6.27881 14.4825 6.95098 14.0319C7.62315 13.5814 8.38115 13.3447 9.15211 13.3447C9.92307 13.3447 10.6811 13.5814 11.3532 14.0319C12.0254 14.4825 12.5891 15.1318 12.9903 15.9175"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'WhiteIconFace'
);

const IconFace = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2"
      y="4.5"
      width="20"
      height="15"
      rx="1.31949"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6294 10.2236L18.6861 10.2236"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6294 13.3428L18.6861 13.3428"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.62598 10.745C6.62598 12.1806 7.75615 13.3445 9.15028 13.3445C9.50272 13.3445 9.8383 13.2701 10.143 13.1357C11.0436 12.7386 11.6746 11.8177 11.6746 10.745C11.6746 9.30933 10.5444 8.14551 9.15028 8.14551C7.75615 8.14551 6.62598 9.30933 6.62598 10.745Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.31396 15.9175C5.71508 15.1318 6.27881 14.4825 6.95098 14.0319C7.62315 13.5814 8.38115 13.3447 9.15211 13.3447C9.92307 13.3447 10.6811 13.5814 11.3532 14.0319C12.0254 14.4825 12.5891 15.1318 12.9903 15.9175"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconFace'
);

const IconLaptop = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 16V9.2C20 8.0799 20 7.51984 19.782 7.09202C19.5903 6.71569 19.2843 6.40973 18.908 6.21799C18.4802 6 17.9201 6 16.8 6H12M4 16V13M7.26953 8.26953L8.5 9.5M21 16V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8V16H8.33726C8.58185 16 8.70414 16 8.81923 16.0276C8.92127 16.0521 9.01881 16.0925 9.10828 16.1474C9.2092 16.2092 9.29568 16.2957 9.46863 16.4686L9.53137 16.5314C9.70432 16.7043 9.7908 16.7908 9.89172 16.8526C9.98119 16.9075 10.0787 16.9479 10.1808 16.9724C10.2959 17 10.4182 17 10.6627 17H13.3373C13.5818 17 13.7041 17 13.8192 16.9724C13.9213 16.9479 14.0188 16.9075 14.1083 16.8526C14.2092 16.7908 14.2957 16.7043 14.4686 16.5314L14.5314 16.4686C14.7043 16.2957 14.7908 16.2092 14.8917 16.1474C14.9812 16.0925 15.0787 16.0521 15.1808 16.0276C15.2959 16 15.4182 16 15.6627 16H21ZM8 6.5C8 7.88071 6.88071 9 5.5 9C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4C6.88071 4 8 5.11929 8 6.5Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconLaptop'
);

const WhiteIconRobot = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.825 8.83301H5.175C4.5951 8.83301 4.125 9.3056 4.125 9.88856V20.4441C4.125 21.0271 4.5951 21.4997 5.175 21.4997H18.825C19.4049 21.4997 19.875 21.0271 19.875 20.4441V9.88856C19.875 9.3056 19.4049 8.83301 18.825 8.83301Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M8.32502 14.1111C8.90492 14.1111 9.37502 13.6385 9.37502 13.0556C9.37502 12.4726 8.90492 12 8.32502 12C7.74513 12 7.27502 12.4726 7.27502 13.0556C7.27502 13.6385 7.74513 14.1111 8.32502 14.1111Z"
      fill="white"
    />
    <path
      d="M15.675 14.1111C16.2549 14.1111 16.725 13.6385 16.725 13.0556C16.725 12.4726 16.2549 12 15.675 12C15.0951 12 14.625 12.4726 14.625 13.0556C14.625 13.6385 15.0951 14.1111 15.675 14.1111Z"
      fill="white"
    />
    <path
      d="M9.89998 17.2778C9.6215 17.2778 9.35443 17.3612 9.15751 17.5097C8.9606 17.6582 8.84998 17.8595 8.84998 18.0695C8.84998 18.2795 8.9606 18.4808 9.15751 18.6293C9.35443 18.7778 9.6215 18.8612 9.89998 18.8612V17.2778ZM14.1 18.8612C14.3785 18.8612 14.6455 18.7778 14.8424 18.6293C15.0394 18.4808 15.15 18.2795 15.15 18.0695C15.15 17.8595 15.0394 17.6582 14.8424 17.5097C14.6455 17.3612 14.3785 17.2778 14.1 17.2778V18.8612ZM9.89998 18.8612H14.1V17.2778H9.89998V18.8612Z"
      fill="white"
    />
    <path
      d="M12 4.61133V8.83355M1.5 13.0558V17.278M22.5 13.0558V17.278"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4.61111C12.5799 4.61111 13.05 4.13852 13.05 3.55556C13.05 2.97259 12.5799 2.5 12 2.5C11.4201 2.5 10.95 2.97259 10.95 3.55556C10.95 4.13852 11.4201 4.61111 12 4.61111Z"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>,
  'IconRobot'
);

const IconRobot = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.825 8.83301H5.175C4.5951 8.83301 4.125 9.3056 4.125 9.88856V20.4441C4.125 21.0271 4.5951 21.4997 5.175 21.4997H18.825C19.4049 21.4997 19.875 21.0271 19.875 20.4441V9.88856C19.875 9.3056 19.4049 8.83301 18.825 8.83301Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
    />
    <path
      d="M8.32502 14.1111C8.90492 14.1111 9.37502 13.6385 9.37502 13.0556C9.37502 12.4726 8.90492 12 8.32502 12C7.74513 12 7.27502 12.4726 7.27502 13.0556C7.27502 13.6385 7.74513 14.1111 8.32502 14.1111Z"
      fill="#6f6b7d"
    />
    <path
      d="M15.675 14.1111C16.2549 14.1111 16.725 13.6385 16.725 13.0556C16.725 12.4726 16.2549 12 15.675 12C15.0951 12 14.625 12.4726 14.625 13.0556C14.625 13.6385 15.0951 14.1111 15.675 14.1111Z"
      fill="#6f6b7d"
    />
    <path
      d="M9.89998 17.2778C9.6215 17.2778 9.35443 17.3612 9.15751 17.5097C8.9606 17.6582 8.84998 17.8595 8.84998 18.0695C8.84998 18.2795 8.9606 18.4808 9.15751 18.6293C9.35443 18.7778 9.6215 18.8612 9.89998 18.8612V17.2778ZM14.1 18.8612C14.3785 18.8612 14.6455 18.7778 14.8424 18.6293C15.0394 18.4808 15.15 18.2795 15.15 18.0695C15.15 17.8595 15.0394 17.6582 14.8424 17.5097C14.6455 17.3612 14.3785 17.2778 14.1 17.2778V18.8612ZM9.89998 18.8612H14.1V17.2778H9.89998V18.8612Z"
      fill="#6f6b7d"
    />
    <path
      d="M12 4.61133V8.83355M1.5 13.0558V17.278M22.5 13.0558V17.278"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4.61111C12.5799 4.61111 13.05 4.13852 13.05 3.55556C13.05 2.97259 12.5799 2.5 12 2.5C11.4201 2.5 10.95 2.97259 10.95 3.55556C10.95 4.13852 11.4201 4.61111 12 4.61111Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
    />
  </svg>,
  'IconRobot'
);

const IconUserPen = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 21C3 17.134 6.13401 14 10 14C10.8808 14 11.7236 14.1627 12.5 14.4596M14 21L16.025 20.595C16.2015 20.5597 16.2898 20.542 16.3721 20.5097C16.4452 20.4811 16.5147 20.4439 16.579 20.399C16.6516 20.3484 16.7152 20.2848 16.8426 20.1574L21 16C21.5523 15.4477 21.5523 14.5523 21 14C20.4477 13.4477 19.5523 13.4477 19 14L14.8426 18.1574C14.7152 18.2848 14.6516 18.3484 14.601 18.421C14.5561 18.4853 14.5189 18.5548 14.4903 18.6279C14.458 18.7102 14.4403 18.7985 14.405 18.975L14 21ZM14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconUserPen'
);

const IconSearch = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0001 13.5C13.0001 13.5 13.0001 12.889 13.0001 12.1667C13.0001 11.2055 13.0001 11 11.0001 11C9.00009 11 8.99834 10.7328 9.0001 9.83252C9.00245 8.58501 9.88294 8.50002 11.0001 8.50004M11.0001 13.5H9.0001M11.0001 13.5L11.0001 14.5M13.0001 8.5L11.0001 8.50004M11.0001 8.50004L11.0001 7.5"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconSearch'
);

const IconCalendarUser = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3V6M17 3V6M7.10002 20C7.56329 17.7178 9.58104 16 12 16C14.419 16 16.4367 17.7178 16.9 20M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21ZM14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconCalendarUser'
);

const IconUsers = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 20V18C13 15.2386 10.7614 13 8 13C5.23858 13 3 15.2386 3 18V20H13ZM13 20H21V19C21 16.0545 18.7614 14 16 14C14.5867 14 13.3103 14.6255 12.4009 15.6311M11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconUsers'
);

const IconHouseCheck = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 19.3998L16.7143 20.9998L21 16.9998M5 9.77728V16.1998C5 17.88 5 18.7201 5.32698 19.3618C5.6146 19.9263 6.07354 20.3852 6.63803 20.6729C7.27976 20.9998 8.11984 20.9998 9.8 20.9998H11M21 11.9998L15.5668 5.96381C14.3311 4.59104 13.7133 3.90466 12.9856 3.65126C12.3466 3.4287 11.651 3.42875 11.0119 3.65141C10.2843 3.90491 9.66661 4.59139 8.43114 5.96433L3 11.9998"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconHouseCheck'
);

const IconDriveUpload = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4.125V14.25M6.375 14.25H4.125C3.82663 14.25 3.54048 14.3685 3.32951 14.5795C3.11853 14.7905 3 15.0766 3 15.375V19.875C3 20.1734 3.11853 20.4595 3.32951 20.6705C3.54048 20.8815 3.82663 21 4.125 21H19.875C20.1734 21 20.4595 20.8815 20.6705 20.6705C20.8815 20.4595 21 20.1734 21 19.875V15.375C21 15.0766 20.8815 14.7905 20.6705 14.5795C20.4595 14.3685 20.1734 14.25 19.875 14.25H17.625M7.5 8.625L12 3L16.5 8.625M17.625 17.625H17.6363"
      stroke="#6f6b7d"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'IconDriveUpload'
);

export {
  WhiteIconCalendar,
  IconCalendar,
  WhiteIconAddress,
  IconAddress,
  WhiteIconFace,
  IconFace,
  IconLaptop,
  WhiteIconRobot,
  IconRobot,
  IconUserPen,
  IconSearch,
  IconCalendarUser,
  IconUsers,
  IconHouseCheck,
  IconDriveUpload,
};

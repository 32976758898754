/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState } from 'react';

import utripApi from '../utils/api/utripApi';
import { useUtripContextApi, setOpenLoading, setOpenDialogPopup } from '../context';

import Header from '../pages/aiBot/header';
import Content from '../pages/aiBot/content';
import Footer from '../pages/aiBot/footer';
import { IconRobot } from './icons';

function FloatingChat() {
  const clickButton = () => {
    const floatChat = document.querySelector('.floating-chat');
    floatChat.classList.toggle('on');
  };

  const [value, setValue] = useState('');
  const [controller, dispatch] = useUtripContextApi();
  const [gds, setGds] = useState('');
  const [loading, setLoading] = useState(false);

  const [requests, setRequests] = useState([]);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const savedRequests = JSON.parse(sessionStorage.getItem('aiBotReq')) || [];
    const savedResponses = JSON.parse(sessionStorage.getItem('aiBotRes')) || [];
    setRequests(savedRequests);
    setResponses(savedResponses);
  }, []);

  useEffect(() => {
    if (requests.length > 0 || responses.length > 0) {
      sessionStorage.setItem('aiBotReq', JSON.stringify(requests));
      sessionStorage.setItem('aiBotRes', JSON.stringify(responses));
    }
  }, [requests, responses]);

  const callApi = async () => {
    if (value == '') {
      return;
    }
    setLoading(true);

    const request = {
      message: value,
      time: new Date().toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
    };
    setRequests((prevRequests) => [...prevRequests, request]);

    const query = value;
    const determineGdsValue = (value) => {
      if (value.includes("세이버") || value.includes("새이버") || value.includes("sabre") || value.includes("Sabre")) {
        return 'sabre';
      } else {
        return 'topas';
      }
    };
    setValue('');

    try {
      const response = await utripApi.get(
        `/apis/func_wire/v2/search?gds=${determineGdsValue(query)}&query=${query}`
      );

      if (response.status === 200) {
        const responseMessage = {
          message: response.data.gpt_answer,
          time: new Date().toLocaleTimeString('ko-KR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        };
        setResponses((prevResponses) => [...prevResponses, responseMessage]);
      }
    } catch (error) {
      const errorMessage = {
        message: 'REQUESTED DISPLAY NOT AVAILABLE',
        time: new Date().toLocaleTimeString('ko-KR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }),
      };
      setResponses((prevResponses) => [...prevResponses, errorMessage]);
    }

    setLoading(false);
  };

  const handleChangeValue = (newValue) => {
    setValue(newValue);
  };
  // 드래그 앤 드롭 로직
  useEffect(() => {
    const floatingChat = document.querySelector(".floating-chat");
    let isDragging = false;
    let offsetX = 0;
    let offsetY = 0;

    const handleMouseDown = (e) => {
      isDragging = true;

      // 아이콘의 현재 위치와 마우스의 상대적 위치 계산
      const rect = floatingChat.getBoundingClientRect();
      offsetX = e.clientX - rect.left;
      offsetY = e.clientY - rect.top;

      floatingChat.style.cursor = "grabbing";
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      // 마우스 위치 기반 새로운 좌표 계산
      const x = e.clientX - offsetX;
      const y = e.clientY - offsetY;

      // 화면 경계를 계산하여 이동 제한
      const maxX = window.innerWidth - floatingChat.offsetWidth; // 오른쪽 경계
      const maxY = window.innerHeight - floatingChat.offsetHeight; // 아래쪽 경계

      // 새로운 좌표를 적용
      floatingChat.style.left = `${Math.max(0, Math.min(x, maxX))}px`;
      floatingChat.style.top = `${Math.max(0, Math.min(y, maxY))}px`;

      // `left`와 `top`으로 위치 고정
      floatingChat.style.right = "auto";
      floatingChat.style.bottom = "auto";
      floatingChat.style.position = "fixed";
    };

    const handleMouseUp = () => {
      isDragging = false;
      floatingChat.style.cursor = "grab";
    };

    // 이벤트 리스너 등록
    floatingChat.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    // 이벤트 리스너 정리
    return () => {
      floatingChat.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);
  return (
    <>
      <div className="floating-chat">
        <div className="floating-chat-device">
          <Header isMobile={true} />
          <Content
            isLoading={loading}
            callApi={callApi}
            responses={responses}
            requests={requests}
          />
          <Footer callApi={callApi} onChangeValue={handleChangeValue} />
        </div>

        <button className="btn-floating-chat">
          <IconRobot fontSize="large" onClick={clickButton} />
        </button>
      </div>
    </>
  );
}

export default FloatingChat;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// ====================== MUI Components  =========================

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TableFooter from '@mui/material/TableFooter';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import DefaultLayout from '../../layouts/defaultLayout';

function LowestPricingResult() {
  const [age, setAge] = useState('성인');
  const [gender, setGender] = useState('');
  const ageTypes = ['성인', '유아', '소아'];
  const genderTypes = ['남', '여'];
  const navigate = useNavigate();

  // useEffect(() => {
  //   setResJson(Array.from({ length: totalNum }, createDefaultMember));
  // }, [totalNum]);

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const goToReservation = () => {
    navigate(`/pricingpage/reservation`);
  };

  const lowestPriceResult = JSON.parse(sessionStorage.getItem('lowestPriceResult'));
  const selectedSegInfo = sessionStorage.getItem('selectedSegInfo');
  const pricingInfo = JSON.parse(sessionStorage.getItem('selectedTravelInfo'));
  const lowestPassMember = JSON.parse(sessionStorage.getItem('lowestPassMember'));

  const ageChange = (event) => {
    setAge(event.target.value);
  };

  const genderChange = (event) => {
    setGender(event.target.value);
  };

  return (
    <DefaultLayout pageTitle="예약 요청정보 및 예약결과">
      <>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Paper className="custom-paper">
              <Box>
                <Typography variant="subtitle1" mb={2} fontWeight={700}>
                  [탑승자 정보]
                </Typography>

                <TableContainer>
                  <Table className="custom-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">구분</TableCell>
                        <TableCell align="center">성별</TableCell>
                        <TableCell align="center">성</TableCell>
                        <TableCell align="center">이름</TableCell>
                        <TableCell align="center">생년월일</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lowestPassMember?.map((member, currentRowIndex) => (
                        <TableRow key={currentRowIndex}>
                          <TableCell align="center">{currentRowIndex + 1}</TableCell>
                          <TableCell align="center">
                            <TextField
                              label="구분"
                              variant="outlined"
                              size="small"
                              value={member?.resListAdtcheck}
                              readOnly={true}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="성별"
                              variant="outlined"
                              size="small"
                              value={member?.resListGender}
                              readOnly={true}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="성"
                              variant="outlined"
                              size="small"
                              value={member?.resListFname}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="이름"
                              variant="outlined"
                              size="small"
                              value={member?.resListName}
                              readOnly={true}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="생년월일"
                              variant="outlined"
                              size="small"
                              value={member?.resListBirth}
                              readOnly={true}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box mt={8}>
                <Typography variant="subtitle1" mb={2} fontWeight={700}>
                  [여정 및 예상가격]
                </Typography>

                <Alert severity="success">{selectedSegInfo}</Alert>

                <TableContainer className="custom-scrollbar" sx={{ mt: 5 }}>
                  <Table className="custom-table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">PTC</TableCell>
                        <TableCell align="center">운임</TableCell>
                        <TableCell align="center">TAX</TableCell>
                        <TableCell align="center">총 합계</TableCell>
                        <TableCell align="center">FARE BASIS</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pricingInfo?.items.map((item) => (
                        <TableRow key={item?.no}>
                          <TableCell align="center">{item?.ptc}</TableCell>

                          <TableCell align="center">{item?.fee}</TableCell>
                          <TableCell align="center">{item?.tax}</TableCell>
                          <TableCell align="center">{item?.price}</TableCell>
                          <TableCell align="center">{item?.basis}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter sx={{ bgcolor: 'grey.800' }}>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={{ color: 'white', fontWeight: 700 }}
                        >
                          TOTAL
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.fee}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.tax}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.price}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>

            <Box py={3} px={5}>
              <Typography variant="body" color="error.main">
                {/* 운임 TL : 30MAY24 */}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Paper className="custom-paper">
              <Alert severity="info">{convertNewlinesToBreaks(lowestPriceResult)}</Alert>
            </Paper>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => goToReservation()}
            sx={{ px: 8, bgcolor: 'secondary.main', color: 'white' }}
          >
            이전
          </Button>
          <Link href="/calendar">
            <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }}>
              Home
            </Button>
          </Link>
        </Stack>
      </>
    </DefaultLayout>
  );
}

export default LowestPricingResult;

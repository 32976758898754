// ====================== COMMON ======================
import { useState } from 'react';

// ====================== MUI ======================
import {
  Grid
} from '@mui/material';

// ====================== Layouts ======================
import DefaultLayout from '../../layouts/defaultLayout';

// ====================== Components ======================
import Write from './write';
import Result from './result';
import ExcelWrite from './excel-write';
import ExcelResult from './excel-result';
import AlertDialog from '../../components/alert';

function GrpReservation() {
  const [isResult, setIsResult] = useState(false);
  const [isExcelResult, setIsExcelResult] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState(null);
  const items = [];
  
  const goToList = () => {
    setIsResult(false);
    setPageTitle(null);
  }
  
  const goToResult = () => {
    setIsResult(true);
    setPageTitle('예약결과');
  };
  
  const goToExcelResult = () => {
    setIsExcelResult(true);
    setPageTitle('예약결과');
  };
  
  for (let i = 1; i < 20; i++) {
    items.push({
      id: i,
      no: i,
      out: '7C2903 / 2155',
      in: '..',
      class: 'Z',
      tl: '20SEP24',
      price: 'KRW 183,100',
    });
  }
  
  const AlertOpen = () => {
    setAlertOpen(true);
  };
  
  const AlertClose = () => {
    setAlertOpen(false);
  };
  
  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid container spacing={5} flexDirection="column">
        <Grid item sx={{ ".MuiPaper-root.custom-paper": { height: "auto", maxHeight: 'none' }}}>
          {isExcelResult ?
            <ExcelResult /> :
            <ExcelWrite goToExcelResult={goToExcelResult} />
          }
        </Grid>
        <Grid item sx={{ ".MuiPaper-root.custom-paper": { height: "auto", maxHeight: 'none' }}}>
          {isResult ?
            <Result goToList={goToList} /> :
            <Write goToResult={goToResult} />
          }
        </Grid>
      </Grid>
      {/*{isResult ?
        <Result goToList={goToList} /> :
        <ListPage
          items={items}
          goToResult={goToResult}
        />
      }*/}
      
      <AlertDialog
        open={alertOpen}
        handleClose={AlertClose}
        title=""
        content="여정을 클릭해주세요."
      />
    </DefaultLayout>
  );
}

export default GrpReservation;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState, useRef } from 'react';
import MobileLoading from '../../components/mobile-loading';
// ====================== MUI Components  =========================

function Content({ responses, requests, isLoading, pcLoading }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [requests, responses]);

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="chatbot-content" ref={containerRef}>
        {requests?.map((req, index) => (
          <div key={index}>
            <div className="chat-wrap my-chat">
              <div className="chat-box">
                <div className="text-balloon">{req?.message}</div>
                <div className="date">{req?.time}</div>
              </div>
            </div>
            <div className="chat-wrap bot-chat">
              <div className="chat-box">
                <div className="text-balloon">
                  {/* 마지막 요청일 때만 로딩 "..." 표시 */}
                  {(isLoading || pcLoading) && index === requests.length - 1 && (
                    <>
                      <div className="loading-dots"></div>
                      {/* <MobileLoading showYn={isLoading} /> */}
                    </>
                  )}

                  {convertNewlinesToBreaks(responses?.[index]?.message)}
                </div>
                <div className="date">{responses?.[index]?.time}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Content;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState } from 'react';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';
import { useNavigate } from 'react-router-dom';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';

// ====================== Service Components ======================

// ====================== Images ==================================

function LowestPriceListPage() {
  const [controller, dispatch] = useUtripContextApi();
  const navigate = useNavigate();

  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [seatType, setSeatType] = useState('Economy');
  const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const seatTypes = [
    { 일반석: 'Economy' },
    { '프리미엄 일반석': 'Premium' },
    { 비즈니스석: 'Business' },
    { 일등석: 'First' },
  ];

  const result = JSON.parse(sessionStorage.getItem('lowestPricing'));

  const goToDetail = (no) => {
    sessionStorage.setItem('lowestPricingDetail', no);
    navigate(`/pricingpage/${no}`);
  };

  const [travelInfo, setTravelInfo] = useState({
    adult_num: 0,
    apikey: 'R26RWLMTV8IVTF3F',
    arr_air_port1: sessionStorage.getItem('lowestPrice_arr_air_port1') || '',
    arr_air_port2: sessionStorage.getItem('lowestPrice_arr_air_port2') || '',
    arr_air_port3: sessionStorage.getItem('lowestPrice_arr_air_port3') || '',
    arr_air_port4: sessionStorage.getItem('lowestPrice_arr_air_port4') || '',
    chd_num: 0,
    chk_1: 0,
    date1: sessionStorage.getItem('lowestPriceDate1') || '',
    date2: sessionStorage.getItem('lowestPriceDate2') || '',
    date3: sessionStorage.getItem('lowestPriceDate3') || '',
    date4: sessionStorage.getItem('lowestPriceDate4') || '',
    dep_air_port1: sessionStorage.getItem('lowestPrice_dep_air_port1') || '',
    dep_air_port2: sessionStorage.getItem('lowestPrice_dep_air_port2') || '',
    dep_air_port3: sessionStorage.getItem('lowestPrice_dep_air_port3') || '',
    dep_air_port4: sessionStorage.getItem('lowestPrice_dep_air_port4') || '',
    inf_num: 0,
    seat_type: '',
    air_line1: '',
    air_line2: '',
    air_line3: '',
    time1: '',
    time2: '',
    time3: '',
    time4: '',
  });

  const clearInfo = () => {
    sessionStorage.removeItem('lowestPricing');
    sessionStorage.removeItem('travelMember');
    sessionStorage.removeItem('selectedSegInfo');
    sessionStorage.removeItem('selectedTravelInfo');
    sessionStorage.removeItem('lowestPricingDetail');
    // 추가
    sessionStorage.removeItem('lowestPrice_arr_air_port1');
    sessionStorage.removeItem('lowestPrice_arr_air_port2');
    sessionStorage.removeItem('lowestPrice_arr_air_port3');
    sessionStorage.removeItem('lowestPrice_arr_air_port4');

    sessionStorage.removeItem('lowestPrice_dep_air_port1');
    sessionStorage.removeItem('lowestPrice_dep_air_port2');
    sessionStorage.removeItem('lowestPrice_dep_air_port3');
    sessionStorage.removeItem('lowestPrice_dep_air_port4');

    sessionStorage.removeItem('lowestPriceDate1');
    sessionStorage.removeItem('lowestPriceDate2');
    sessionStorage.removeItem('lowestPriceDate3');
    sessionStorage.removeItem('lowestPriceDate4');


    location.reload();
  };

  const clickMirror = () => {
    if (!travelInfo?.arr_air_port1 || !travelInfo?.dep_air_port1) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '여정1은 필수로 입력해야 합니다.',
      });
      return;
    }
    setTravelInfo((prevInfo) => ({
      ...prevInfo,
      dep_air_port2: prevInfo.arr_air_port1,
      arr_air_port2: prevInfo.dep_air_port1,
    }));
    setValue('dep_air_port2', travelInfo?.arr_air_port1);
    setValue('arr_air_port2', travelInfo?.dep_air_port1);
  };

  const getLowerPrice = async (travelInfo) => {
    if (
      !travelInfo?.flight1 &&
      !travelInfo?.date1 &&
      !travelInfo?.dep_air_port1 &&
      !travelInfo?.arr_air_port1
    ) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `출발일 출발지공항 목적지공항을 입력 후 실행해주세요`,
      });
      return;
    }
    setOpenLoading(dispatch, { showYn: true });

    //탑승인원
    const member = {
      adult: adult,
      child: child,
      infant: infant,
    };

    sessionStorage.setItem('travelMember', JSON.stringify(member));
    // 세션스토리지 저장 작업
    sessionStorage.setItem('lowestPrice_arr_air_port1', travelInfo?.arr_air_port1);
    sessionStorage.setItem('lowestPrice_arr_air_port2', travelInfo?.arr_air_port2);
    sessionStorage.setItem('lowestPrice_arr_air_port3', travelInfo?.arr_air_port3);
    sessionStorage.setItem('lowestPrice_arr_air_port4', travelInfo?.arr_air_port4);

    sessionStorage.setItem('lowestPrice_dep_air_port1', travelInfo?.dep_air_port1);
    sessionStorage.setItem('lowestPrice_dep_air_port2', travelInfo?.dep_air_port2);
    sessionStorage.setItem('lowestPrice_dep_air_port3', travelInfo?.dep_air_port3);
    sessionStorage.setItem('lowestPrice_dep_air_port4', travelInfo?.dep_air_port4);

    sessionStorage.setItem('lowestPriceDate1', travelInfo?.date1);
    sessionStorage.setItem('lowestPriceDate2', travelInfo?.date2);
    sessionStorage.setItem('lowestPriceDate3', travelInfo?.date3);
    sessionStorage.setItem('lowestPriceDate4', travelInfo?.date4);

    const body = {
      adult_num: adult,
      apikey: 'R26RWLMTV8IVTF3F',
      arr_air_port1: travelInfo?.arr_air_port1,
      arr_air_port2: travelInfo?.arr_air_port2,
      arr_air_port3: travelInfo?.arr_air_port3,
      arr_air_port4: travelInfo?.arr_air_port4,
      chd_num: child,
      chk_1: 1,
      date1: travelInfo?.date1,
      date2: travelInfo?.date2,
      date3: travelInfo?.date3,
      date4: travelInfo?.date4,
      dep_air_port1: travelInfo?.dep_air_port1,
      dep_air_port2: travelInfo?.dep_air_port2,
      dep_air_port3: travelInfo?.dep_air_port3,
      dep_air_port4: travelInfo?.dep_air_port4,
      inf_num: infant,
      seat_type: seatType,
      air_line1: travelInfo?.air_line1,
      air_line2: travelInfo?.air_line2,
      air_line3: travelInfo?.air_line3,
      time1: '',
      time2: '',
      time3: '',
      time4: '',
    };

    try {
      const response = await utripApi.post('/apis/func_wire/v2/get_lowestprice_air', body);
      if (response.data.message === 'SUCCESS') {
        const inputData = response.data;
        const transformedData = [];
        for (let i = 0; i < Object.keys(inputData.Cls).length; i++) {
          transformedData.push({
            id: i,
            no: i + 1,
            out: inputData.j1[i].trim(),
            in: '..',
            class: inputData.Cls[i],
            tl: inputData.FareTL[i],
            price: inputData.Total[i],
            more: inputData.more[i],
          });
        }
        sessionStorage.setItem('lowestPricing', JSON.stringify(transformedData));
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };

  const handleTravelInfo = (event) => {
    const { name, value } = event.target;
    setTravelInfo({
      ...travelInfo,
      [name]: value,
    });
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const selectChange = (event) => {
    const name = event.target.name;

    switch (name) {
      case 'adult':
        setAdult(event.target.value);
        break;
      case 'child':
        setChild(event.target.value);
        break;
      case 'infant':
        setInfant(event.target.value);
        break;
      case 'seat_type':
        setSeatType(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <Grid
      component="form"
      role="form"
      onSubmit={handleSubmit((data) => {
        getLowerPrice(data);
      })}
    >
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여정1
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="출발일"
                  variant="outlined"
                  placeholder="20SEP"
                  size="small"
                  {...register('date1')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.date1 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="출발지"
                  variant="outlined"
                  placeholder="ICN"
                  size="small"
                  {...register('dep_air_port1')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.dep_air_port1 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="목적지"
                  variant="outlined"
                  placeholder="DAD"
                  size="small"
                  {...register('arr_air_port1')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.arr_air_port1 || ''}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Button
                  variant="contained"
                  sx={{
                    minWidth: 0,
                    px: 3,
                    bgcolor: '#fff0e1',
                    boxShadow: 0,
                    color: 'primary.main',
                  }}
                  onClick={() => clickMirror()}
                >
                  M
                </Button>
              </Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여정2
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발일"
                  variant="outlined"
                  placeholder="20SEP"
                  size="small"
                  {...register('date2')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.date2 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발지"
                  variant="outlined"
                  placeholder="ICN"
                  size="small"
                  {...register('dep_air_port2')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.dep_air_port2 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="목적지"
                  variant="outlined"
                  placeholder="DAD"
                  size="small"
                  {...register('arr_air_port2')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.arr_air_port2 || ''}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              ></Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여정3
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발일"
                  variant="outlined"
                  placeholder="20SEP"
                  size="small"
                  {...register('date3')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.date3 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발지"
                  variant="outlined"
                  placeholder="ICN"
                  size="small"
                  {...register('dep_air_port3')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.dep_air_port3 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="목적지"
                  variant="outlined"
                  placeholder="DAD"
                  size="small"
                  {...register('arr_air_port3')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.arr_air_port3 || ''}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              ></Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여정4
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발일"
                  variant="outlined"
                  placeholder="20SEP"
                  size="small"
                  {...register('date4')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.date4 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="출발지"
                  variant="outlined"
                  placeholder="ICN"
                  size="small"
                  {...register('dep_air_port4')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.dep_air_port4 || ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="목적지"
                  variant="outlined"
                  placeholder="DAD"
                  size="small"
                  {...register('arr_air_port4')}
                  onChange={handleTravelInfo}
                  value={travelInfo?.arr_air_port4 || ''}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              ></Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여행인원
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">어른</InputLabel>
                  <Select
                    labelId="label1"
                    id="select1"
                    value={adult}
                    name="adult"
                    label="Adult"
                    {...register('adult')}
                    onChange={selectChange}
                  >
                    {count.map((num) => (
                      <MenuItem key={num} value={num}>
                        ADT {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="label2">아동</InputLabel>
                  <Select
                    labelId="label2"
                    id="select2"
                    value={child}
                    name="child"
                    label="Child"
                    {...register('child')}
                    onChange={selectChange}
                  >
                    {count.map((num) => (
                      <MenuItem key={num} value={num}>
                        CHD {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="label3">유아</InputLabel>
                  <Select
                    labelId="label3"
                    id="select3"
                    value={infant}
                    name="infant"
                    label="Infant"
                    {...register('infant')}
                    onChange={selectChange}
                  >
                    {count.map((num) => (
                      <MenuItem key={num} value={num}>
                        INF {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              ></Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                선호항공사
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="선호항공사1"
                  variant="outlined"
                  placeholder="KE"
                  size="small"
                  {...register('air_line1')}
                  onChange={handleTravelInfo}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="선호항공사2"
                  variant="outlined"
                  placeholder="ZE"
                  size="small"
                  {...register('air_line2')}
                  onChange={handleTravelInfo}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  label="선호항공사3"
                  variant="outlined"
                  placeholder="OZ"
                  size="small"
                  {...register('air_line3')}
                  onChange={handleTravelInfo}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              ></Grid> */}
            </Grid>

            <Grid
              container
              py={3}
              px={4}
              flexWrap="nowrap"
              sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
            >
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                여행인원
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">좌석구분</InputLabel>
                  <Select
                    labelId="label4"
                    id="select4"
                    value={seatType}
                    name="seatType"
                    label="seatType"
                    {...register('seat_type')}
                    onChange={selectChange}
                  >
                    {seatTypes.map((seatType) => {
                      const [key, value] = Object.entries(seatType)[0];
                      return (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper table-paper">
            <TableContainer className="custom-scrollbar">
              <Table className="custom-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={80}>
                      NO
                    </TableCell>
                    <TableCell align="center">OUT</TableCell>
                    <TableCell align="center">IN</TableCell>
                    <TableCell align="center">CLASS</TableCell>
                    <TableCell align="center">운임TL</TableCell>
                    <TableCell align="center" width={150}>
                      총 발권금액
                    </TableCell>
                    <TableCell align="center" width={50}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result?.map((item) => (
                    <TableRow key={item?.no} onClick={() => goToDetail(item?.no)}>
                      <TableCell scope="row" align="center">
                        {item?.no}
                      </TableCell>
                      <TableCell align="center">{item?.out}</TableCell>
                      <TableCell align="center">{item?.in}</TableCell>
                      <TableCell align="center">{item?.class}</TableCell>
                      <TableCell align="center">{item?.tl}</TableCell>
                      <TableCell align="center">{item?.price}</TableCell>
                      <TableCell align="center">{item?.more}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          type="submit"
          disabled={isSubmitting}
        >
          조회
        </Button>
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, bgcolor: 'secondary.main', color: 'white' }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>

      </Stack>
    </Grid>

  );
}

export default LowestPriceListPage;

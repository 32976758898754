/*
  SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
  SPDX-License-Identifier: BSD-3-Clause
*/

import axios from 'axios';
import Cookies from 'js-cookie';
import { logoutRemoveAuthInfo } from '../session.js';
import { getGlobalDispatch } from '../../context'; // dispatch 헬퍼 함수 import

const API_TIMEOUT = 600000; // 30s
/*
  baseURL : 1. localhost:3000 -> api서버 바라볼 때는 baseURL: http://43.201.15.71:8080
            2. 서버올렸을 때는 nginx -> /api 부분 reverse-proxy설정해서 baseURL : /
            3. localhost:3000 -> localhost:8080 (로컬로 api 서버 올릴 때) baseURL: localhost:8080
*/
const errorCodeDict = {
  //Common
  'C-AA01': '[Sabre 미등록 계정] 마이 페이지에서 Sabre 계정 등록후 이용하세요.',
  'C-AA02': '[Sabre 계정 오류] 세이버 360에서 계정 상태를 확인하세요.',
  'C-AA03': '[사용자 정보 저장 알림] 단말기 설정은 "사용자 정보" 저장 후 사용 가능합니다.',
  //Page - Inputpage
  'P-IN01': '[]',
  //Page - OcrExtract
  'P-OC01': '[필드 오류]',
  'P-OC02': '[NAME UNMATCH]',
  //Page - SSRUpload
  'P-SS01': '[필드 오류] PNR 번호를 확인하세요.',

  'C-0001': '[Empty Value] 입력 값이 비어있습니다.',
  'C-0002': '[GDS Type Error] GDS값이 올바르지 않습니다.',
  'C-0004': '[GDS 설정] GDS를 설정해주세요.',
  'C-0005': '[GDS 설정] Topas를 설정해주세요.',
}
const errorCodeDict400 = {
  //Common
  'C-0001': '[Empty Value] 입력 값이 비어있습니다.',
  'C-0002': '[GDS Type Error] GDS값이 올바르지 않습니다.'
}
const generateErrorMessage = (errorCode, message) => {
  if (message) {
    return `<p>${errorCodeDict[errorCode]}</p><p>${message}</p>` +
      (errorCode === 'C-AA02' ? ` <a href="https://accounts.havail.sabre.com/login/srw" target="_blank">Sabre 360 바로가기</a>` : '');
  } else {
    return errorCodeDict[errorCode] || 'Unknown error';
  }
};


const errorPopup = async (response) => {
  let errorCode = response.data.errorcode;
  if (errorCode) {
    let errorMessage = response.data.message
    let msg = generateErrorMessage(errorCode, errorMessage);
    const dispatch = getGlobalDispatch();
    dispatch({
      type: 'OPEN_DIALOGPOPUP',
      value: {
        modalType: 'failCustom',
        showYn: true,
        content: `Error Code: ${errorCode}<br>${msg}`,
      },
    });
    return Promise.reject(response);
  }
  return response;
};

const utripApi = axios.create({
  baseURL: 'https://t2.utriprpa.com', // baseURL
  timeout: API_TIMEOUT,
  withCredentials: true,
  changeOrigin: true,
  headers: {
    Authorization: 'Bearer ' + Cookies.get('AccessToken') || '',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
    'Access-Control-Max-Age': '600',
    // "Content-Type": "multipart/form-data; application/json;",
  },
});

// Function to refresh the access token
const refreshAccessToken = () => {
  // Make a request to refresh the access token
  const JWT = {
    refresh_token: Cookies.get('RefreshToken'),
  };
  return utripApi.post('/apis/refresh', JWT);
};

// interceptor request
utripApi.interceptors.request.use(
  (config) => {
    // Add the access token to the request headers
    config.headers['Authorization'] = `Bearer ${Cookies.get('AccessToken') || ''}`;
    const session = Cookies.get('X-Session-ID') || ''
    if (session) config.headers['session'] = Cookies.get('X-Session-ID') || '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

utripApi.interceptors.response.use(
  (response) => {
    // If the response is successful, return it directly
    // [x] TODO ADD ErrorHandler 
    if (Object.hasOwn(response.data, 'errorcode')) {
      return errorPopup(response)
    }
    const session = response.headers["X-Session-ID"];
    if (session) {
      // 쿠키에 session 값을 저장
      Cookies.set("X-Session-ID", session, {
        path: "/", // 루트 경로에서만 사용
        secure: true, // HTTPS에서만 사용
        sameSite: "Strict", // CSRF 방지
      });
    }
    return response;
  },
  // axios 통신이 200 아닐 때 다 error로 감. 200이면 response로 가니까 모든 api 다 확인하게 된다. 성능문제 있을듯.
  async (error) => {
    const originalRequest = error.config;

    // Check if the error response status code indicates an expired access token
    if (error.response.data.msg === 'Token has expired' && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh the access token
        // const refreshTokenResponse = await refreshAccessToken();
        // Update the access token with the new one
        const newAccessToken = refreshTokenResponse.data.token;
        const newRefreshToken = refreshTokenResponse.data.refresh_token;
        const at = new Date();
        const rt = new Date();
        at.setTime(at.getTime() + 1000 * 60 * 60 * 24 * 30); // 30일
        rt.setTime(rt.getTime() + 1000 * 60 * 60 * 24 * 90); // 90일
        Cookies.set('AccessToken', newAccessToken, { expires: at });
        Cookies.set('RefreshToken', newRefreshToken, { expires: rt });

        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        return utripApi(originalRequest);
      } catch (refreshError) {
        // refreshToken 발급받는 과정에서 에러가 났을 경우 -> 재 로그인해야함.
        console.error('RefreshToken Expired :', refreshError);
        /*
         모달 띄우는 건 불가능함. (react hook을 못쓰기 때문에)
         redirectToLogin 페이지로 가서 로그인할 지 홈으로 이동할지 결정하게
         TODO:: 더 나은 방식은 없는가?
         */
        logoutRemoveAuthInfo();

        throw error; // Throw the original error to maintain the error flow
      } finally {
      }
    }
    return Promise.reject(error);
  }
);

export default utripApi;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import utripApi from '../../utils/api/utripApi';
import { useForm } from 'react-hook-form';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';

// ====================== MUI Components  =========================
import { css, styled } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal/Modal';
import {
  Grid,
  Paper,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Alert,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@mui/material';
// ====================== Service Components ======================

// ====================== Images ==================================

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'base-Backdrop-open': open }, className)} ref={ref} {...other} />;
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid ${theme.palette.grey[200]};
    box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
    padding: 24px;
    color: ${theme.palette.grey[900]};

    & .modal-title {
      margin: 0 0 8px;
      line-height: 1.5rem;
    }

    & .modal-description {
      margin: 0 0 4px;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.grey[800]};
    }
  `
);

function ListPage() {
  const [controller, dispatch] = useUtripContextApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [radioValue, setRadioValue] = useState('topas');
  const [selectedSegInfo, setSelectedSegInfo] = useState('');
  const ageTypeValues = [];

  // 토파스, 세이버 라디오 변경
  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [age, setAge] = useState('성인');
  const [gender, setGender] = useState('');
  const ageTypes = ['성인', '소아', '유아'];
  const genderTypes = ['남', '여'];

  const [reserveInfo, setReserveInfo] = useState({
    resNum: 'skd',
  });

  const selectChange = (event) => {
    const name = event.target.name;

    switch (name) {
      case 'adult':
        setAdult(event.target.value);
        break;
      case 'child':
        setChild(event.target.value);
        break;
      case 'infant':
        setInfant(event.target.value);
        break;
      default:
        break;
    }
  };
  const getIndSchedules = async () => {
    if (
      !travelInfo?.flight1 &&
      !travelInfo?.date1 &&
      !travelInfo?.dep_air_port1 &&
      !travelInfo?.arr_air_port1
    ) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `출발일 출발지공항 목적지공항을 입력 후 실행해주세요`,
      });
      return;
    }

    setOpenLoading(dispatch, { showYn: true });

    const body = {
      adult_num: adult,
      apikey: 'R26RWLMTV8IVTF3F',
      arr_air_port1: travelInfo?.arr_air_port1,
      arr_air_port2: travelInfo?.arr_air_port2,
      arr_air_port3: travelInfo?.arr_air_port3,
      arr_air_port4: travelInfo?.arr_air_port4,
      chd_num: child,
      cls1: travelInfo?.cls1,
      cls2: travelInfo?.cls2,
      cls3: travelInfo?.cls3,
      cls4: travelInfo?.cls4,
      date1: travelInfo?.date1,
      date2: travelInfo?.date2,
      date3: travelInfo?.date3,
      date4: travelInfo?.date4,
      dep_air_port1: travelInfo?.dep_air_port1,
      dep_air_port2: travelInfo?.dep_air_port2,
      dep_air_port3: travelInfo?.dep_air_port3,
      dep_air_port4: travelInfo?.dep_air_port4,
      flight1: travelInfo?.flight1,
      flight2: travelInfo?.flight2,
      flight3: travelInfo?.flight3,
      flight4: travelInfo?.flight4,
      inf_num: infant,
      gds: radioValue,
    };
    try {
      const response = await utripApi.post('/apis/func_wire/v2/indschedules', body);

      if (response.status === 200) {
        if (response.data.schedules.includes('No schedules')) {
          setOpenDialogPopup(dispatch, {
            modalType: 'failCustom',
            showYn: true,
            content: `스케줄 정보를 확인하세요.`,
          });
          setOpenLoading(dispatch, { showYn: false });
          return
        }
        setSelectedSegInfo(response.data.schedules[0]);
        reserveTravelInfo(response.data.schedules[0]);
      } else {
        setOpenDialogPopup(dispatch, {
          modalType: 'failCustom',
          showYn: true,
          content: `${response.data.message}`,
        });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `${response.data.message}`,
      });
      setOpenLoading(dispatch, { showYn: false });
      console.error(error.response);
    }
  };
  const ageChange = (event) => {
    setAge(event.target.value);
  };

  const genderChange = (event) => {
    setGender(event.target.value);
  };

  const popOpen = () => {
    setOpen(true);
  };

  const popClose = () => {
    setOpen(false);
  };

  const createDefaultMember = (index) => ({
    resListAdtcheck: ageTypeValues[index], // Set `구분` based on the index
    resListGender: '',
    resListName: '',
    resListFname: '',
    resListBirth: '',
    resListNation: 'KR',
    resListAPM: '',
    resListNationNum: '',
    resListMobNum: '',
    resListPaNnum: String(index + 1),
  });
  const indTravelMember = JSON.parse(sessionStorage.getItem('indTravelMember'));
  sessionStorage.removeItem('indTravelMember')
  let totalNum = 0;

  if (indTravelMember) {
    totalNum = Object.values(indTravelMember).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }
  const [resJson, setResJson] = useState(
    Array.from({ length: totalNum }, (_, index) => createDefaultMember(index))
  );
  const showDetail = () => {
    //탑승인원
    const member = {
      adult: adult,
      child: child,
      infant: infant,
    };


    // Add `성인` (adult) values
    for (let i = 0; i < member.adult; i++) {
      ageTypeValues.push(ageTypes[0]); // '성인'
    }

    // Add `소아` (child) values
    for (let i = 0; i < member.child; i++) {
      ageTypeValues.push(ageTypes[1]); // '소아'
    }

    // Add `유아` (infant) values
    for (let i = 0; i < member.infant; i++) {
      ageTypeValues.push(ageTypes[2]); // '유아'
    }

    const totalNum = Object.values(member)?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setResJson(Array.from({ length: totalNum }, (_, index) => createDefaultMember(index)));
  };

  const clearInfo = () => {
    sessionStorage.removeItem('indTravelMember');
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.startsWith("indres_")) {
        sessionStorage.removeItem(key);
        i--;
      }
    }
    location.reload();
  };
  const [travelInfo, setTravelInfo] = useState({
    adult_num: 0,
    apikey: 'R26RWLMTV8IVTF3F',
    arr_air_port1: sessionStorage.getItem(`indres_arr_air_port1`) || '',
    arr_air_port2: sessionStorage.getItem(`indres_arr_air_port2`) || '',
    arr_air_port3: sessionStorage.getItem(`indres_arr_air_port3`) || '',
    arr_air_port4: sessionStorage.getItem(`indres_arr_air_port4`) || '',
    chd_num: 0,
    cls1: sessionStorage.getItem(`indres_cls1`) || '',
    cls2: sessionStorage.getItem(`indres_cls2`) || '',
    cls3: sessionStorage.getItem(`indres_cls3`) || '',
    cls4: sessionStorage.getItem(`indres_cls4`) || '',
    date1: sessionStorage.getItem(`indres_date1`) || '',
    date2: sessionStorage.getItem(`indres_date2`) || '',
    date3: sessionStorage.getItem(`indres_date3`) || '',
    date4: sessionStorage.getItem(`indres_date4`) || '',
    dep_air_port1: sessionStorage.getItem(`indres_dep_air_port1`) || '',
    dep_air_port2: sessionStorage.getItem(`indres_dep_air_port2`) || '',
    dep_air_port3: sessionStorage.getItem(`indres_dep_air_port3`) || '',
    dep_air_port4: sessionStorage.getItem(`indres_dep_air_port4`) || '',
    flight1: sessionStorage.getItem(`indres_flight1`) || '',
    flight2: sessionStorage.getItem(`indres_flight2`) || '',
    flight3: sessionStorage.getItem(`indres_flight3`) || '',
    flight4: sessionStorage.getItem(`indres_flight4`) || '',
    gds: radioValue,
    inf_num: 0,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  const handleReserveInfo = (event) => {
    const { name, value } = event.target;
    setReserveInfo({
      ...reserveInfo,
      [name]: value,
    });
  };

  const handleTravelInfo = (event) => {
    const { name, value } = event.target;
    setTravelInfo({
      ...travelInfo,
      [name]: value,
    });
    sessionStorage.setItem(`indres_${name}`, value)
  };

  const handleInfo = (index, event) => {
    const { name, value } = event.target;
    const updatedResJson = [...resJson];
    updatedResJson[index][name] = value;
    setResJson(updatedResJson);
  };

  const includePassenger = () => {
    const updatedResJson = [...resJson];
    let [lastName, firstName] = ['', ''];
    if (reserveInfo?.resName) {
      [lastName, firstName] = reserveInfo.resName?.split('/');
    }
    updatedResJson[0]['resListName'] = firstName;
    updatedResJson[0]['resListFname'] = lastName;
    setResJson(updatedResJson);
  };

  const handleAddRow = (index) => {
    const updatedResJson = [...resJson];
    updatedResJson.splice(index + 1, 0, {
      resListAPM: '',
      resListNationNum: '',
      resListMobNum: '',
      resListPaNnum: '',
    });
    setResJson(updatedResJson);
  };

  const handleRemoveRow = (index) => {
    const updatedResJson = [...resJson];
    updatedResJson.splice(index, 1);
    setResJson(updatedResJson);
  };

  const goToResult = () => {
    const member = {
      adult: adult,
      child: child,
      infant: infant,
    };
    sessionStorage.setItem('indTravelMember', JSON.stringify(member));
    sessionStorage.setItem('indPassMember', JSON.stringify(resJson));
    getIndSchedules();
    // reserveTravelInfo();
  };

  const reserveTravelInfo = async (selectedSegInfo) => {
    if (resJson.some((member) => !member.resListFname || !member.resListName || !member.resListGender || !member.resListAdtcheck)) {
      setOpenLoading(dispatch, { showYn: false });
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `탑승자 정보를 확인하세요.`,
      });
      return;
    }

    const adultPaNnumList = resJson
      .filter((member) => member.resListAdtcheck === '성인')
      .map((member) => member.resListPaNnum);

    const updatedResJson = resJson.map((member) => {
      if (member.resListAdtcheck === '유아') {
        return {
          ...member,
          resListParentnum: adultPaNnumList[0],
        };
      }
      return member;
    });

    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      resData: {
        gdsName: radioValue,
        resMail: reserveInfo?.resMail,
        resName: reserveInfo?.resName,
        resNum: reserveInfo?.resNum,
        resPhone: reserveInfo?.resPhone,
        segList: [selectedSegInfo],
      },
      resJson: updatedResJson,
    };
    console.log(body, '바디');

    try {
      const response = await utripApi.post(
        '/apis/func_wire/v2/get_lowestprice_air/reservation',
        body
      );

      if (
        response.status === 200 &&
        response.data.pnr !== 'fail' &&
        response.data.pnr !== 'timeout' &&
        response.data.message !== 'Proccess Error'
      ) {
        sessionStorage.setItem('indPriceResult', JSON.stringify(response.data.pnr));
        navigate('/indrespage/result');
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }

    setOpenLoading(dispatch, { showYn: false });
  };
  return (
    <Grid>
      <Grid container spacing={5}>
        <Grid item xs={5}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                스케쥴 정보
              </Typography>
              <Grid
                container
                px={4}
                py={3}
                flexWrap="nowrap"
                sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여정1
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="편명"
                    variant="outlined"
                    placeholder="KE1234"
                    size="small"
                    {...register('flight1')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.flight1 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CLASS"
                    variant="outlined"
                    placeholder="Y"
                    size="small"
                    {...register('cls1')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.cls1 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="날짜"
                    variant="outlined"
                    placeholder="20JAN"
                    size="small"
                    {...register('date1')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.date1 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="출발지"
                    variant="outlined"
                    placeholder="ICN"
                    size="small"
                    {...register('dep_air_port1')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.dep_air_port1 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="목적지"
                    variant="outlined"
                    placeholder="DAD"
                    size="small"
                    {...register('arr_air_port1')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.arr_air_port1 || ''}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                px={4}
                py={3}
                flexWrap="nowrap"
                sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여정2
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="편명"
                    variant="outlined"
                    placeholder="KE1234"
                    size="small"
                    {...register('flight2')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.flight2 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CLASS"
                    variant="outlined"
                    placeholder="Y"
                    size="small"
                    {...register('cls2')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.cls2 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="날짜"
                    variant="outlined"
                    placeholder="20JAN"
                    size="small"
                    {...register('date2')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.date2 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="출발지"
                    variant="outlined"
                    placeholder="ICN"
                    size="small"
                    {...register('dep_air_port2')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.dep_air_port2 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="목적지"
                    variant="outlined"
                    placeholder="DAD"
                    size="small"
                    {...register('arr_air_port2')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.arr_air_port2 || ''}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                px={4}
                py={3}
                flexWrap="nowrap"
                sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여정3
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="편명"
                    variant="outlined"
                    placeholder="KE1234"
                    size="small"
                    {...register('flight3')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.flight3 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CLASS"
                    variant="outlined"
                    placeholder="Y"
                    size="small"
                    {...register('cls3')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.cls3 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="날짜"
                    variant="outlined"
                    placeholder="20JAN"
                    size="small"
                    {...register('date3')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.date3 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="출발지"
                    variant="outlined"
                    placeholder="ICN"
                    size="small"
                    {...register('dep_air_port3')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.dep_air_port3 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="목적지"
                    variant="outlined"
                    placeholder="DAD"
                    size="small"
                    {...register('arr_air_port3')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.arr_air_port3 || ''}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                px={4}
                py={3}
                flexWrap="nowrap"
                sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여정4
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="편명"
                    variant="outlined"
                    placeholder="KE1234"
                    size="small"
                    {...register('flight4')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.flight4 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CLASS"
                    variant="outlined"
                    placeholder="Y"
                    size="small"
                    {...register('cls4')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.cls4 || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="날짜"
                    variant="outlined"
                    placeholder="20JAN"
                    size="small"
                    {...register('date4')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.date4 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="출발지"
                    variant="outlined"
                    placeholder="ICN"
                    size="small"
                    {...register('dep_air_port4')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.dep_air_port4 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <TextField
                    label="목적지"
                    variant="outlined"
                    placeholder="DAD"
                    size="small"
                    {...register('arr_air_port4')}
                    onChange={handleTravelInfo}
                    value={travelInfo?.arr_air_port4 || ''}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mt={5}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                예약인원 선택
              </Typography>
              <Grid
                container
                px={4}
                py={3}
                flexWrap="nowrap"
                sx={{ gap: 2, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여행인원
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">어른</InputLabel>
                    <Select
                      labelId="label1"
                      id="select1"
                      value={adult}
                      name="adult"
                      label="Adult"
                      {...register('adult')}
                      onChange={selectChange}
                    >
                      {count.map((num) => (
                        <MenuItem key={num} value={num}>
                          ADT {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="label2">아동</InputLabel>
                    <Select
                      labelId="label2"
                      id="select2"
                      value={child}
                      name="child"
                      label="Child"
                      {...register('child')}
                      onChange={selectChange}
                    >
                      {count.map((num) => (
                        <MenuItem key={num} value={num}>
                          CHD {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="label3">유아</InputLabel>
                    <Select
                      labelId="label3"
                      id="select3"
                      value={infant}
                      name="infant"
                      label="Infant"
                      {...register('infant')}
                      onChange={selectChange}
                    >
                      {count.map((num) => (
                        <MenuItem key={num} value={num}>
                          INF {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              onClick={() => showDetail()}
            >
              선택
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                예약자 정보
              </Typography>

              <TableContainer>
                <Table className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">예약자명 (영문)</TableCell>
                      <TableCell align="center">연락처 (MOBILE)</TableCell>
                      <TableCell align="center">메일주소 (선택)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        <TextField
                          label="예약자명"
                          variant="outlined"
                          placeholder="HONG/KILDING"
                          size="small"
                          {...register('resName')}
                          onChange={handleReserveInfo}
                          value={reserveInfo?.resName || ''}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          label="연락처"
                          variant="outlined"
                          placeholder="010-1234-5678"
                          size="small"
                          {...register('resPhone')}
                          onChange={handleReserveInfo}
                          value={reserveInfo?.resPhone || ''}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          label="메일주소"
                          variant="outlined"
                          placeholder="tripus@tripus.co.kr"
                          size="small"
                          {...register('resMail')}
                          onChange={handleReserveInfo}
                          value={reserveInfo?.resMail || ''}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box mt={8}>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>
                탑승자 정보
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0 20px' }}>
                <Alert severity="warning" sx={{ flex: '1 0 1%', py: 0, mb: 2 }}>
                  소아/유아 포함인 경우 생년월일 필수 입력
                </Alert>
                <FormControlLabel
                  control={<Checkbox onClick={() => includePassenger()} />}
                  label="탑승자에 여행자 포함"
                  sx={{ '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                />
              </Box>

              <TableContainer>
                <Table className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">구분</TableCell>
                      <TableCell align="center">성별</TableCell>
                      <TableCell align="center">성</TableCell>
                      <TableCell align="center">이름</TableCell>
                      <TableCell align="center">생년월일</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resJson?.map((member, currentRowIndex) => (
                      <TableRow key={currentRowIndex}>
                        <TableCell>{currentRowIndex + 1}</TableCell>
                        <TableCell>
                          <FormControl fullWidth size="small" sx={{ width: '100px' }}>
                            <InputLabel id="demo-simple-select-label">구분</InputLabel>
                            <Select
                              labelId="label1"
                              id={`select1-${currentRowIndex}`}
                              {...register(`resListAdtcheck-${currentRowIndex}`)}
                              value={member?.resListAdtcheck}
                              onChange={(event) => handleInfo(currentRowIndex, event)}
                              name="resListAdtcheck"
                              label="구분"
                            >
                              {ageTypes.map((ageType) => (
                                <MenuItem key={ageType} value={ageType}>
                                  {ageType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth size="small" sx={{ width: '80px' }}>
                            <InputLabel id="demo-simple-select-label">성별</InputLabel>
                            <Select
                              labelId="label2"
                              id={`select2-${currentRowIndex}`}
                              {...register(`resListGender-${currentRowIndex}`)}
                              value={member?.resListGender}
                              onChange={(event) => handleInfo(currentRowIndex, event)}
                              name="resListGender"
                              label="성별"
                            >
                              {genderTypes.map((genderType) => (
                                <MenuItem key={genderType} value={genderType}>
                                  {genderType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="성"
                            variant="outlined"
                            placeholder="HONG"
                            size="small"
                            {...register(`resListFname-${currentRowIndex}`)}
                            value={member?.resListFname}
                            onChange={(event) => handleInfo(currentRowIndex, event)}
                            name="resListFname"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="이름"
                            variant="outlined"
                            placeholder="KILDING"
                            size="small"
                            {...register(`resListName-${currentRowIndex}`)}
                            value={member?.resListName}
                            onChange={(event) => handleInfo(currentRowIndex, event)}
                            name="resListName"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="생년월일"
                            variant="outlined"
                            placeholder="19870101"
                            size="small"
                            {...register(`resListBirth-${currentRowIndex}`)}
                            value={member?.resListBirth}
                            onChange={(event) => handleInfo(currentRowIndex, event)}
                            name="resListBirth"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '0 20px',
                }}
              >
                <RadioGroup row name="name" value={radioValue} onChange={radioChange}>
                  <FormControlLabel value="topas" control={<Radio />} label="토파스" />
                  <FormControlLabel value="sabre" control={<Radio />} label="세이버" />
                </RadioGroup>

                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => popOpen(true)}
                >
                  연락처 입력
                </Button>
              </Box>
            </Box>
          </Paper>

          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button
              variant="contained"
              size="large"
              sx={{ px: 8, color: 'white' }}
              onClick={() => {
                goToResult();
              }}
            >
              예약하기
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 8 }}
              onClick={() => clearInfo()}
            >
              초기화
            </Button>

          </Stack>
        </Grid>
      </Grid>

      <Modal open={open} onClose={popClose} slots={{ backdrop: StyledBackdrop }}>
        <ModalContent sx={{ width: 800 }}>
          <Typography variant="h6" fontWeight={700} gutterBottom>
            승객 연락처 입력
          </Typography>

          <Table className="custom-table">
            <TableHead>
              <TableRow>
                <TableCell align="center">휴대폰 번호(APM)</TableCell>
                <TableCell align="center">국가번호</TableCell>
                <TableCell align="center">휴대폰 번호(CTCM)</TableCell>
                <TableCell align="center">승객지정</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resJson?.map((member, currentRowIndex) => (
                <TableRow key={currentRowIndex}>
                  {currentRowIndex === 0 ? (
                    <TableCell>
                      <TextField
                        label="APM"
                        variant="outlined"
                        placeholder="01012345678"
                        size="small"
                        value={member?.resListAPM || ''}
                        onChange={(event) => handleInfo(currentRowIndex, event)}
                        name="resListAPM"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      {/* <TextField
                        label="휴대폰번호"
                        variant="outlined"
                        placeholder="01012345678"
                        size="small"
                        value={member?.resListAPM || ''}
                        name="resListAPM"
                        disabled
                      /> */}
                    </TableCell>
                  )}
                  <TableCell>
                    <TextField
                      label="국가번호"
                      variant="outlined"
                      placeholder="82"
                      size="small"
                      value={member?.resListNationNum}
                      onChange={(event) => handleInfo(currentRowIndex, event)}
                      name="resListNationNum"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="CTCM"
                      variant="outlined"
                      placeholder="01012345678"
                      size="small"
                      value={member?.resListMobNum}
                      onChange={(event) => handleInfo(currentRowIndex, event)}
                      name="resListMobNum"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="승객지정"
                      variant="outlined"
                      placeholder="1"
                      size="small"
                      value={member?.resListPaNnum}
                      onChange={(event) => handleInfo(currentRowIndex, event)}
                      name="resListPaNnum"
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{ p: 0, minWidth: 24 }}
                        onClick={() => handleAddRow(currentRowIndex)}
                      >
                        +
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ p: 0, minWidth: 24 }}
                        onClick={() => handleRemoveRow(currentRowIndex)}
                      >
                        -
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  <Button variant="contained" sx={{ fontWeight: 700, color: 'white' }} onClick={popClose}>
                    확인
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </Table>
        </ModalContent>
      </Modal>
    </Grid>
  );
}

export default ListPage;

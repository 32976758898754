import { useMemo, useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const UtripContextApi = createContext();
UtripContextApi.displayName = 'UtripContext';

let globalDispatch;

function reducer(state, action) {
  switch (action.type) {
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'OPEN_DIALOGPOPUP': {
      return { ...state, openDialogpopup: action.value };
    }
    case 'OPEN_LOADING': {
      return { ...state, openLoading: action.value };
    }
  }
}

function UtripControllerProvider({ children }) {
  const initialState = {
    openConfigurator: false,
    openDialogpopup: {
      modalType: 'success',
      showYn: false,
      title: '',
      content: '',
      reload: false,
      redirect: '',
    },
    //modalType : success(작업성공), failure(작업실패), login(로그인필요), admin(관리자 페이지), sucCustom(성공커스텀), failCustom(실패커스텀)
    openLoading: {
      showYn: false, // 로딩
    },
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  // 글로벌로 dispatch 저장 (전역 접근 가능하도록)
  globalDispatch = dispatch;

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <UtripContextApi.Provider value={value}>{children}</UtripContextApi.Provider>;
}

function useUtripContextApi() {
  const context = useContext(UtripContextApi);
  if (!context) {
    throw new Error('UtripContextApi should be used inside the UtripControllerProvider.');
  }
  return context;
}
export const getGlobalDispatch = () => {
  return globalDispatch;
};

UtripControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setOpenConfigurator = (dispatch, value) => dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setOpenDialogPopup = (dispatch, value) => dispatch({ type: 'OPEN_DIALOGPOPUP', value });
const setOpenLoading = (dispatch, value) => dispatch({ type: 'OPEN_LOADING', value });

export {
  UtripControllerProvider,
  useUtripContextApi,
  setOpenConfigurator,
  setOpenDialogPopup,
  setOpenLoading,
};

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import { useState } from 'react';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import ListPage from './list';
import Result from './result';
// ====================== Service Components ======================

// ====================== Images ==================================

function IndvReservation() {
  const [pageTitle, setPageTitle] = useState('INDV PNR');

  return <DefaultLayout pageTitle={pageTitle}>{<ListPage />}</DefaultLayout>;
}

export default IndvReservation;

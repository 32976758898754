// ====================== COMMON ======================
import React, { useState } from 'react';

// ====================== Layouts ======================
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Alert,
  Box, Button, Checkbox,
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem, Pagination,
  Paper, Radio, RadioGroup,
  Select,
  Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize  } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import TuneIcon from '@mui/icons-material/Tune';

// ====================== Components ======================

// rows 테스트 데이터
const rows = [];
for (let i = 0; i < 100; i++) {
  rows.push({
    id: (i + 1),
    name: '(주) 삼화여행사',
    code: 'SH0002_T',
    manager: 'HO/SIKJOONG',
    tel: '070-9898-0101',
    memo: '인센티브 통합 요청',
  });
}

function Client() {
  const [pageTitle, setPageTitle] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [dateValue, setDateValue] = useState(dayjs(new Date()));
  
  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
  
  const paginatedRows = filteredRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  
  const changePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  
  const changeSearch = (event) => {
    setSearchText(event.target.value);
    setPage(1);
  };
  
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 6px 6px 0 6px;
    color: ${theme.palette.grey[900]};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
    resize: none;

    &:hover {
      border-color: ${theme.palette.black};
    }

    &:focus {
      outline: 0;
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  
  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" fontWeight={700} gutterBottom>거래처정보</Typography>
              
              <Grid container spacing={3} mt={1}>
                <Grid item xs={6}>
                  <TextField label="회사명" variant="outlined" size="small" placeholder="유트립" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="회사코드(영문기호)" variant="outlined" size="small" placeholder="U100" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="회사타입" variant="outlined" size="small" placeholder="상용" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="사업자등록번호" variant="outlined" size="small" placeholder="유트립" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="대표전화" variant="outlined" size="small" placeholder="+8200-1111-2222" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="FAX" variant="outlined" size="small" placeholder="" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="회사담당자" variant="outlined" size="small" placeholder="JAMES DEAN" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="담당자 이메일" variant="outlined" size="small" placeholder="" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="담당자 전화번호" variant="outlined" size="small" placeholder="" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="미지급금" variant="outlined" size="small" placeholder="" sx={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="미수금" variant="outlined" size="small" placeholder="" sx={{ width: '100%' }} />
                </Grid>
              </Grid>
              
              <Textarea minRows={8} placeholder="" sx={{ width: '100%', mt: 3 }} />
            </Box>
          </Paper>
          
          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button variant="contained" size="large" color="secondary" sx={{ px: 8 }}>초기화</Button>
            <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }}>수정/저장</Button>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box display="flex" justifyContent="space-between" mb={3}>
              <FormControl variant="outlined" size="small" sx={{ textAlign: 'center', flex: '0 0 120px' }}>
                <InputLabel>페이지당 노출 개수</InputLabel>
                <Select
                  value={rowsPerPage}
                  onChange={changeRowsPerPage}
                  label="페이지당 노출 개수"
                >
                  {[10, 25, 50, 100].map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <Box display="flex" gap={2}>
                <TextField
                  label="검색어 입력"
                  variant="outlined"
                  value={searchText}
                  onChange={changeSearch}
                  size="small"
                />
              </Box>
            </Box>
            
            <TableContainer sx={{ maxHeight: 450 }}>
              <Table
                className="custom-table"
                size="small"
                stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox defaultChecked />
                    </TableCell>
                    <TableCell align="center">거래처</TableCell>
                    <TableCell align="center">거래처코드</TableCell>
                    <TableCell align="center">담당자</TableCell>
                    <TableCell align="center">전화번호</TableCell>
                    <TableCell align="center">메모</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell scope="row" align="center">
                        <Checkbox />
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">{row.manager}</TableCell>
                      <TableCell align="center">{row.tel}</TableCell>
                      <TableCell align="center">{row.memo}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
              <Pagination
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page}
                onChange={changePage}
                shape="rounded"
                color='primary'
              />
            </Box>
          </Paper>
          
          <Stack direction="row" spacing={4} mt={8} justifyContent="center">
            <Button variant="contained" size="large" color="error" sx={{ px: 8 }}>삭제</Button>
          </Stack>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}

export default Client;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState } from 'react';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import LowestPriceListPage from './list';

function LowestPrice() {
  const [pageTitle, setPageTitle] = useState('INDV 최저가 및 PNR생성');

  return (
    <DefaultLayout pageTitle={pageTitle}>
      <LowestPriceListPage />
    </DefaultLayout>
  );
}

export default LowestPrice;

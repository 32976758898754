/*
  SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
  SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import utripApi from '../utils/api/utripApi';
import { Link } from 'react-router-dom';
import { setOpenDialogPopup, useUtripContextApi } from '../context';
import { useLocation, useNavigate } from 'react-router-dom';
// ====================== MUI Components  =========================
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiListSubheader from '@mui/material/ListSubheader';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import SettingsIcon from '@mui/icons-material/Settings';

// ====================== Images ==================================
import {
  IconCalendar,
  IconAddress,
  IconFace,
  IconLaptop,
  IconRobot,
  IconUserPen,
  IconSearch,
  IconCalendarUser,
  IconUsers,
  IconHouseCheck,
  IconDriveUpload,
} from './icons';

const ListGroup = styled(Box)(({ theme }) => ({
  '& + &': {
    marginTop: 8,
  },
}));

const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
  padding: 16,
  fontWeight: 500,
  lineHeight: 1,
  color: theme.palette.primary.lighter,
  backgroundColor: theme.palette.primary.main,
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: '12px 16px',
  cursor: 'pointer',
  userSelect: 'none',
  '& .MuiListItemText-root': {
    margin: 0,
  },
}));

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  color: '#fff',
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  color: '#6f6b7d',
  fontSize: 14,
  fontWeight: 600,
  '& > .MuiListItemText-primary': {
    fontSize: '1em',
  },
}));

function Nav() {
  const location = useLocation();

  const [controller, dispatch] = useUtripContextApi();
  const navigate = useNavigate(); // Initialize the navigate function

  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [customerRole, setCustomerRole] = useState(null);

  useEffect(() => {
    const setRole = async () => {
      const response = await utripApi.get(`/apis/func_wire/v2/user-role`);
      if (response.status === 200) {
        setCustomerRole(response.data.role)
      }
    }
    const getAllMenus = async () => {
      try {
        const response = await utripApi.get('/apis/func_wire/v2/menu');
        if (response.data.message === 'success') {
          setMenus(response.data.data);
        }
      } catch (error) {
        console.error('Error getAllMenus : ', error);
      }
    };
    setRole()
    getAllMenus();
  }, []);

  const checkRole = async (url, role, menuName) => {
    if (role.includes('user-role')) {
      getRoles(url, menuName);
    } else {
      if (
        menuName.includes('AI U-BOT ADMIN') ||
        menuName.includes('APIS업로드') ||
        menuName.includes('LCC')
      ) {
        return;
      }
      // TODO UserLog추가
      try {
        const body = { gds: 'page', user_click_m: menuName };
        const response = await utripApi.post(`/apis/func_wire/v2/add_user_log`, body);
      } catch (error) {
        console.error('API 요청 중 오류 발생:', error);
      }
      navigate('/' + url);
    }
  };

  const getRoles = async (url, menuName) => {
    try {
      const response = await utripApi.get(`/apis/func_wire/v2/user-role`);
      if (response.status === 200) {
        const role = response.data.role;
        if (!role || role === 'none' || role === 'gds_none') {
          setOpenDialogPopup(dispatch, {
            modalType: 'failCustom',
            showYn: true,
            content: 'TOPAS SellConnect에 대한 GDS 정보 설정을 완료하십시오',
          });
        } else {
          if (
            menuName.includes('AI U-BOT ADMIN') ||
            // menuName.includes('APIS업로드') ||
            menuName.includes('LCC')
          ) {
            return;
          }
          // TODO UserLog추가
          try {
            const body = { gds: 'page', user_click_m: menuName };
            const response = await utripApi.post(`/apis/func_wire/v2/add_user_log`, body);
          } catch (error) {
            console.error('API 요청 중 오류 발생:', error);
          }
          // [x] GDS직접 조회 화면은 새로운 탭으로 생성
          if (menuName.includes('GDS화면 직접조회')) {
            window.open(url, "_blank", "noopener,noreferrer");
          } else navigate('/' + url);
        }
      }
    } catch (error) {
      console.error('Error getRoles : ', error);
    }
  };

  return (
    <React.Fragment>
      <List component="nav" sx={{ p: 0 }}>
        <ListGroup>
          {/* <ListItem component={Link} to="/pub/main" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconCalendar sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="예약캘린더" />
          </ListItem>
          <ListItem component={Link} to="/pub/pnr" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconAddress sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="PNR 등록관리" />
          </ListItem>
          <ListItem component={Link} to="/pub/passport" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconFace sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="여권정보추출" />
          </ListItem>
          <ListItem component={Link} to="/pub/gds" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconLaptop sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="GDS 직접조회" />
          </ListItem>
          <ListItem component={Link} to="/pub/ai-bot" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconRobot sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="AI U-BOT" />
          </ListItem>
          <ListItem component={Link} to="/pub/list-upload" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconDriveUpload sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="단체명단업로드" />
          </ListItem>
          <ListItem component={Link} to="/pub/ssr-international" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconUserPen sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="SSR 자동입력(국제)" />
          </ListItem>
          <ListItem component={Link} to="/pub/ssr-domestic" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconUserPen sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="SSR 자동입력(국내)" />
          </ListItem>
          <ListItem component={Link} to="/pub/lowest-price" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconSearch sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="최저가 조회 및 예약" />
          </ListItem>
          <ListItem component={Link} to="/pub/indv-reservation" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconCalendarUser sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="개인예약" />
          </ListItem>
          <ListItem component={Link} to="/pub/grp-reservation" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconUsers sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="단체예약" />
          </ListItem>
          <ListItem component={Link} to="/custom-company-profiles" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
              <IconHouseCheck sx={{ width: 24, height: 24, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="거래처등록관리" />
          </ListItem> */}
          {menus
            ? menus
              .sort((a, b) => a.menu_index - b.menu_index) // Step 1: Sort the menus array based on the order property
              .map(
                (
                  menu,
                  index // Step 2: Map through the sorted array
                ) => (
                  <ListItem
                    key={index}
                    onClick={() => checkRole(menu.menu_url, menu.menu_icon, menu.menu_name)}
                    sx={{
                      px: 0, "&:hover": {
                        backgroundColor: "rgba(255, 153, 51, 0.1)",
                        cursor: "pointer",
                      },
                      // 유저 롤을 확인하고 메뉴 흐리게 만드는 옵션
                      opacity:
                        (customerRole === "none" || customerRole === "gds_none") &&
                          menu.menu_icon?.includes("user-role")
                          ? 0.5
                          : 1
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24, marginRight: 2 }}>
                      {menu?.menu_name.includes('PNR 관리 캘린더') ? (
                        <IconCalendar sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('PNR 등록/리스트 관리') ? (
                        <IconAddress sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('여권') ? (
                        <IconFace sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('GDS') ? (
                        <IconLaptop sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('BOT') ? (
                        <IconRobot sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('국제') ? (
                        <IconUserPen sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('국내') ? (
                        <IconUserPen sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('개인 최저가조회/PNR생성') ? (
                        <IconSearch sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('개인 PNR 생성') ? (
                        <IconCalendarUser sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('업로드') ? (
                        <IconDriveUpload sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('단체예약') ? (
                        <IconUsers sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : menu?.menu_name.includes('거래처') ? (
                        <IconHouseCheck sx={{ width: 24, height: 24, color: 'white' }} />
                      ) : (
                        <IconFace sx={{ width: 24, height: 24, color: 'white' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={menu?.menu_name} primaryTypographyProps={{
                      sx: { fontWeight: 'bold' } // 볼드 처리
                    }} />
                  </ListItem>
                )
              )
            : ''}
        </ListGroup>
      </List>
    </React.Fragment>
  );
}

export default Nav;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Typography,
  Grid,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Alert,
  Button,
  TableFooter,
  Stack,
  Link,
} from '@mui/material';
// ====================== Service Components ======================

// ====================== Images ==================================

function IndvReservationResult() {
  const indPriceResult = JSON.parse(sessionStorage.getItem('indPriceResult'));
  const indTravelMember = JSON.parse(sessionStorage.getItem('indTravelMember'));
  const indPassMember = JSON.parse(sessionStorage.getItem('indPassMember'));

  const convertNewlinesToBreaks = (text) => {
    return text?.split('\n')?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState('INDV PNR');
  const goToList = () => {
    navigate('/indrespage');
  };

  return (
    <DefaultLayout pageTitle={pageTitle}>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Box>
              <Typography variant="subtitle1" mb={2} fontWeight={700}>
                탑승자 정보
              </Typography>

              {/* <Grid
                container
                px={4}
                py={3}
                mb={4}
                flexWrap="nowrap"
                sx={{ gap: 4, border: 1, borderRadius: 2, borderColor: 'grey.300' }}
              >
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                  여행인원
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', fontWeight: 700, gap: '0 4px' }}
                >
                  <TextField
                    label="성인"
                    variant="outlined"
                    placeholder="1"
                    size="small"
                    value={indTravelMember?.adult}
                    InputProps={{ readOnly: true }}
                  />{' '}
                  명
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', fontWeight: 700, gap: '0 4px' }}
                >
                  <TextField
                    label="소아"
                    variant="outlined"
                    placeholder="1"
                    size="small"
                    value={indTravelMember?.child}
                    InputProps={{ readOnly: true }}
                  />{' '}
                  명
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', alignItems: 'center', fontWeight: 700, gap: '0 4px' }}
                >
                  <TextField
                    label="유아"
                    variant="outlined"
                    placeholder="1"
                    size="small"
                    value={indTravelMember?.infant}
                    InputProps={{ readOnly: true }}
                  />{' '}
                  명
                </Grid>
              </Grid> */}

              <TableContainer>
                <Table className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">구분</TableCell>
                      <TableCell align="center">성별</TableCell>
                      <TableCell align="center">성</TableCell>
                      <TableCell align="center">이름</TableCell>
                      <TableCell align="center">생년월일</TableCell>
                      <TableCell align="center">국적</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {indPassMember?.map((member, currentRowIndex) => (
                      <TableRow key={currentRowIndex}>
                        <TableCell align="center">{currentRowIndex + 1}</TableCell>
                        <TableCell align="center">
                          <TextField
                            label="구분"
                            variant="outlined"
                            size="small"
                            value={member?.resListAdtcheck}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="성별"
                            variant="outlined"
                            size="small"
                            value={member?.resListGender}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="성"
                            variant="outlined"
                            size="small"
                            value={member?.resListFname}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="이름"
                            variant="outlined"
                            size="small"
                            value={member?.resListName}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="생년월일"
                            variant="outlined"
                            size="small"
                            value={member?.resListBirth}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            label="국적"
                            variant="outlined"
                            size="small"
                            value={member?.resListNation}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className="custom-paper">
            <Alert severity="info">{convertNewlinesToBreaks(indPriceResult)}</Alert>
          </Paper>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          onClick={() => goToList()}
          sx={{ px: 8, bgcolor: 'secondary.main', color: 'white' }}
        >
          이전
        </Button>
        <Link href="/calendar">
          <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }}>
            Home
          </Button>
        </Link>
      </Stack>
    </DefaultLayout>
  );
}

export default IndvReservationResult;

/*
	SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

import { useEffect, useState } from 'react';

const PrivateRoute = ({ children }) => {
  // 프론트에서 막아주고, api로 두 번 막기.
  // api로 안 막을 경우 막말로 localStorate, sessionStorage 임의로 변경하면 접근 가능해진다.
  // TODO:: isLogin으로 체크해야한다.

  const [isLogin, setIsLogin] = useState(
    localStorage.getItem('UtripRPA') || sessionStorage.getItem('UtripRPA') ? true : false
  );

  useEffect(() => {
    if (!isLogin) {
      // 로그인 후 이용가능합니다로 변경하기
      window.history.back();
    }
  }, [isLogin]);

  return children;
};

export default PrivateRoute;

/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState, useEffect } from 'react';
import { setOpenLoading, setOpenDialogPopup, useUtripContextApi } from '../../context';
import utripApi from '../../utils/api/utripApi';
import { useNavigate, useParams } from 'react-router-dom';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// ====================== Service Components ======================

// ====================== Images ==================================

function LowestPriceDetail() {
  const { id } = useParams();
  const [controller, dispatch] = useUtripContextApi();

  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('a');
  const pricingInfo = JSON.parse(sessionStorage.getItem('selectedTravelInfo'));
  const [segList, setSegList] = useState([]);
  const [selectedSeg, setSelectedSeg] = useState('');

  const goToList = () => {
    navigate('/pricingpage');
  };

  const goToReservation = () => {
    if (!selectedSeg) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: '여정 선택후 실행하십시오',
      });
      return;
    }
    sessionStorage.setItem('selectedSegInfo', selectedSeg);
    navigate('/pricingpage/reservation');
  };

  const getPickSeg = async (id) => {
    setOpenLoading(dispatch, { showYn: true });
    const body = {
      apikey: 'R26RWLMTV8IVTF3F',
      entry_detail: id,
    };
    try {
      const response = await utripApi.post('/apis/func_wire/v2/get_lowestprice_air/pickseg', body);
      if (response.status === 200) {
        setSegList(response?.data.pick_seg_list);

        const pricingInfo = {
          items: [
            {
              no: 1,
              ptc: '성인',
              fee:
                response.data.pricing_info?.adt_cur?.replace(/[^0-9]/g, '') -
                response.data.pricing_info?.adt_tax?.replace(/[^0-9]/g, ''),
              tax: response.data.pricing_info?.adt_tax,
              price: response.data.pricing_info?.adt_cur,
              basis: response.data.pricing_info?.adt_fare_basis,
            },
            {
              no: 2,
              ptc: '소아',
              fee:
                response.data.pricing_info?.ch_cur?.replace(/[^0-9]/g, '') -
                response.data.pricing_info?.ch_tax?.replace(/[^0-9]/g, '') || '',
              tax: response.data.pricing_info?.ch_tax,
              price: response.data.pricing_info?.ch_cur,
              basis: response.data.pricing_info?.ch_fare_basis,
            },
            {
              no: 3,
              ptc: '유아',
              fee:
                response.data.pricing_info?.in_cur?.replace(/[^0-9]/g, '') -
                response.data.pricing_info?.in_tax?.replace(/[^0-9]/g, '') || '',
              tax: response.data.pricing_info?.in_tax,
              price: response.data.pricing_info?.in_cur,
              basis: response.data.pricing_info?.in_fare_basis,
            },
          ],
          total: {
            fee:
              response.data.pricing_info?.total_cur?.replace(/[^0-9]/g, '') -
              response.data.pricing_info?.total_tax?.replace(/[^0-9]/g, ''),
            tax: response.data.pricing_info?.total_tax,
            price: response.data.pricing_info?.total_cur,
          },
        };

        sessionStorage.setItem('selectedTravelInfo', JSON.stringify(pricingInfo));
      }
    } catch (error) {
      console.error('Error getPickSeg : ', error);
    }
    setOpenLoading(dispatch, { showYn: false });
  };

  useEffect(() => {
    getPickSeg(id);
  }, [id]);

  const parseUnicode = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html');
    return doc.body.textContent;
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <DefaultLayout pageTitle={'여정선택 및 운임보기'}>
      <>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Paper className="custom-paper table-paper">
              <RadioGroup
                aria-label="options"
                name="options"
                value={selectedValue}
                onChange={handleChange}
              >
                <TableContainer className="custom-scrollbar">
                  <Table className="custom-table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">여정</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {segList?.map((tour, index) => (
                        <TableRow key={index}>
                          <TableCell size="small">
                            <FormControlLabel
                              value={index}
                              control={<Radio size="small" />}
                              onChange={() => setSelectedSeg(tour)}
                              label={
                                <>
                                  {parseUnicode(tour)
                                    .split('\n')
                                    .map((line, idx) => (
                                      <React.Fragment key={idx}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                </>
                              }
                              sx={{ '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RadioGroup>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="custom-paper table-paper">
              <RadioGroup
                aria-label="options"
                name="options"
                value={selectedValue}
                onChange={handleChange}
              >
                <TableContainer className="custom-scrollbar">
                  <Table className="custom-table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">PTC</TableCell>
                        <TableCell align="center">운임</TableCell>
                        <TableCell align="center">TAX</TableCell>
                        <TableCell align="center">총 합계</TableCell>
                        <TableCell align="center">FARE BASIS</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pricingInfo?.items?.map((item) => (
                        <TableRow key={item?.no}>
                          <TableCell align="center">{item?.ptc}</TableCell>
                          <TableCell align="center">{item?.fee}</TableCell>
                          <TableCell align="center">{item?.tax}</TableCell>
                          <TableCell align="center">{item?.price}</TableCell>
                          <TableCell align="center">{item?.basis}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter sx={{ bgcolor: 'grey.800' }}>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={{ color: 'white', fontWeight: 700 }}
                        >
                          TOTAL
                        </TableCell>

                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.fee}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.tax}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}>
                          {pricingInfo?.total?.price}
                        </TableCell>
                        <TableCell align="center" sx={{ color: 'white' }}></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </RadioGroup>
            </Paper>

            <Box py={3} px={5}>
              <Typography variant="body" color="error.main">
                {/* 운임 TL : 30MAY24 */}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => goToReservation()}
            sx={{ px: 8, color: 'white' }}
          >
            예약하기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => goToList()}
            sx={{ px: 8, bgcolor: 'secondary.main', color: 'white' }}
          >
            이전
          </Button>

        </Stack>
      </>
    </DefaultLayout>
  );
}

export default LowestPriceDetail;

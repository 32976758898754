/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import utripApi from '../../utils/api/utripApi';
import DragDrop from '../../components/dragDrop'; // dragDrop
// ====================== MUI Components  =========================
import {
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Stack,
  Alert,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { setOpenDialogPopup, setOpenLoading, useUtripContextApi } from '../../context';
// ====================== Service Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
// ====================== Images ==================================

function FileTransformPage() {
  const [controller, dispatch] = useUtripContextApi();

  const [fileName, setFileName] = useState('');
  const [uploadFile, setUploadFile] = useState();
  const [radioValue, setRadioValue] = useState('7C');



  const convertNewlinesToBreaks = (text) => {
    return text
      ?.replace(/&nbsp;/g, ' ')
      ?.split('<br>')
      ?.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
  };
  //엑셀 다운로드
  const excelDownload = () => {
    const excelData = {};

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'PNR 등록 데이터');
    XLSX.writeFile(workBook, 'Sales_Data_Export.xlsx');
  };

  const inputFileChange = (event) => {
    const file = event[0] instanceof File ? event[0] : event.target.files[0];
    if (file) {
      setFileName(file.name);
      console.log(file)
      setUploadFile(file);
    } else {
      setFileName('');
    }
  };

  const excuteUpload = async () => {
    setOpenLoading(dispatch, { showYn: true });
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('radio', radioValue);
    try {

      const response = await utripApi.post('/apis/func_wire/v2/tranceform-lcc', formData,
        {
          responseType: 'blob',
        }
      );
      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // URL 생성
        const url = window.URL.createObjectURL(blob);

        // 임시 다운로드 링크 생성 및 클릭
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${radioValue.toUpperCase()}_excel.xlsx` // 다운로드 파일 이름 설정
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url); // 메모리 정리
      }
    } catch (error) {
      console.error("Error downloading the file", error);
    } finally {
      setOpenLoading(dispatch, { showYn: false });
    }
  }


  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const DownloadLink = styled(Link)(
    ({ theme }) => `
    color: ${theme.palette.secondary.main};
    text-decoration: none;
  `
  );

  const clearInfo = () => {
    location.reload();
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <DefaultLayout pageTitle='LCC 파일 변환'>
      <Grid item xs={6}>
        <Paper className="custom-paper">
          <Box>
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              엑셀 양식 다운로드
            </Typography>

            <Box display="flex" gap={3}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                startIcon={<FileDownloadIcon />}
              >
                <DownloadLink href="/assets/docs/LCC_변환_양식.xlsx" download>
                  엑셀 양식 다운로드
                </DownloadLink>
              </Button>
            </Box>
          </Box>

          <Box mt={8}>
            <Typography variant="subtitle1" fontWeight={700} gutterBottom>
              명단 업로드
            </Typography>

            <RadioGroup row name="type" value={radioValue} onChange={radioChange} sx={{ mb: 3 }}>
              <FormControlLabel value="7C" control={<Radio id="type1" />} label="제주항공" />
              <FormControlLabel value="LJ" control={<Radio id="type2" />} label="진에어" />
              <FormControlLabel value="TW" control={<Radio id="type3" />} label="티웨이" />
            </RadioGroup>

            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Box gap={4} display="flex">
                  <DragDrop onChangeFiles={inputFileChange} description={fileName.length ? fileName : "파일을 드래그앤드롭 하시면 업로드할 수 있습니다"} />
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    color="secondary"
                    onChange={inputFileChange}
                  >
                    파일 업로드
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>


      <Stack direction="row" spacing={4} mt={8} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ px: 8, color: 'white' }}
          onClick={() => excuteUpload()}
        >
          실행
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ px: 8 }}
          onClick={() => clearInfo()}
        >
          초기화
        </Button>

      </Stack>
    </DefaultLayout>
  );
}

export default FileTransformPage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Grid,
    Alert,
    Button,
    Stack,
    TextField,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const DragDrop = ({ onChangeFiles, description = "파일 첨부" }) => {
    const [dragOver, setDragOver] = useState(false);

    // 드래그 오버 이벤트
    const handleDragOver = (e) => {
        e.preventDefault();  // 기본 동작을 막아야 드롭 가능
        e.stopPropagation();
        setDragOver(true);
    };

    // 드래그가 영역을 벗어날 때
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
    };

    // 파일을 드롭할 때 호출
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);

        if (e.dataTransfer && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            onChangeFiles(files); // 여러 파일을 부모로 전달
        }
    };

    // 파일 선택 시 (input)
    const handleChange = (e) => {
        const files = e.target.files ? Array.from(e.target.files) : [];
        onChangeFiles(files);
        e.target.value = ""; // 파일 선택 후 input 초기화
    };

    return (
        <Grid className="flex flex-col justify-center items-center w-full">
            <div className="w-3/4">
                <Button
                    type="button"
                    className={`w-full h-48 border-4 ${dragOver ? 'border-blue-500 bg-blue-100' : 'border-gray-300'} rounded-lg flex flex-col items-center justify-center cursor-pointer`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {/* {description} */}
                    <p className="text-lg text-gray-700 mb-2">{description}</p>
                    {/* {<CloudUploadIcon />} */}
                    {/* <div className="w-6 h-6 pointer-events-none" >
                    </div> */}
                </Button>
                <input
                    id="fileUpload"
                    type="file"
                    multiple // 여러 파일 업로드 지원
                    className="hidden"
                    onChange={handleChange}
                    style={{
                        position: 'absolute',
                        width: '1px',
                        height: '1px',
                        padding: 0,
                        margin: '-1px',
                        overflow: 'hidden',
                        border: 0,
                        clip: 'rect(0, 0, 0, 0)',
                        whiteSpace: 'nowrap',
                        clipPath: 'inset(100%)',
                    }}
                />
            </div >
        </Grid >
    );
};

DragDrop.propTypes = {
    onChangeFiles: PropTypes.func.isRequired,
    description: PropTypes.string,
};

export default DragDrop;

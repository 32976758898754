// ====================== Common ======================
import React, { useState } from 'react';

// ====================== MUI ======================
import {
  Paper,
  Grid,
  Alert,
  Button,
  Stack,
  TextField,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadIcon from '@mui/icons-material/Upload';

// ====================== Components ======================
import DefaultLayout from '../../layouts/defaultLayout';
import CustomDialog from '../../components/dialog';

// 임시 데이터
const rows = []
for (let i = 0; i < 50; i++) {
  rows.push({
    id: i,
    lastName: 'HONG',
    firstName: 'GILDONG',
    gender: 'M',
    birth: '20040101',
    national: 'KOR',
    passportNo: 'M12345678',
    passportExp: '20241212',
    authority: 'KOR',
    type: 'PM',
    korName: '홍길동',
    fileName: '홍길동.jpg'
  });
}

function Passport() {
  const [fileName, setFileName] = useState('');
  const [transApisPop, setTransApisPop] = useState('');
  const [uploadApisPop, setUploadApisPop] = useState('');
  const [uploadListApisPop, setUploadListApisPop] = useState('');
  const [reservePopOpen, setReservePopOpen] = useState(false);
  
  const openTransApisPop = () => {
    setTransApisPop(true);
  }
  
  const closeTransApisPop = () => {
    setTransApisPop(false);
  }
  
  const openUploadListApisPop = () => {
    setUploadListApisPop(true);
  }
  
  const closeUploadListApisPop = () => {
    setUploadListApisPop(false);
  }
  
  const openUploadApisPop = () => {
    setUploadApisPop(true);
  }
  
  const closeUploadApisPop = () => {
    setUploadApisPop(false);
  }
  
  const openReservePop = () => {
    setReservePopOpen(true);
  }
  
  const closeReservePop = () => {
    setReservePopOpen(false);
  }
  
  const inputFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName('');
    }
  };
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  return (
    <DefaultLayout>
      <Grid container spacing={8} flexDirection="column">
        <Grid item sx={{ ".MuiPaper-root.custom-paper": { height: "auto", maxHeight: 'none' }}}>
          <Alert severity="info" sx={{ py: 0, mb: 3, boxShadow: '0 0 5px 2px rgba(0,0,0,0.2)' }}>본 메뉴에서는 여권 정보를 인식하고 APIS로 변환한 후, 변환된 정보를 명단 포함하여 PNR에 바로 연동할 수 있습니다.</Alert>
          
          <Paper className="custom-paper">
            <Box gap={2} display="flex">
              <TextField value={fileName} size="small" sx={{ flex: '1 1 1%' }} InputProps={{ readOnly: true }} placeholder="선택된 파일 없음" />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                color="secondary"
                onChange={inputFileChange}
              >
                파일 업로드
                <VisuallyHiddenInput type="file" />
              </Button>
            </Box>
            
            <Alert severity="warning" sx={{ py: 0, mt: 2 }}>여권이미지를 업로드해주세요. (50장까지 업로드가능)</Alert>
          </Paper>
          
          <Stack direction="row" spacing={4} mt={6} justifyContent="center">
            <Button variant="contained" size="large" color="secondary" sx={{ px: 8 }}>초기화</Button>
            <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }}>추출하기</Button>
          </Stack>
        </Grid>
        <Grid item sx={{ ".MuiPaper-root.custom-paper": { height: "42vh", maxHeight: '42vh' }}}>
          <TableContainer component={Paper} className="custom-paper table-paper">
            <Table className="custom-table custom-table-sm" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>성</TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell>성별</TableCell>
                  <TableCell>생년월일</TableCell>
                  <TableCell>국적</TableCell>
                  <TableCell>여권번호</TableCell>
                  <TableCell>여권만료일</TableCell>
                  <TableCell>발행국가</TableCell>
                  <TableCell>여권타입</TableCell>
                  <TableCell>한글이름</TableCell>
                  <TableCell>파일명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    <TableCell>
                      {row.lastName}
                    </TableCell>
                    <TableCell>
                      {row.firstName}
                    </TableCell>
                    <TableCell>
                      {row.gender}
                    </TableCell>
                    <TableCell>
                      {row.birth}
                    </TableCell>
                    <TableCell>
                      {row.national}
                    </TableCell>
                    <TableCell>
                      {row.passportNo}
                    </TableCell>
                    <TableCell>
                      {row.passportExp}
                    </TableCell>
                    <TableCell>
                      {row.authority}
                    </TableCell>
                    <TableCell>
                      {row.type}
                    </TableCell>
                    <TableCell>
                      {row.korName}
                    </TableCell>
                    <TableCell>
                      {row.fileName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Stack direction="row" spacing={4} mt={6} justifyContent="center">
            <Button variant="outlined" size="large" color="secondary" sx={{ px: 8 }}>Excel 다운로드</Button>
            <Button variant="outlined" size="large" color="secondary" sx={{ px: 8 }} onClick={openTransApisPop}>APIS 변환</Button>
            <Button variant="contained" size="large" color="secondary" sx={{ px: 8 }} onClick={openUploadListApisPop}>명단 &amp; APIS 업로드</Button>
            <Button variant="contained" size="large" color="secondary" sx={{ px: 8 }} onClick={openUploadApisPop}>APIS 업로드</Button>
            <Button variant="contained" size="large" sx={{ px: 8, color: 'white' }} onClick={openReservePop}>예약하기</Button>
          </Stack>
        </Grid>
      </Grid>
      
      <CustomDialog
        open={transApisPop}
        onClose={openTransApisPop}
        maxWidth="sm"
        title={{text: 'APIS 변환', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closeTransApisPop, color: 'secondary' }
          ]
        }}
      >
        <RadioGroup name="apisType" defaultValue="amadeus" row>
          <FormControlLabel value="amadeus" label="아마데우스" control={<Radio />}></FormControlLabel>
          <FormControlLabel value="sabre" label="세이버" control={<Radio />}></FormControlLabel>
        </RadioGroup>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField size="small" sx={{ flex: '1 1 1%' }} />
            <Button variant="contained" color="primary" startIcon={<ContentCopyIcon />} sx={{ color: 'white' }}>COPY</Button>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField size="small" sx={{ flex: '1 1 1%' }} />
            <Button variant="contained" color="primary" startIcon={<ContentCopyIcon />} sx={{ color: 'white' }}>COPY</Button>
          </Box>
        </Box>
      </CustomDialog>
      
      <CustomDialog
        open={uploadListApisPop}
        onClose={closeUploadListApisPop}
        maxWidth="sm"
        title={{text: '명단 & APIS 업로드', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closeUploadListApisPop, color: 'secondary' }
          ]
        }}
      >
        <RadioGroup name="apisType" defaultValue="amadeus" row>
          <FormControlLabel value="amadeus" label="아마데우스" control={<Radio />}></FormControlLabel>
          <FormControlLabel value="sabre" label="세이버" control={<Radio />}></FormControlLabel>
        </RadioGroup>
        
        <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
          <TextField size="small" placeholder="GDS PNR 입력" sx={{ flex: '1 1 1%' }} />
          <Button variant="contained" color="primary" startIcon={<UploadIcon />} sx={{ color: 'white' }}>업로드</Button>
        </Box>
      </CustomDialog>
      
      <CustomDialog
        open={uploadApisPop}
        onClose={closeUploadApisPop}
        maxWidth="sm"
        title={{text: 'APIS 업로드', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closeUploadApisPop, color: 'secondary' }
          ]
        }}
      >
        <RadioGroup name="apisType" defaultValue="amadeus" row>
          <FormControlLabel value="amadeus" label="아마데우스" control={<Radio />}></FormControlLabel>
          <FormControlLabel value="sabre" label="세이버" control={<Radio />}></FormControlLabel>
        </RadioGroup>
        
        <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
          <TextField size="small" placeholder="GDS PNR 입력" sx={{ flex: '1 1 1%' }} />
          <Button variant="contained" color="primary" startIcon={<UploadIcon />} sx={{ color: 'white' }}>업로드</Button>
        </Box>
      </CustomDialog>
      
      <CustomDialog
        open={reservePopOpen}
        onClose={closeReservePop}
        maxWidth="sm"
        title={{text: '예약하기', align: 'center'}}
        actions={{
          align: 'center',
          buttons: [
            { label: '취소', handler: closeReservePop, color: 'secondary' },
            { label: '보내기', handler: closeReservePop, color: 'primary' }
          ]
        }}
      >
        <RadioGroup name="reserveType">
          <Button variant="outlined" endIcon={<IosShareIcon color="secondary" />}>
            <FormControlLabel value="topas" label="토파스 GDS로 보내기" control={<Radio />} sx={{ width: '100%', color: 'secondary.main' }} />
          </Button>
          <Button variant="outlined" endIcon={<IosShareIcon color="secondary" />} sx={{ mt: 3 }}>
            <FormControlLabel value="sabre" label="세이버 GDS로 보내기" control={<Radio />} sx={{ width: '100%', color: 'secondary.main' }} />
          </Button>
        </RadioGroup>
      </CustomDialog>
    </DefaultLayout>
  )
}

export default Passport;
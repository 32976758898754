/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================

import * as React from 'react';
import { useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Alert,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import DefaultLayout from '../../layouts/defaultLayout';

function NonameExcelResult() {
  const nonameResultGroup = JSON.parse(sessionStorage.getItem('nonameResultGroup'));
  const keys =
    nonameResultGroup && nonameResultGroup['No.'] ? Object.keys(nonameResultGroup['No.']) : [];
  //엑셀 다운로드
  const excelDownload = () => {
    const excelData = keys.map((key) => ({
      'No.': nonameResultGroup['No.'][key],
      단말기: nonameResultGroup['단말기'][key],
      항공사: nonameResultGroup['항공사'][key],
      인원: nonameResultGroup['인원'][key],
      출발일1: nonameResultGroup['출발일1'][key],
      도착지1: nonameResultGroup['도착지1'][key],
      편명1: nonameResultGroup['편명1'][key],
      PNR: nonameResultGroup['PNR'][key],
    }));

    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, '노네임예약_결과');
    XLSX.writeFile(workBook, '노네임예약_결과.xlsx');
  };

  return (
    <>
      <Paper className="custom-paper">
        <Typography variant="h5" fontWeight={700} mb={5} textAlign="center" gutterBottom>
          노네임단체 PNR  생성결과
        </Typography>

        <Alert severity="success" sx={{ mb: 2 }}>
          엑셀저장 시 파일저장위치 : 내 PC > 다운로드
        </Alert>

        <TableContainer>
          <Table className="custom-table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">단말기</TableCell>
                <TableCell align="center">항공사</TableCell>
                <TableCell align="center">인원</TableCell>
                <TableCell align="center">출발일1</TableCell>
                <TableCell align="center">도착지1</TableCell>
                <TableCell align="center">편명1</TableCell>
                <TableCell align="center">PNR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys?.map((key) => (
                <TableRow key={key}>
                  <TableCell align="center">{nonameResultGroup['No.'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['단말기'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['항공사'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['인원'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['출발일1'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['도착지1'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['편명1'][key]}</TableCell>
                  <TableCell align="center">{nonameResultGroup['PNR'][key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" spacing={4} mt={8} justifyContent="center">
          <Button
            variant="contained"
            size="large"
            sx={{ px: 8, color: 'white' }}
            onClick={() => excelDownload()}
          >
            엑셀저장
          </Button>
        </Stack>
      </Paper>
    </>
  );
}

export default NonameExcelResult;

import React, { useState } from 'react';
import theme from '../assets/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = ({ children, open, onClose, title, maxWidth, actions }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={maxWidth}>
      {title ? <DialogTitle fontSize={18} fontWeight={700} mb={5} sx={{
        textAlign: title.align,
        backgroundColor: theme.palette.primary.main,
        py: 3
      }}>{title.text}</DialogTitle> : null}
      
      <Button color="secondary" onClick={onClose} sx={{ position: 'absolute', top: 8, right: 0, minWidth: 36 }}><CloseIcon /></Button>
      
      <DialogContent sx={{
        "&::-webkit-scrollbar": { width: "4px" },
        "&::-webkit-scrollbar-track": { background: "#ddd", borderRadius: "5px" },
        "&::-webkit-scrollbar-thumb": { background: "#666", borderRadius: "5px" }
      }}>
        {children}
      </DialogContent>
      
      {actions ?
        <DialogActions sx={{ pb: 6, justifyContent: 'center' }}>
          {actions.buttons.map((button, index) => (
            <Button key={index} onClick={button.handler} variant="contained" color={button.color || 'primary'} size="large" sx={{ color: 'white' }}>
              {button.label}
            </Button>
          ))}
        </DialogActions> : <></>
      }
    </Dialog>
  );
};

export default CustomDialog;

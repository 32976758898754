/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

import { common, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Fonts
import '../fonts/fonts.css';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#f93',
      light: '#fda148',
      lighter: '#ffb975',
      dark: '#d87208',
    },
    secondary: {
      main: '#222',
    },
    info: {
      main: '#2d6b57',
      light: '#65b199',
      dark: '#10533f',
    },
    success: {
      main: '#009933',
      light: '#b0b939',
    },
    warning: {
      main: '#ff9f43',
    },
    error: {
      main: '#e01b1b',
    },
    white: '#fff',
    black: '#000',
    red: {
      main: '#960018',
      light: '#cf3049',
      dark: '#6a0716',
    },
    green: {
      main: '#618b25',
      light: '#8bc33a',
      dark: '#40600a',
    },
    yellow: {
      main: '#ffcc33',
      light: '#ffe74c',
      dark: '#bf8b1c',
    },
    purple: {
      main: '#663399',
      light: '#9262c3',
      dark: '#582c4d',
    },
    blue: {
      main: '#033f63',
      light: '#336699',
      dark: '#032b43',
      50: '#f0f7ff',
      100: '#c2e0ff',
      200: '#99ccf3',
      300: '#66b2ff',
      400: '#39f',
      500: '#007fff',
      600: '#0072e6',
      700: '#0059b3',
      800: '#004c99',
      900: '#003a75',
    },
    grey: {
      main: '#ccc',
      50: '#f3f6f9',
      100: '#e5eaf2',
      200: '#dae2ed',
      300: '#c7d0dd',
      400: '#b0b8c4',
      500: '#9da8b7',
      600: '#6b7a90',
      700: '#434d5b',
      800: '#303740',
      900: '#1c2025',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: 'Pretendard, sans-serif',
  },
});

export default theme;

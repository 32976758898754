/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import React, { useEffect, useState } from 'react';
import utripApi from '../../utils/api/utripApi';
import { setOpenLoading, useUtripContextApi, setOpenDialogPopup } from '../../context';
import Cookies from 'js-cookie';

// ====================== MUI Components  =========================
import DefaultLayout from '../../layouts/defaultLayout';
import {
  Grid,
  Paper,
  Button,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
// ====================== Service Components ======================
import Calendar from './calendar';
import dayjs from 'dayjs';

// ====================== Images ==================================
import theme from '../../assets/theme';
import CustomDialog from '../../components/dialog';
function MainPage() {
  const [controller, dispatch] = useUtripContextApi();

  const initialDateString = sessionStorage.getItem('calendarDate') || null;
  // 문자열을 Date 객체로 변환하는 함수
  const stringToDate = (dateString) => {
    return new Date(dateString); // Date 객체 반환
  };

  // today를 가져오거나 특정 날짜가 있으면 그 날짜를 사용
  const getInitialDate = () => {
    if (initialDateString) {
      return stringToDate(initialDateString);
    }
    return new Date(); // 없으면 오늘 날짜 반환
  };

  // 초기화 값
  const initialField = 'TL 메모';
  const initialPnrInfo = '';
  const initialTitleInfo = '';
  const initialDescription = '';
  const initialRadioValue = false;

  // const today = new Date();
  // TODO 우측 날짜표기
  const today = getInitialDate();

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  const month2 = String(today.getMonth() + 1).padStart(2, '0');
  const date2 = String(today.getDate()).padStart(2, '0');
  const daysOfWeek = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  const dayOfWeek = daysOfWeek[today.getDay()];
  const [formattedCurrentDate, setFormattedCurrentDate] = useState(today);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dayOfWeek = daysOfWeek[date.getDay()];
    return `${year}년 ${month}월 ${day}일 ${dayOfWeek}`;
  };
  const formattedDate = formatDate(formattedCurrentDate);
  const todayFormattedDate = `${year}-${month2}-${date2}`;

  const [memoYn, setMemoYn] = useState(false);
  const [pnrDetailOpen, setPnrDetailOpen] = useState(false);
  const [ttlaDetailOpen, setTtlaDetailOpen] = useState(false);
  const [idParam, setIdParam] = useState();
  const [field, setField] = useState('TL 메모');
  const [saveYn, setSaveYn] = useState(true);
  const [radioValue, setRadioValue] = useState(false);
  const [calendar, setCalendar] = useState('tlm');
  const [pnrInfo, setPnrInfo] = useState();
  const [titleInfo, setTitleInfo] = useState();
  // const [selectedDateTime, setSelectedDateTime] = useState(dayjs().format('YYYY-MM-DD HH:mm'));
  const [selectedDateTime, setSelectedDateTime] = useState(
    initialDateString
      ? dayjs(initialDateString).hour(16).minute(0).second(0).format('YYYY-MM-DD HH:mm')
      : dayjs().hour(16).minute(0).second(0).format('YYYY-MM-DD HH:mm')
  );
  const [selectedDate, setSelectedDate] = useState(initialDateString ? initialDateString : dayjs().format('YYYY-MM-DD'));
  const [description, setDescription] = useState();
  const [matchingEvents, setMatchingEvents] = useState([]);
  const [matchingPnrSales, setMatchingPnrSales] = useState([]);
  const [matchingTtlaSales, setMatchingTtlaSales] = useState([]);

  const [pnrDetail, setPnrDetail] = useState({});
  const [ttlaDetail, setTtlaDetail] = useState({});

  const [currentDate, setCurrentDate] = useState({
    year: year, // 현재 년
    month: month, // 현재 월
  });

  const handleDateChange = (year, month) => {
    if (currentDate?.year != year || currentDate?.month != month) setCurrentDate({ year, month });
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 6px 6px 0 6px;
    color: ${theme.palette.grey[900]};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 0px 2px 2px ${theme.palette.grey[50]};
    resize: none;

    &:hover {
      border-color: ${theme.palette.black};
    }

    &:focus {
      outline: 0;
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const openMemo = () => {
    setSaveYn(true);
    setMemoYn(true);
  };
  const opemMemoPop = (event) => {
    setSaveYn(false);
    setMemoYn(true);
    setCalendar(event?.extendedProps?.calendar);
    if (event?.extendedProps?.calendar === 'MEM') {
      setField('일반메모');
    } else {
      setField('TL 메모');
    }
    setRadioValue(event?.is_completed);
    setPnrInfo(event?.extendedProps?.pnr);
    setTitleInfo(event?.title);
    setIdParam(event?.extendedProps?.id);
    const date = new Date(event?.tlm_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    setSelectedDateTime(formattedDate);
    setSelectedDate(event?.start?.split('T')[0]);
    setDescription(event?.extendedProps?.description);
  };

  const closeMemo = () => {
    setField(initialField);
    setPnrInfo(initialPnrInfo);
    setTitleInfo(initialTitleInfo);
    setDescription(initialDescription);
    setRadioValue(initialRadioValue);
    setMemoYn(false);
  };

  const openPnrDetail = (sale) => {
    setPnrDetail(sale);
    setPnrDetailOpen(true);
  };

  const closePnrDetail = () => {
    setPnrDetailOpen(false);
  };

  const openTtlaDetail = (sale) => {
    setTtlaDetail(sale);
    setTtlaDetailOpen(true);
  };

  const closeTtlaDetail = () => {
    setTtlaDetailOpen(false);
  };

  const selectChange = (e) => {
    setField(e.target.value);
    if (e.target.value === 'TL 메모') {
      setCalendar('tlm');
    } else {
      setCalendar('mem');
    }
  };

  const handleTitleInfo = (event) => {
    field === 'TL 메모' ? setPnrInfo(event.target.value) : setTitleInfo(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const radioChange = (e) => {
    const value = e.target.value === 'true';
    setRadioValue(value);
  };

  // 회사정보 등록
  const saveMemo = async () => {
    if (!pnrInfo && field === 'TL 메모') {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `PNR 값은 필수값입니다.`,
      });
      return;
    }

    if (!titleInfo && field !== 'TL 메모') {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `제목은 필수값입니다.`,
      });
      return;
    }
    const body = {
      display: 'block', //block고정
      end: '', // 미사용
      endStr: '', // 미사용
      extendedProps: {
        calendar: calendar, // 메모종류 (mem, tlm)
        description: description, // 메모의 비고
        eventCompleted: radioValue, // 완료상태
        eventPNR: pnrInfo,
        eventPending: !radioValue, //미완료 : true
        eventTlmDate: selectedDateTime, //tlm일 때 dateString 2024-07-05 12:00
        guests: [], // 미사용
        location: '', // 미사용
      },
      start: selectedDate, // start = startStr
      startStr: selectedDate, // start = startStr
      title: field === 'TL 메모' ? pnrInfo : titleInfo, // 메모 제목
      id: '',
    };

    try {
      const response = await utripApi.post('/apis/memo', body);
      if (response.data.message === 'success') {
        // 작업날짜 저장
        sessionStorage.setItem('calendarDate', selectedDate);
        location.reload();
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }
    setMemoYn(false);
  };

  const updateMemo = async () => {
    if (calendar == 'tlm' && !pnrInfo) {
      setOpenDialogPopup(dispatch, {
        modalType: 'failCustom',
        showYn: true,
        content: `PNR 값은 필수값입니다.`,
      });
      return;
    }
    const body = {
      display: 'block', //block고정
      end: selectedDate, // 미사용
      extendedProps: {
        calendar: calendar, // 메모종류 (mem, tlm)
        description: description, // 메모의 비고
        eventCompleted: radioValue, // 완료상태
        eventPending: !radioValue, //미완료 : true
        eventTlmDate: field === 'TL 메모' ? selectedDateTime : selectedDate,
        //tlm일 때 dateString 2024-07-05 12:00
      },
      start: selectedDate, // start = startStr
      title: field === 'TL 메모' ? pnrInfo : titleInfo, // 메모 제목
      id: idParam,
    };

    try {
      const response = await utripApi.put('/apis/memo', body);
      sessionStorage.setItem('calendarDate', selectedDate);
      if (response.data.message === 'update success') {
        location.reload();
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }
    setMemoYn(false);
  };

  const deleteMemo = async () => {
    const body = {
      id: idParam,
    };

    try {
      const response = await utripApi.delete('/apis/memo', { data: body });

      if (response.data.message === 'delete success') {
        sessionStorage.setItem('calendarDate', selectedDate);
        location.reload();
      } else {
        setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      }
    } catch (error) {
      setOpenDialogPopup(dispatch, { modalType: 'failure', showYn: true });
      console.error(error.response);
    }
    setMemoYn(false);
  };

  // 이벤트 목록
  const [events, setEvents] = useState([]);
  const [sales, setSales] = useState([]);


  // json- 휴일 목록
  const [holidays, setHolidays] = useState({});
  useEffect(() => {
    const fetchHolidays = async () => {
      const response = await fetch("/holidays.json");
      const data = await response.json();

      const transformedHolidays = transformHolidays(data);
      setHolidays(transformedHolidays);
    };

    fetchHolidays();
  }, []);
  const transformHolidays = (data) => {
    const holidays = {};
    Object.keys(data).forEach((year) => {
      data[year].forEach((holiday) => {
        holidays[holiday.start] = holiday.title;
      });
    });
    return holidays;
  };

  // PNR, MEM, TTL, TLM 건 수
  const [eventCountPerDay, setEventCountPerDay] = useState({});
  const [eventCount, setEventCount] = useState({});

  const getAllMemos = async () => {
    try {
      const response = await utripApi.get(
        `/apis/memo?year=${currentDate?.year}&month=${currentDate?.month}`
      );
      if (response.status === 200) {
        const newEventCountPerDay = response.data.eventCountPerDay;
        if (newEventCountPerDay) {
          setEventCountPerDay((prevState) => ({
            ...prevState,
            ...newEventCountPerDay,
          }));
          setEventCount(newEventCountPerDay[todayFormattedDate]);
        }
        setEvents(response.data.events);
      }
    } catch (error) {
      console.error('Error getAllMemos : ', error);
    }
  };
  const getAllSales = async () => {
    try {
      const response = await utripApi.get(
        `/apis/func_wire/v2/sales?year=${currentDate?.year}&month=${currentDate?.month}`
      );
      if (response.status === 200) {
        const newEventCountPerDay = response.data.eventCountPerDay;
        if (newEventCountPerDay) {
          setEventCountPerDay((prevState) => ({
            ...prevState,
            ...newEventCountPerDay,
          }));
          setEventCount(newEventCountPerDay[todayFormattedDate]);
        }
        setSales(response.data?.data);
      }
    } catch (error) {
      console.error('Error getAllSales : ', error);
    }
  };

  useEffect(() => {
    setMatchingEvents(events.filter((event) => event.start.split('T')[0] === todayFormattedDate));
  }, [events, todayFormattedDate]);

  const handleClickDate = (clickDate) => {
    setFormattedCurrentDate(new Date(clickDate));
    setSelectedDate(clickDate);
    setSelectedDateTime(clickDate)
    setMatchingEvents(events.filter((event) => event.start.split('T')[0] === clickDate));
    setEventCount(eventCountPerDay[clickDate]);
    setMatchingPnrSales(
      Array.isArray(sales) ? sales.filter((sale) => sale?.tour_start_date === clickDate) : []
    );
    setMatchingTtlaSales(
      Array.isArray(sales) ? sales.filter((sale) => sale?.min_tl?.split('T')[0] === clickDate) : []
    );
  };

  const memCompletedEvents = matchingEvents.filter(
    (event) => event.extendedProps.calendar === 'MEM' && event.is_completed
  );
  const memNCompletedEvents = matchingEvents.filter(
    (event) => event.extendedProps.calendar === 'MEM' && !event.is_completed
  );

  const tlmCompletedEvents = matchingEvents.filter(
    (event) => event.extendedProps.calendar === 'TLM' && event.is_completed
  );
  const tlmNCompletedEvents = matchingEvents.filter(
    (event) => event.extendedProps.calendar === 'TLM' && !event.is_completed
  );
  const ttlaEvents = matchingEvents.filter(
    (event) => event.extendedProps.calendar === 'TLM' && !event.is_completed
  );
  const notices = async () => {
    const response = await utripApi.get('apis/func_wire/v2/notice');
    if (response && response.data && response.data.message === 'success' && Array.isArray(response.data.data) && response.data.data[0]

    ) {
      setOpenDialogPopup(dispatch, {
        modalType: 'notice',
        showYn: true,
        content: response.data.data[0].content,
      });

    }
  };

  useEffect(() => {
    setMatchingPnrSales(
      Array.isArray(sales)
        ? sales.filter((sale) => sale?.tour_start_date === todayFormattedDate)
        : []
    );
    setMatchingTtlaSales(
      Array.isArray(sales)
        ? sales.filter((sale) => sale?.min_tl?.split('T')[0] === todayFormattedDate)
        : []
    );
  }, [sales, todayFormattedDate]);
  useEffect(() => {
    getAllMemos();
    getAllSales();
  }, [currentDate]);
  useEffect(() => {
    const noticeSeen = Cookies.get("notice_seen");
    if (!noticeSeen) {
      notices()
      Cookies.set("notice_seen", "true", { expires: 1 });
    }
  }, []);

  return (
    <DefaultLayout>
      <Grid container spacing={4}>
        <Grid item xs={10}>
          <Paper className="custom-paper custom-paper-full-height-90">
            <Calendar
              itemCounts={eventCountPerDay}
              holidays={holidays}
              onDateChange={handleDateChange}
              clickDate={handleClickDate}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className="custom-paper custom-paper-full-height-90">
            <Button variant="contained" color="info" sx={{ width: '100%', fontSize: '1rem', }} onClick={openMemo}>
              TL메모 &amp; 업무메모 추가
            </Button>

            <Box mt={5}>
              <Box
                sx={{
                  p: 1,
                  border: '1px solid',
                  borderColor: theme.palette.secondary.main,
                  borderRadius: 1,
                }}
              >
                <Typography variant="subtitle1" textAlign="center" fontWeight={700}>
                  {formattedDate}
                </Typography>
              </Box>
              {/* PNR 영역 */}
              {eventCount?.pnr > 0 && (
                <Box
                  sx={{
                    mt: 4,
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    fontWeight={700}
                    sx={{ py: 1, color: 'white', backgroundColor: theme.palette.info.main }}
                  >
                    금일출발 PNR : {eventCount?.pnr || 0}건
                  </Typography>
                  <Box p={4} display="flex" flexDirection="column" gap={1}>
                    {matchingPnrSales.map((sale, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                        onClick={() => openPnrDetail(sale)}
                      >
                        {sale?.pnr_num + '(' + sale?.people_count + ')'}{' '}
                        {sale?.is_group === true ? (
                          <span className="status-badge green">단체</span>
                        ) : (
                          <span className="status-badge secondary">개인</span>
                        )}
                      </Button>
                    ))}
                  </Box>
                </Box>
              )}

              {/* MEM 영역 */}
              {matchingEvents.filter(
                (event) => event.extendedProps.calendar === 'MEM').length > 0 && (
                  <Box
                    sx={{
                      mt: 4,
                      border: '1px solid',
                      borderColor: theme.palette.grey[200],
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      fontWeight={700}
                      sx={{ py: 1, color: 'white', backgroundColor: theme.palette.info.main }}
                    >
                      {/* MEM : {eventCount?.mem || 0}건 */}
                      MEMO : {matchingEvents.filter(
                        (event) => event.extendedProps.calendar === 'MEM').length || 0}건
                    </Typography>

                    {/* <Box p={4}> */}
                    <Box p={4} display="flex" flexDirection="column" gap={1}>
                      {memCompletedEvents.map((event, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          sx={{ width: '100%', justifyContent: 'space-between' }}
                          onClick={() => opemMemoPop(event)}
                        >{event.title}
                        </Button>
                      ))}
                      {memNCompletedEvents.map((event, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          sx={{ width: '100%', justifyContent: 'space-between' }}
                          onClick={() => opemMemoPop(event)}
                        >{event.title}
                        </Button>
                      ))}
                    </Box>
                    {/* 기존 완료 미완료 표기 */}
                    {/* <Box>
                        <span className="status-badge success">완료</span>

                        <List sx={{ margin: 'auto 0' }}>
                          {memCompletedEvents.map((event, index) => (
                            <ListItem
                              key={index}
                              sx={{ p: 0, cursor: 'pointer' }}
                              onClick={() => opemMemoPop(event)}
                            >
                              <ListItemText
                                primary={event.title}
                                sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 } }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box> */}

                    {/* <Box mt={2}>
                        <span className="status-badge error">미완료</span>

                        <List sx={{ margin: 'auto 0' }}>
                          {memNCompletedEvents.map((event, index) => (
                            <ListItem
                              key={index}
                              sx={{ p: 0, cursor: 'pointer' }}
                              onClick={() => opemMemoPop(event)}
                            >
                              <ListItemText
                                primary={event.title}
                                sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 } }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box> */}
                    {/* </Box> */}
                  </Box>
                )}

              {/* TTLA 영역 */}
              {eventCount?.ttl > 0 && (

                <Box
                  sx={{
                    mt: 4,
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    fontWeight={700}
                    sx={{ py: 1, color: 'white', backgroundColor: theme.palette.primary.main }}
                  >
                    TTLA : {eventCount?.ttl || 0}건
                  </Typography>

                  <Box p={4} display="flex" flexDirection="column" gap={1}>
                    {matchingTtlaSales.map((sale, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                        onClick={() => openTtlaDetail(sale)}
                      >
                        {sale?.pnr_num + '(' + sale?.people_count + ')'}{' '}
                        {sale?.is_group === true ? (
                          <span className="status-badge green">단체</span>
                        ) : (
                          <span className="status-badge secondary">개인</span>
                        )}
                      </Button>
                    ))}
                  </Box>
                </Box>
              )}
              {/* TTLM 영역 */}
              {matchingEvents.filter(
                (event) => event.extendedProps.calendar === 'TLM').length > 0 && (
                  <Box
                    sx={{
                      mt: 4,
                      border: '1px solid',
                      borderColor: theme.palette.grey[200],
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      fontWeight={700}
                      sx={{ py: 1, color: 'white', backgroundColor: theme.palette.primary.main }}
                    >
                      {/* TTLM : {eventCount?.tlm || 0}건 */}
                      TTLM : {matchingEvents.filter(
                        (event) => event.extendedProps.calendar === 'TLM').length || 0}건
                    </Typography>
                    <Box p={4} display="flex" flexDirection="column" gap={1}>
                      {tlmCompletedEvents.map((event, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          sx={{ width: '100%', justifyContent: 'space-between' }}
                          onClick={() => opemMemoPop(event)}
                        >{event.title}
                        </Button>
                      ))}
                      {tlmNCompletedEvents.map((event, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          sx={{ width: '100%', justifyContent: 'space-between' }}
                          onClick={() => opemMemoPop(event)}
                        >{event.title}
                        </Button>
                      ))}
                    </Box>
                    {/* 기존 완료 미완료 */}
                    {/* <Box p={4}>
                      <Box>
                        <span className="status-badge success">완료</span>
                        <List sx={{ margin: 'auto 0' }}>
                          {tlmCompletedEvents.map((event, index) => (
                            <ListItem
                              key={index}
                              sx={{ p: 0, cursor: 'pointer' }}
                              onClick={() => opemMemoPop(event)}
                            >
                              <ListItemText
                                primary={event.title}
                                sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 } }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>

                      <Box mt={2}>
                        <span className="status-badge error">미완료</span>
                        <List sx={{ margin: 'auto 0' }}>
                          {tlmNCompletedEvents.map((event, index) => (
                            <ListItem
                              key={index}
                              sx={{ p: 0, cursor: 'pointer' }}
                              onClick={() => opemMemoPop(event)}
                            >
                              <ListItemText
                                primary={event.title}
                                sx={{ m: 0, '.MuiListItemText-primary': { fontSize: 15 } }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box> */}
                  </Box>
                )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* 메모 추가 */}
      <CustomDialog
        open={memoYn}
        onClose={closeMemo}
        maxWidth="sm"
        title={{ text: 'TL메모 & 업무메모 등록', align: 'center' }}
        actions={{
          align: 'center',
          buttons: saveYn
            ? [
              { label: '추가', handler: saveMemo, color: 'primary' },
              { label: '닫기', handler: closeMemo, color: 'secondary' },
            ]
            : [
              { label: '수정', handler: updateMemo, color: 'primary' },
              { label: '삭제', handler: deleteMemo, color: 'error' },

            ],
        }}
      >
        <FormControl fullWidth size="small">
          <InputLabel id="label">구분</InputLabel>
          <Select
            labelId="label"
            id="select"
            value={field}
            name="field"
            label="Field"
            onChange={selectChange}
          >
            {['TL 메모', '일반메모'].map((num) => (
              <MenuItem key={num} value={num}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {field === 'TL 메모' ? (
          <>
            <Box display="flex" gap={4} mt={4}>
              <Box display="flex" alignItems="center" gap={5} sx={{ flex: '1 1 1%' }}>
                <Typography variant="subtitle1" fontWeight={700}>
                  상태
                </Typography>

                <RadioGroup row name="radio" value={radioValue} onChange={radioChange}>
                  <FormControlLabel value={true} control={<Radio id="radio1" />} label="완료" />
                  <FormControlLabel value={false} control={<Radio id="radio2" />} label="미완료" />
                </RadioGroup>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: '1 1 1%' }}>
                <DateTimePicker
                  label="TL 일시"
                  format="YYYY-MM-DD HH:mm"
                  value={dayjs(selectedDateTime)}
                  // value={dayjs(selectedDate)}
                  onChange={(newValue) => {
                    setSelectedDateTime(newValue ? newValue.format('YYYY-MM-DD HH:mm') : null);
                  }}
                  slotProps={{
                    textField: { size: 'small', variant: 'outlined' },
                  }}
                />
              </LocalizationProvider>
            </Box>

            <TextField
              label="PNR"
              variant="outlined"
              placeholder="PNR"
              size="small"
              value={pnrInfo || ''}
              onChange={handleTitleInfo}
              sx={{ width: '100%', flex: '1 1 1%', mt: 4 }}
            />
          </>
        ) : (
          <>
            <Box display="flex" gap={4} mt={4}>
              <Box display="flex" alignItems="center" gap={5} sx={{ flex: '1 1 1%' }}>
                <Typography variant="subtitle1" fontWeight={700}>
                  상태
                </Typography>

                <RadioGroup row name="radio" value={radioValue} onChange={radioChange}>
                  <FormControlLabel value={true} control={<Radio id="radio1" />} label="완료" />
                  <FormControlLabel value={false} control={<Radio id="radio2" />} label="미완료" />
                </RadioGroup>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: '1 1 1%' }}>
                <DatePicker
                  label="날짜"
                  value={dayjs(selectedDate)} ㅋ
                  format="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setSelectedDate(newValue ? newValue.format('YYYY-MM-DD') : null);
                  }}
                  slotProps={{
                    textField: { size: 'small', variant: 'outlined' },
                  }}
                />
              </LocalizationProvider>
            </Box>

            <TextField
              label="제목"
              variant="outlined"
              placeholder="제목 (최대 24자)"
              value={titleInfo || ''}
              onChange={handleTitleInfo}
              size="small"
              sx={{ width: '100%', flex: '1 1 1%', mt: 4 }}
            />
          </>
        )}

        <TextField
          multiline
          minRows={5}
          placeholder=""
          sx={{ width: '100%', mt: 5 }}
          value={description || ''}
          onChange={handleDescription}
        />
      </CustomDialog>

      {/* PNR 상세 보기 */}
      <CustomDialog
        open={pnrDetailOpen}
        onClose={closePnrDetail}
        maxWidth="sm"
        title={{ text: 'PNR 상세보기', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closePnrDetail, color: 'secondary' },
            // { label: '추가', handler: closePnrDetail, color: 'primary' },
          ],
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>등록일</TableCell>
              <TableCell>{pnrDetail?.created_at?.replace('T', ' ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GDS</TableCell>
              <TableCell>{pnrDetail?.gds_type?.toUpperCase()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PNR</TableCell>
              <TableCell>{pnrDetail?.pnr_num + '(' + pnrDetail?.people_count + ')'} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>{pnrDetail?.booking_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>구분</TableCell>
              <TableCell>{pnrDetail?.is_group === true ? '단체' : '개인'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>거래처</TableCell>
              <TableCell>{pnrDetail?.customer_comapny_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발일</TableCell>
              <TableCell>{pnrDetail?.tour_start_date}</TableCell>
              {pnrDetail?.flights?.length > 1 && (
                <>
                  <TableCell>출발일</TableCell>
                  <TableCell>{pnrDetail?.flights[1].date}</TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell>출발편명</TableCell>
              <TableCell>{pnrDetail?.start_air_name}</TableCell>
              {pnrDetail?.flights?.length > 1 && (
                <><TableCell>출발편명</TableCell><TableCell>{pnrDetail?.flights[1].flight_number}</TableCell></>
              )}
            </TableRow>
            <TableRow>
              <TableCell>출발지</TableCell>
              <TableCell>{pnrDetail?.tour_start_loc}</TableCell>
              {pnrDetail?.flights?.length > 1 && (
                <>
                  <TableCell>출발지</TableCell>
                  <TableCell>{pnrDetail?.flights[1].dep_apo}</TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell>도착지</TableCell>
              <TableCell>{pnrDetail?.tour_finish_loc}</TableCell>
              {pnrDetail?.flights?.length > 1 && (
                <>
                  <TableCell>도착지</TableCell>
                  <TableCell>{pnrDetail?.flights[1].arr_apo}</TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell>네임 TL</TableCell>
              <TableCell>{pnrDetail?.name_tl}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>네임 TL 100%</TableCell>
              <TableCell>{pnrDetail?.name_tl_other}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>발권 TL</TableCell>
              <TableCell> {pnrDetail?.min_tl === '3333-12-31T00:00:00'
                ? '확인필요'
                : pnrDetail?.min_tl?.replace('T', ' ')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CustomDialog>
      {/* TTLA 상세 보기 */}
      <CustomDialog
        open={ttlaDetailOpen}
        onClose={closeTtlaDetail}
        maxWidth="sm"
        title={{ text: 'TTLA 상세보기', align: 'center' }}
        actions={{
          align: 'center',
          buttons: [
            { label: '닫기', handler: closeTtlaDetail, color: 'secondary' },
            // { label: '추가', handler: closeTtlaDetail, color: 'primary' },
          ],
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>등록일</TableCell>
              <TableCell>{ttlaDetail?.created_at?.replace('T', ' ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GDS</TableCell>
              <TableCell>{ttlaDetail?.gds_type?.toUpperCase()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PNR</TableCell>
              <TableCell>{ttlaDetail?.pnr_num + '(' + ttlaDetail?.people_count + ')'} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>{ttlaDetail?.booking_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>구분</TableCell>
              <TableCell>{ttlaDetail?.is_group === true ? '단체' : '개인'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>거래처</TableCell>
              <TableCell>{ttlaDetail?.customer_comapny_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발일</TableCell>
              <TableCell>{ttlaDetail?.tour_start_date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발편명</TableCell>
              <TableCell>{ttlaDetail?.start_air_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>출발지</TableCell>
              <TableCell>{ttlaDetail?.tour_start_loc}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>도착지</TableCell>
              <TableCell>{ttlaDetail?.tour_finish_loc}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>네임 TL</TableCell>
              <TableCell>{ttlaDetail?.name_tl}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>네임 TL 100%</TableCell>
              <TableCell>{ttlaDetail?.name_tl_other}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>발권 TL</TableCell>
              <TableCell>{ttlaDetail?.min_tl?.replace('T', ' ')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CustomDialog>
    </DefaultLayout >
  );
}

export default MainPage;
